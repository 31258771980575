<div class="row">
    <div class="col-lg-3 col-md-6">
        <div class="expected-earnings-box bg-white">
            <h5 class="fw-medium">
                OPD Patients
            </h5>
            <div class="info">
                <div class="d-flex align-items-center justify-content-between">
                    
                    <span class="d-block fw-medium">
                        {{opdPatients}}
                    </span>
                </div>
                <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
            </div>
        </div>
        <div class="expected-earnings-box bg-white">
            <h5 class="fw-medium">
                In-Patients
            </h5>
            <div class="info">
                <div class="d-flex align-items-center justify-content-between">
                   
                    <span class="d-block fw-medium">
                        {{inPatients}}
                    </span>
                </div>
                <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="expected-earnings-box bg-white">
            <h5 class="fw-medium">
                Care Plan Patients
            </h5>
            <div class="info">
                <div class="d-flex align-items-center justify-content-between">
                    
                    <span class="d-block fw-medium">
                        {{carePlanPatients}}
                    </span>
                </div>
                <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
            </div>
        </div>
        <div class="expected-earnings-box bg-white">
            <h5 class="fw-medium">
                Visitors
            </h5>
            <div class="info">
                <div class="d-flex align-items-center justify-content-between">
                    
                    <span class="d-block fw-medium">
                        450
                    </span>
                </div>
                <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-md-12">
        <mat-card class="trinta-card balance-overview-card mb-25 bg-white border-none d-block">
            <mat-card-header style="margin: 0 !important;">
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Hospital Survey
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="chart">
                    <apx-chart [series]="chartOptions.series!" [chart]="chartOptions.chart!"
                        [xaxis]="chartOptions.xaxis!" [fill]="chartOptions.fill!" [stroke]="chartOptions.stroke!"
                        [grid]="chartOptions.grid!" [yaxis]="chartOptions.yaxis!"
                        [colors]="chartOptions.colors!" [dataLabels]="chartOptions.dataLabels!" [legend]="chartOptions.legend!"></apx-chart>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-12">
        <mat-card
    class="trinta-card user-activity-card mb-25 border-none d-block"
>
<mat-card-header>
    <mat-card-title>
        <h5 class="mt-0 mb-0 text-white">
            Age Diversity
        </h5>
    </mat-card-title>
    <mat-card-subtitle>
        <button type="button" mat-button class="card-header-menu-btn text-white" [matMenuTriggerFor]="cardHeaderMenu">
            {{ currentAgeHospitalSelection }}
        </button>
        <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
            <button mat-menu-item (click)="onAgeHospitalSelect(hospital)" *ngFor="let hospital of hospitalList">
                {{ hospital.organizationBranchName }}
            </button>
        </mat-menu>
    </mat-card-subtitle>
    
</mat-card-header>
    <mat-card-content>
        
        <div class="chart bg-white">
            <div class="inner">
                <apx-chart
                    [series]="ageGroupChartOptions.series!"
                    [chart]="ageGroupChartOptions.chart!"
                    [dataLabels]="ageGroupChartOptions.dataLabels!"
                    [plotOptions]="ageGroupChartOptions.plotOptions!"
                    [yaxis]="ageGroupChartOptions.yaxis!"
                    [xaxis]="ageGroupChartOptions.xaxis!"
                    [grid]="ageGroupChartOptions.grid!"
                    [colors]="ageGroupChartOptions.colors!"
                    [fill]="ageGroupChartOptions.fill!"
                    [title]="ageGroupChartOptions.title!"
                ></apx-chart>
            </div>
        </div>
    </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-12">
        <mat-card
    class="trinta-card organic-sessions-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Gender Diversity
            </h5>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu1">
                {{ currentGenderHospitalSelection }}
            </button>
            <mat-menu #cardHeaderMenu1="matMenu" class="card-header-menu" xPosition="before">
                <button mat-menu-item (click)="onGenderHospitalSelect(hospital)" *ngFor="let hospital of hospitalList">
                    {{ hospital.organizationBranchName }}
                </button>
            </mat-menu>
        </mat-card-subtitle>
        
    </mat-card-header>
    <mat-card-content>
        <div class="chart">
            <apx-chart
                [series]="genderChartOptions.series!"
                [chart]="genderChartOptions.chart!"
                [colors]="genderChartOptions.colors!"
                [labels]="genderChartOptions.labels!"
                [stroke]="genderChartOptions.stroke!"
                [legend]="genderChartOptions.legend!"
                [fill]="genderChartOptions.fill!"
                [tooltip]="genderChartOptions.tooltip!"
                [dataLabels]="genderChartOptions.dataLabels!"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>
    </div>
    <div class="col-lg-12 col-md-12">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Number of Patients in each Department
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu2">
                        {{ currentDepartmentHospitalSelected }}
                    </button>
                    <mat-menu #cardHeaderMenu2="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item (click)="onDepartmentHospitalSelect(hospital)" *ngFor="let hospital of hospitalList">
                            {{ hospital.organizationBranchName }}
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <apx-chart
                    [series]="treeChartOptions.series!"
                    [chart]="treeChartOptions.chart!"
                    [dataLabels]="treeChartOptions.dataLabels!"
                    [plotOptions]="treeChartOptions.plotOptions!"
                    [title]="treeChartOptions.title!"
                    [legend]="treeChartOptions.legend!"
                ></apx-chart>
            </mat-card-content>
        </mat-card>
    </div>
</div>