import { AbstractControl, FormGroup } from '@angular/forms';
export class PasswordValidation {
  static MatchPassword(control: AbstractControl) {
    let password = control.get('password')?.value;
    if (
      control.get('confirmPassword')?.touched ||
      control.get('confirmPassword')?.dirty
    ) {
      let verifyPassword = control.get('confirmPassword')?.value;
      if (password != verifyPassword) {
        control.get('confirmPassword')?.setErrors({ MatchPassword: true });
      }
    }
    return null;
  }

  static ConfirmPassword() {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls['password'];
      const matchingControl = formGroup.controls['confirmPassword'];
      if (matchingControl.errors && !matchingControl.errors['MatchPassword']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ MatchPassword: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
