<div style="display: flex; justify-content: end; margin: 10px 0;">
  <button mat-raised-button color="primary" type="button" (click)="addRow()">Add Staff</button>
</div>
<div class="onboardingInsideWrapper" style="overflow: auto;">
  <form [formGroup]="staffDetailsFormGroup">
    <table class="wardTable md-table">
      <thead class="md-table-header">
        <tr class="md-table-header-row">
          <th scope="col">First Name</th>
          <th scope="col">Last Name</th>
          <th scope="col">Roles</th>
          <th scope="col">Rights</th>
          <th scope="col">Gender</th>
          <th scope="col">DOB</th>
          <th scope="col">Education</th>
          <th scope="col">Institute</th>
          <th scope="col">Speciality</th>
          <th scope="col">Phone</th>
          <th scope="col">Email</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody class="md-table-content">
        <ng-container formArrayName="tableRows" *ngFor="let group of getFormControls.controls; let i=index">
          <tr [formGroupName]="i" class="md-table-content-row">
            <td>
              <mat-form-field class="form-field-100px-width">
                <input placeholder="FirstName" value="" matInput formControlName="firstname" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="form-field-100px-width">
                <input placeholder="LastName" value="" matInput formControlName="lastname" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="form-field-150px-width">
                <mat-label>Role</mat-label>
                <mat-select formControlName="roles" (selectionChange)="onRoleChange($event, i)" multiple>
                  <mat-option *ngFor="let role of roleList" [value]="role.roleName">
                    {{ role.roleName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="form-field-150px-width">
                <mat-label>Rights</mat-label>
                <mat-select formControlName="rights" multiple>
                  <mat-option *ngFor="let right of rightsList" [value]="right.privilegesDescription">
                    {{ right.privilegesDescription }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            <!-- <td>
              <mat-form-field class="form-field-150px-width">
                <mat-select formControlName="rights" multiple>
                  @for (right of rightList; track right) {
                  <mat-option [value]="right">{{right}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </td> -->
            <td>
              <mat-form-field class="form-field-100px-width">
                <mat-select placeholder="" formControlName="gender">
                  <mat-option value="Male">Male</mat-option>
                  <mat-option value="Female">Female</mat-option>
                  <mat-option value="other">Other</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="form-field-150px-width">
                <input matInput [matDatepicker]="picker" (click)="picker.open()" placeholder="Date of Birth" readonly
                  formControlName="dob">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="form-field-100px-width">
                <input placeholder="Education" value="" matInput formControlName="edu" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="form-field-100px-width">
                <input placeholder="Institute" value="" matInput formControlName="institute" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="form-field-150px-width">
                <input placeholder="Specialty" value="" matInput formControlName="speciality" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="form-field-100px-width">
                <input placeholder="Phone" value="" matInput formControlName="phone" (keypress)="onKeyPress($event)" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="form-field-100px-width">
                <input placeholder="Email" value="" matInput formControlName="email" />
              </mat-form-field>
            </td>
            <!-- <td><div (click)="removeEmployee(i)"><mat-icon>close</mat-icon></div></td> -->
          </tr>
        </ng-container>
      </tbody>
    </table>
  </form>
</div>