import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { Constants } from 'src/app/constant/constant';

@Injectable()
export class LoaderInterceptorService implements HttpInterceptor {
  private totalRequests = 0;
  constructor(private loaderService: LoaderService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // check for bypass loader.
    let bypassURL:any = Constants.BYPASS_URL.find((url: any) => req.url.includes(url));
    if(!bypassURL) {
      this.totalRequests++;
      this.showLoader();
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.onEnd();
          }
        },
        (err: any) => {
          this.onEnd();
        }
      )
    );
  }

  private onEnd(): void {
    this.totalRequests--;
    if (this.totalRequests == 0) {
      this.hideLoader();
    }
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
