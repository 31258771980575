import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
  NgApexchartsModule,
  ApexPlotOptions,
} from 'ng-apexcharts';
import { UtilityService } from 'src/app/core/services/utility.service';
import { UniDashboardService } from 'src/app/shared/services/uni-dashboard-service/uni-dashboard.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  colors: string[];
  markers: ApexMarkers;
  tooltip: any; // ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};
export type OpdChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  plotOptions: ApexPlotOptions;
  colors: string[];
  yaxis: ApexYAxis;
  legend: ApexLegend;
};
@Component({
  selector: 'app-performance-report',
  standalone: true,
  imports: [
    RouterLink,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    NgApexchartsModule,
  ],
  templateUrl: './performance-report.component.html',
  styleUrl: './performance-report.component.scss',
})
export class PerformanceReportComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public opdChartOptions: Partial<OpdChartOptions>;
  public inpChartOptions: Partial<OpdChartOptions>;
  performanceData: any = [];
  currentOpdSelection: any = 'Top 10';
  currentInpSelection: any = 'Top 10';

  constructor(private uniDashboardService: UniDashboardService) {
    this.chartOptions = {
      series: [
        {
          name: 'No. of Patients',
          data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
        },
        {
          name: 'Average Length of Stay',
          data: [3, 4.1, 6.2, 4, 3, 2, 2.9, 3.7, 3.6, 5.1, 3.2, 3.5],
        },
      ],
      chart: {
        height: 433,
        type: 'line',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
        strokeDashArray: 0,
        borderColor: '#edeff5',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      stroke: {
        width: 2,
        curve: 'straight',
        dashArray: [0, 0],
      },
      colors: ['#2DB6F5', '#EE368C'],
      markers: {
        size: 7,
        strokeWidth: 0,
        hover: {
          sizeOffset: 2,
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
          color: '#edeff5',
        },
        axisTicks: {
          show: false,
          color: '#edeff5',
        },
        labels: {
          show: true,
          style: {
            colors: '#262626',
            fontSize: '13px',
          },
        },
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: '#a9a9c8',
            fontSize: '13px',
          },
        },
        axisBorder: {
          show: false,
          color: '#edeff5',
        },
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '13px',
        horizontalAlign: 'center',
        labels: {
          colors: '#77838f',
        },
        itemMargin: {
          horizontal: 15,
          vertical: 0,
        },
        markers: {
          offsetY: -1,
        },
      },
    };
  }
  ngOnInit(): void {
    const userInfo = UtilityService.getUser();
    this.uniDashboardService
      .FetchPatientOverviewData(userInfo.id, 'all')
      .subscribe((response) => {
        this.performanceData = response.body;
        const hospitalOpdData = this.getHospitalData(
          response.body,
          10,
          'desc',
          'New'
        );
        this.opdChartOptions = {
          series: [
            {
              name: 'Patients',
              data: hospitalOpdData.map((hospital) => hospital[1]),
            },
          ],
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ['#00836C'],
          grid: {
            show: true,
            strokeDashArray: 0,
            borderColor: '#edeff5',
          },
          plotOptions: {
            bar: {
              borderRadius: 7,
              columnWidth: '35px',
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            axisBorder: {
              show: false,
              color: '#edeff5',
            },
            axisTicks: {
              show: false,
              color: '#edeff5',
            },
            labels: {
              show: true,
              style: {
                colors: '#262626',
                fontSize: '13px',
              },
            },
            categories: hospitalOpdData.map((hospital) => hospital[0]),
          },
          yaxis: {
            labels: {
              show: true,
              style: {
                colors: '#a9a9c8',
                fontSize: '13px',
              },
            },
            axisBorder: {
              show: true,
              color: '#edeff5',
            },
          },
          legend: {
            show: false,
          },
        };

        const hospitalInpData = this.getHospitalData(
          response.body,
          10,
          'desc',
          'Admit'
        );
        this.inpChartOptions = {
          series: [
            {
              name: 'Patients',
              data: hospitalInpData.map((hospital) => hospital[1]),
            },
          ],
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ['#00836C'],
          grid: {
            show: true,
            strokeDashArray: 0,
            borderColor: '#edeff5',
          },
          plotOptions: {
            bar: {
              borderRadius: 7,
              columnWidth: '35px',
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            axisBorder: {
              show: false,
              color: '#edeff5',
            },
            axisTicks: {
              show: false,
              color: '#edeff5',
            },
            labels: {
              show: true,
              style: {
                colors: '#262626',
                fontSize: '13px',
              },
            },
            categories: hospitalInpData.map((hospital) => hospital[0]),
          },
          yaxis: {
            labels: {
              show: true,
              style: {
                colors: '#a9a9c8',
                fontSize: '13px',
              },
            },
            axisBorder: {
              show: true,
              color: '#edeff5',
            },
          },
          legend: {
            show: false,
          },
        };
      });
  }

  onOpdSelect(selection: string) {
    console.log(selection);
    this.currentOpdSelection = selection;
    var hospitalData;
    if (selection === 'Top 5')
      hospitalData = this.getHospitalData(
        this.performanceData,
        5,
        'desc',
        'New'
      );
    else if (selection === 'Top 10')
      hospitalData = this.getHospitalData(
        this.performanceData,
        10,
        'desc',
        'New'
      );
    else if (selection === 'Bottom 5')
      hospitalData = this.getHospitalData(
        this.performanceData,
        5,
        'asc',
        'New'
      );
    else
      hospitalData = this.getHospitalData(
        this.performanceData,
        10,
        'asc',
        'New'
      );
    this.opdChartOptions = {
      series: [
        {
          name: 'Patients',
          data: hospitalData.map((hospital) => hospital[1]),
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: ['#00836C'],
      grid: {
        show: true,
        strokeDashArray: 0,
        borderColor: '#edeff5',
      },
      plotOptions: {
        bar: {
          borderRadius: 7,
          columnWidth: '35px',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
          color: '#edeff5',
        },
        axisTicks: {
          show: false,
          color: '#edeff5',
        },
        labels: {
          show: true,
          style: {
            colors: '#262626',
            fontSize: '13px',
          },
        },
        categories: hospitalData.map((hospital) => hospital[0]),
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: '#a9a9c8',
            fontSize: '13px',
          },
        },
        axisBorder: {
          show: true,
          color: '#edeff5',
        },
      },
      legend: {
        show: false,
      },
    };
  }

  onInpSelect(selection: string) {
    console.log(selection);
    this.currentInpSelection = selection;
    var hospitalData;
    if (selection === 'Top 5')
      hospitalData = this.getHospitalData(
        this.performanceData,
        5,
        'desc',
        'Admit'
      );
    else if (selection === 'Top 10')
      hospitalData = this.getHospitalData(
        this.performanceData,
        10,
        'desc',
        'Admit'
      );
    else if (selection === 'Bottom 5')
      hospitalData = this.getHospitalData(
        this.performanceData,
        5,
        'asc',
        'Admit'
      );
    else
      hospitalData = this.getHospitalData(
        this.performanceData,
        10,
        'asc',
        'Admit'
      );
    this.inpChartOptions = {
      series: [
        {
          name: 'Patients',
          data: hospitalData.map((hospital) => hospital[1]),
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: ['#00836C'],
      grid: {
        show: true,
        strokeDashArray: 0,
        borderColor: '#edeff5',
      },
      plotOptions: {
        bar: {
          borderRadius: 7,
          columnWidth: '35px',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
          color: '#edeff5',
        },
        axisTicks: {
          show: false,
          color: '#edeff5',
        },
        labels: {
          show: true,
          style: {
            colors: '#262626',
            fontSize: '13px',
          },
        },
        categories: hospitalData.map((hospital) => hospital[0]),
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: '#a9a9c8',
            fontSize: '13px',
          },
        },
        axisBorder: {
          show: true,
          color: '#edeff5',
        },
      },
      legend: {
        show: false,
      },
    };
  }

  getHospitalData(
    data: any,
    dataCount: number,
    order: any,
    appointmentType: any
  ) {
    const newConsultations = data.filter(
      (item: { consultationType: string }) =>
        item.consultationType === appointmentType
    );

    // Step 2: Count the occurrences of each hospital
    const hospitalCounts: Record<string, number> = newConsultations.reduce(
      (acc: { [x: string]: any }, item: { hospitalName: string | number }) => {
        acc[item.hospitalName] = (acc[item.hospitalName] || 0) + 1;
        return acc;
      },
      {}
    );

    // Step 3: Convert the hospitalCounts object into an array of [hospitalName, count] pairs
    var sortedHospitals;
    if (order === 'desc') {
      sortedHospitals = Object.entries(hospitalCounts)
        .sort((a, b) => (b[1] as number) - (a[1] as number)) // Step 4: Sort the array in descending order by count
        .slice(0, dataCount); // Step 5: Take the top 10 hospitals
    } else {
      sortedHospitals = Object.entries(hospitalCounts)
        .sort((a, b) => (a[1] as number) - (b[1] as number)) // Step 4: Sort the array in descending order by count
        .slice(0, dataCount); // Step 5: Take the top 10 hospitals
    }

    return sortedHospitals;
  }
}
