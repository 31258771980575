import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  LoaderService,
  LoaderState,
} from '../../../core/services/loader.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { CommonModule, NgClass } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgClass,
    ExtendedModule,
    MatProgressSpinner,
    RouterOutlet
  ],
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  private subscription: Subscription;

  constructor(private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.subscription = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        this.show = state.show;
      }
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
