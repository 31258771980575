import { Constants } from 'src/app/constant/constant';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UtilityService } from '../services/utility.service';

@Injectable({
  providedIn: 'root',
})
export class UniGuard {
  count: any = 0;
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      const subdomain = window.location.hostname.split('.')[0];
      if(subdomain === 'dev-oversight' || subdomain === 'oversight'){
        if(UtilityService.getLocalStorage('accessToken')) return true;
        else{
          console.log('nav')
          this.router.navigate(['/unidashboard/login'], {
            queryParams: { returnUrl: state.url },
          });
          console.log('here: ',state)
          return false;
        }
      }
      return false;
  }
}
