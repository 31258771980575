import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { RoleDTO } from 'src/app/models/dto/role-dto';
import { RolePrivilegeDTO } from 'src/app/models/dto/role-privilege-dto';
import { StaffAddDto } from 'src/app/models/dto/staff-add-dto';
import { RoleModel } from 'src/app/models/role-model';
import { format } from 'date-fns';
import { RolePrivilegeModel } from 'src/app/models/role-privilege-model';
import { RolesPrivilegesService } from 'src/app/shared/services/org-onboarding-service/roles-privileges-service';

@Component({
  selector: 'app-staff-management',
  templateUrl: './staff-management.component.html',
  styleUrl: './staff-management.component.scss',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    MatTableModule,
    MatDatepickerModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
  ],
  providers: [provideNativeDateAdapter()],
})
export class StaffManagementComponent implements OnInit {
  staffDetailsFormGroup: FormGroup;

  roleList: RoleModel[] = [];
  rightsList: RolePrivilegeModel[] = [];
  privilegesList: string[] = [];
  filteredRights: RolePrivilegeModel[] = [];
  selectRight = new FormControl('');
  staffList: StaffAddDto[];

  constructor(
    private fb: FormBuilder,
    private rolesPrivilegesService: RolesPrivilegesService
  ) {}

  emailRegex: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  phoneRegex: RegExp = /^(?:(?!\b(\d)\1+\b)[6789]\d{9})$/;

  ngOnInit() {
    this.staffDetailsFormGroup = this.fb.group({
      tableRows: this.fb.array([], [Validators.required]),
    });
    this.addRow();
    this.fetchRoles();
    this.fetchRights();
  }

  fetchRoles() {
    this.rolesPrivilegesService.getRoles().subscribe((roles: any) => {
      this.roleList = roles.data.map((role: any) => ({
        id: role.id,
        roleName: role.roleName,
      })) as RoleModel[];
    });
  }

  fetchRights() {
    this.rolesPrivilegesService.getPrivileges().subscribe((rights: any) => {
      this.rightsList = rights.data.map((right: any) => ({
        id: right.id,
        roleName: right.roleName,
        privilegesDescription: right.privilegesDescription,
      })) as RolePrivilegeModel[];
    });
  }

  get staffDetails(): FormArray {
    return this.staffDetailsFormGroup.get('tableRows') as FormArray;
  }

  createFormGroup(): FormGroup {
    return this.fb.group({
      firstname: ['', [Validators.required, Validators.minLength(3)]],
      lastname: ['', [Validators.required]],
      roles: ['', [Validators.required]],
      rights: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      education: ['', [Validators.required]],
      institute: ['', [Validators.required]],
      speciality: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(this.phoneRegex)]],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailRegex),
        ],
      ],
    });
  }

  get getFormControls() {
    const control = this.staffDetailsFormGroup.get('tableRows') as FormArray;
    return control;
  }

  addRow() {
    const control = this.staffDetailsFormGroup.get('tableRows') as FormArray;
    control.push(this.createFormGroup());
  }

  onStatusChange(event: any, index: number) {
    console.log(event, index);
  }

  // onRoleChange(event: any, index: number) {
  //   const selectedRoles = event.value;
  //   this.filteredRights[index] = this.rightsList.filter((right) =>
  //     selectedRoles.includes(right.roleName)
  //   );
  //   const control = this.getFormControls.at(index).get('rights') as FormControl;
  //   control.setValue(this.filteredRights[index].map((right) => right.privilegesDescription));
  // }

  onRoleChange(event: any, index: number) {
    const selectedRoles = event.value;
    const selectedRights = this.rightsList
      .filter((right) => selectedRoles.includes(right.roleName))
      .map((right) => right.privilegesDescription);

    const control = this.getFormControls.at(index).get('rights') as FormControl;
    control.setValue(selectedRights);
  }

  removeEmployee(index: number) {
    if (index == 0) {
      return;
    }
    const control = this.staffDetailsFormGroup.get('tableRows') as FormArray;
    control.removeAt(index);
  }

  get onSaveForm() {
    const formValue = this.staffDetailsFormGroup.value;
    const tableRows = formValue.tableRows;

    this.staffList = tableRows.map((row: any): StaffAddDto => {
      const roles: RoleDTO[] = row.roles.map(
        (roleName: string): RoleDTO => new RoleDTO(roleName)
      );

      const rights: RolePrivilegeDTO[] = row.rights.map(
        (privilegesDescription: string): RolePrivilegeDTO => {
          const roleName = row.roles.find((role: string) =>
            this.rightsList.some(
              (right) =>
                right.privilegesDescription === privilegesDescription &&
                right.roleName === role
            )
          );
          const rolePrivilegeId = String(
            this.rightsList.find(
              (right) => right.privilegesDescription === privilegesDescription
            )?.id
          );
          return new RolePrivilegeDTO(rolePrivilegeId, roleName);
        }
      );

      var userDetails = JSON.parse(
        localStorage.getItem('userDetail') as string
      );

      const organizationBranchId =
        localStorage.getItem('organizationBranchId') || '';
      const userId = userDetails.id;

      const formattedDob = format(new Date(row.dob), 'yyyy-MM-dd');

      return new StaffAddDto(
        '',
        userId,
        organizationBranchId,
        row.firstname,
        row.lastname,
        formattedDob,
        row.gender,
        row.email,
        row.education,
        row.institute,
        row.speciality,
        row.phone,
        roles,
        rights,
        []
      );
    });

    return this.staffList;
  }

  onKeyPress(e: any) {
    const allowedKeys = [8, 46];

    if (
      e.which !== 0 &&
      (e.which < 48 || e.which > 57) &&
      !allowedKeys.includes(e.which)
    ) {
      e.preventDefault();
    }
  }
}
