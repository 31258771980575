import { scheduleDay } from 'src/app/shared/model/organization/branch';
import { RoleDTO } from './role-dto';
import { RolePrivilegeDTO } from './role-privilege-dto';

export class StaffAddDto {
  adhaarId: string;
  userId: string;
  organizationBranchId: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  email: string;
  education: string;
  institute: string;
  speciality: string;
  phoneNumber: string;
  roles: RoleDTO[];
  rights: RolePrivilegeDTO[];
  schedules: scheduleDay[];

  constructor(
    adhaarId: string,
    userId: string,
    organizationBranchId: string,
    firstName: string,
    lastName: string,
    birthDate: string,
    gender: string,
    email: string,
    education: string,
    institute: string,
    speciality: string,
    phoneNumber: string,
    roles: RoleDTO[],
    rights: RolePrivilegeDTO[],
    schedules : scheduleDay[],

  ) {
    this.adhaarId = adhaarId;
    this.userId = userId;
    this.organizationBranchId = organizationBranchId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.birthDate = birthDate;
    this.gender = gender;
    this.email = email;
    this.education = education;
    this.institute = institute;
    this.speciality = speciality;
    this.phoneNumber = phoneNumber;
    this.roles = roles;
    this.rights = rights;
    this.schedules = schedules;
  }
}
