import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DepartmentRoomDTO } from 'src/app/models/department-room-dto';
import { DepartmentModalComponent } from 'src/app/modules/department-modal/department-modal.component';
import { DepartmentService } from 'src/app/shared/services/org-onboarding-service/department-service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-departments-room',
  templateUrl: './departments-room.component.html',
  styleUrl: './departments-room.component.scss',
  standalone: true,
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    CommonModule,
    FormsModule,
  ],
  providers: [
    // {
    //   provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    //   useValue: { subscriptSizing: 'dynamic' }
    // }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartmentsRoomComponent implements OnInit {
  wardInfoFormGroup: FormGroup;
  finalDepartments: DepartmentRoomDTO[] = [];
  userDetails = JSON.parse(localStorage.getItem('userDetail') as string);
  organizationBranchId = localStorage.getItem('organizationBranchId') || '';

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private service: DepartmentService
  ) {}

  ngOnInit(): void {
    this.wardInfoFormGroup = this.fb.group({
      departments: this.fb.array([]),
    });
    this.loadDepartments();
  }

  get departments(): FormArray {
    return this.wardInfoFormGroup.get('departments') as FormArray;
  }

  loadDepartments(): void {
    this.service.getDepartments().subscribe((response) => {
      this.finalDepartments = response.data.map((element: any) => ({
        $fieldName: element.departmentName,
        userId: this.userDetails.userId,
        organizationBranchId: this.organizationBranchId,
        departmentId: element.id,
        roomType1: null,
        totalRooms1: null,
        totalBedsPerRoom1: null,
        pricePerNight1: null,
        roomType2: null,
        totalRooms2: null,
        totalBedsPerRoom2: null,
        pricePerNight2: null,
        roomType3: null,
        totalRooms3: null,
        totalBedsPerRoom3: null,
        pricePerNight3: null,
      }));

      this.finalDepartments.forEach((department) => {
        this.departments.push(this.createDepartmentFormGroup(department));
      });

      this.changeDetectorRef.detectChanges();
    });
  }

  createDepartmentFormGroup(department: DepartmentRoomDTO): FormGroup {
    return this.fb.group({
      userId: [department.userId],
      departmentId: [department.departmentId],
      organizationBranchId: [department.organizationBranchId],
      roomType1: [department.roomType1, [Validators.required]],
      totalRooms1: [department.totalRooms1, [Validators.required]],
      totalBedsPerRoom1: [department.totalBedsPerRoom1, [Validators.required]],
      pricePerNight1: [department.pricePerNight1, [Validators.required]],
      roomType2: [department.roomType2],
      totalRooms2: [department.totalRooms2],
      totalBedsPerRoom2: [department.totalBedsPerRoom2],
      pricePerNight2: [department.pricePerNight2],
      roomType3: [department.roomType3],
      totalRooms3: [department.totalRooms3],
      totalBedsPerRoom3: [department.totalBedsPerRoom3],
      pricePerNight3: [department.pricePerNight3],
    });
  }

  departmentPopup() {
    const dialogRef = this.dialog.open(DepartmentModalComponent, {
      width: '400px',
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('Dialog closed', result);
    });
  }

  onKeyPress(e: any) {
    const allowedKeys = [8, 46];

    if (
      e.which !== 0 &&
      (e.which < 48 || e.which > 57) &&
      !allowedKeys.includes(e.which)
    ) {
      e.preventDefault();
    }
  }
}
