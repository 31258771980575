import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'src/app/configs/config';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  constructor(private service: ApiService) {}

  /**
   * Create Patient
   * @param obj
   * @returns
   */
  addAppointment(params: any) {
    return this.service.post(config.partialUrls.AddAppointment, params);
  }

    /**
   * Self registration
   * @param obj
   * @returns
   */
  SelfRegistrationMessage(number: any,drId:string) {
    return this.service.post(config.partialUrls.SelfRegistrationMessage + '?number=' + number+'&drId='+drId, {});
  }

  /**
   * Analysis Form Message
   * @param obj
   * @returns
   */
  AnalysisFormMessage(patientId: any, consultationId:any, number: any) {
    return this.service.post(config.partialUrls.AnalysisFormMessage + '?patientId=' + patientId + '&consultationId=' + consultationId + '&number=' + number , {});
  }

  /**
   * Analysis Form Message
   * @param obj
   * @returns
   */
  SendConsentMessage(patientId: any, doctorId:any, name: any, status:any, phoneNumber: any,organizationBranchId ?: any) {
    let params = `?patientId=${patientId}&doctorId=${doctorId}&name=${name}&status=${status}&number=${phoneNumber}`

    if(organizationBranchId){
      params += `&organizationBranchId=${organizationBranchId}`
    }
    return this.service.post(config.partialUrls.SendConsentMessage + params , {});
  }

  /**
   * Create Patient
   * @param obj
   * @returns
   */
  viewAppointment(params: any) {
    return this.service.get(config.partialUrls.ViewAppointment, params);
  }


  updateAppointmentStatus(params: any) {
    return this.service.post(config.partialUrls.updateAppointmentStatus, params);
  }

  /**
   * Fetch Analsis Count
   * @param params
   * @returns
   */
  fetchAnalysisCount(params: any) {
    return this.service.get(config.partialUrls.viewAnalysisCount, params);
  }
  /**
  * Fetch Analsis Count Open
  * @param params
  * @returns
  */
  fetchAnalysisCountOpen(params: any) {
    return this.service.get(config.partialUrls.viewAnalysisCountOpen, params);
  }

  fetchGutAnalysisAwnser(params: any) {
    return this.service.get(config.partialUrls.ListGutAnalysis, params);
  }
  fetchGutAnalysisAwnserOpen(params: any) {
    return this.service.get(config.partialUrls.ListGutAnalysisOpen, params);
  }

  fetchPrakrutiAnalysisAwnser(params: any) {
    return this.service.get(config.partialUrls.ListPrakrutiAnalysis, params);
  }
  fetchPrakrutiAnalysisAwnserOpen(params: any) {
    return this.service.get(config.partialUrls.ListPrakrutiAnalysisOpen, params);
  }

  /**
   * Update Prakruti Analysis
   * @param params
   * @returns
   */
  updatePrakrutiAnalysis(params: any) {
    return this.service.post(config.partialUrls.updatePrakrutiAnalysis, params)
  }

  /**
   * Update Prakruti Analysis
   * @param params
   * @returns
   */
  updateGutAnalysis(params: any) {
    return this.service.post(config.partialUrls.updateGutAnalysis, params)
  }

    /**
   * Book Appointment
   * @param obj
   * @returns
   */
    bookAppointment(params: any) {
      return this.service.post(config.partialUrls.bookAppointment, params);
    }

    /**
   * Book Appointment
   * @param obj
   * @returns
   */
    scheduleAppointment(params: any) {
      return this.service.get(config.partialUrls.scheduleAppointment, params);
    }

    /**
   * Book Appointment
   * @param obj
   * @returns
   */
    confirmationAppointment(params: any) {
      return this.service.post(config.partialUrls.confirmationAppointment, params);
    }
       /**
   * Book Appointment
   * @param obj
   * @returns
   */
    followUpAppointment(params: any) {
      return this.service.get(config.partialUrls.followUpAppointment, params);
    }
}
