import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-thank-you-page',
  standalone: true,
  imports: [FlexLayoutModule, RouterLinkActive],
  templateUrl: './thank-you-page.component.html',
  styleUrl: './thank-you-page.component.scss'
})
export class ThankYouPageComponent {

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    
    localStorage.removeItem('redirectUrl')
  }
}
