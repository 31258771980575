import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterLink, RouterLinkActive, RouterModule } from '@angular/router';
import { FeathericonsModule } from 'src/app/shared/icons/feathericons/feathericons.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ToggleService } from '../header/toggle.service';


@Component({
  selector: 'app-unisidebar',
  standalone: true,
  imports: [NgScrollbarModule, MatExpansionModule, RouterLinkActive, RouterModule, RouterLink, NgClass, FeathericonsModule],
  templateUrl: './unisidebar.component.html',
  styleUrl: './unisidebar.component.scss'
})
export class UnisidebarComponent {
  constructor(
    private toggleService: ToggleService
) {
    this.toggleService.isToggled$.subscribe(isToggled => {
        this.isToggled = isToggled;
    });
}

// Toggle Service
isToggled = false;
toggle() {
    this.toggleService.toggle();
}

// Mat Expansion
panelOpenState = false;

}
