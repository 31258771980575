import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { UnisidebarComponent } from './common/unisidebar/unisidebar.component';
import { NavigationCancel, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { CommonModule, isPlatformBrowser, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { ToggleService } from './common/header/toggle.service';
import { filter } from 'rxjs/operators';
import { SummarycardComponent } from './common/summarycard/summarycard.component';


@Component({
  selector: 'app-unidashboard',
  standalone: true,
  imports: [UnisidebarComponent,RouterOutlet, CommonModule, RouterLink, HeaderComponent, FooterComponent,SummarycardComponent],
  templateUrl: './unidashboard.component.html',
  styleUrl: './unidashboard.component.scss',
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class UnidashboardComponent {
  title = 'Trinta -  Angular 17 Material Design Admin Dashboard Template';
    routerSubscription: any;
    location: any;

    constructor(
        public router: Router,
        public toggleService: ToggleService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    // Toggle Service
    isToggled = false;

    // Dark Mode
    toggleTheme() {
        this.toggleService.toggleTheme();
    }

    // Settings Button Toggle
    toggle() {
        this.toggleService.toggle();
    }

    // ngOnInit
    ngOnInit(){
        if (isPlatformBrowser(this.platformId)) {
            this.recallJsFuntions();
        }
    }

    // recallJsFuntions
    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            this.scrollToTop();
        });
    }
    scrollToTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }
}
