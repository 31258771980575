<div [class.dark-theme]="toggleService.isDark()">
  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <!-- Main Content -->
  <div
    class="main-content transition d-flex flex-column"
    [ngClass]="{ active: isToggled }"
  >
    <app-header></app-header>
    <router-outlet></router-outlet>
    <div class="flex-grow-1"></div>
    <app-footer></app-footer>
  </div>
</div>

<!-- @if (!isLogin) {
  <app-header (SideNavToggle)="sidenav.toggle()"></app-header>
}
<mat-sidenav-container autosize>
  <mat-sidenav #sidenav mode="side" position="end" fixedInViewport="true">
    <app-sidebar (closeSideNav)="sidenav.close()"></app-sidebar>
  </mat-sidenav>

<mat-sidenav-content class="mat-sidenav-content">

  @if (this.viewedPage !=='Appointment') {
    <div class="tableBorder divBorder">
      @if (viewedPage === 'Vitals' || viewedPage === 'Diagnosis' || viewedPage === 'Patient Prescription') {
        <div>
          <div fxLayout="row" fxLayoutAlign="space-between">
            <p style="padding: 10px 0px 0px 9px;">Patient Name: <span class="color-primary click-here" (click)="redirect()" routerLinkActive="router-link-active">{{patientName}}</span></p>
            @if (this.totalCarePlan != 0) {
              <p style="padding: 10px 10px 0px 9px;">Care Plan Visit: <span class="color-primary click-here">{{ this.currentCarePlan}} of {{ this.totalCarePlan}} </span></p>
            }
          </div>
          <div class="marginhead" >
            <h4 id="underline-gap" class="m-0 pb-2 font-size-19 capitalize text-middle">{{viewedPage | titlecase}}</h4>
          </div>
        </div>
      }
      @if (viewedPage !== 'Vitals' && viewedPage !== 'Diagnosis' && viewedPage !== 'Patient Prescription') {
        <div class="margin-div" >
          <h4 id="underline-gap" class="m-0 pb-2 font-size-19 capitalize text-middle">{{viewedPage | titlecase}}</h4>
        </div>
      }
      <router-outlet></router-outlet>
    </div>
  }
  @if (this.viewedPage =='Appointment') {
    <div >
      <router-outlet></router-outlet>
    </div>
  }
</mat-sidenav-content>
</mat-sidenav-container> -->
