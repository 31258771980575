import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

export interface Task {
  name: string;
  completed: boolean;
  subtasks?: Task[];
}

@Component({
  selector: 'app-department-modal',
  standalone: true,
  templateUrl: './department-modal.component.html',
  styleUrl: './department-modal.component.scss',
  imports: [MatCheckboxModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatDividerModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartmentModalComponent {
  constructor(private fb: FormBuilder) {}
  ngOnInit() {}



  readonly task = signal<Task>({
    name: 'Select all departments',
    completed: true,
    subtasks: [
      { name: 'Kayachikitsa (General Medicine)', completed: true},
      { name: 'Panchakarma (Detoxification and Rejuvenation)', completed: true},
      { name: 'Shalya Tantra (Surgery)', completed: true},
      { name: 'Shalakya Tantra (ENT and Ophthalmology)', completed: true },
      { name: 'Prasuti Tantra and Stree Roga (Obstetrics and Gynecology)', completed: true },
      { name: 'Bal Roga/Kaumarbhritya (Pediatrics)', completed: true },
      { name: 'Swasthavritta (Preventive Medicine)', completed: true },
      { name: 'Maulik Siddhant (Basic Principles)', completed: true },
      { name: 'Rasa Shastra (Alchemy and Pharmaceutics)', completed: true },
      { name: 'Dravyaguna (Materia Medica)', completed: true },
      { name: 'Agad Tantra (Toxicology)', completed: true },
      { name: 'Sharir Kriya (Physiology)', completed: true },
    ],
  });

  readonly partiallyComplete = computed(() => {
    const task = this.task();
    if (!task.subtasks) {
      return false;
    }
    return task.subtasks.some(t => t.completed) && !task.subtasks.every(t => t.completed);
  });

  update(completed: boolean, index?: number) {
    this.task.update(task => {
      if (index === undefined) {
        // Select All
        task.completed = completed;
        task.subtasks?.forEach(t => (t.completed = completed));
      } else {
        // Select Specific
        task.subtasks![index].completed = completed;
        task.completed = task.subtasks?.every(t => t.completed) ?? true;
      }
      return {...task};
    });
  }
}
