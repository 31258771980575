<div class="onboardingInsideWrapper">
  <form [formGroup]="hospitalInfoFormGroup">
    <mat-grid-list cols="2" rowHeight="1100px">
      <mat-grid-tile colspan="1" rowspan="1">
        <mat-card class="innerCardWrap">
          <mat-card-header>
            <img mat-card-avatar mat-card-avatar src="./assets/images/icon-hospital.png" class="hospital-image" />
            <mat-card-title>Hospital Info</mat-card-title>
            <mat-card-subtitle>Name & Contact Details</mat-card-subtitle>
          </mat-card-header>

          <mat-card-content>
            <mat-form-field class="form-field-full-width">
              <mat-label>Hospital Name</mat-label>
              <input matInput placeholder="Hospital Name" formControlName="organizationBranchName" />
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label>Address Line 1</mat-label>
              <input matInput placeholder="Address Line 1" formControlName="address1" />
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label>Address Line 2</mat-label>
              <input matInput placeholder="Address Line 2" formControlName="address2" [disabled]="true" />
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label>City</mat-label>
              <input matInput placeholder="City" formControlName="city" />
            </mat-form-field>

            <mat-form-field class=" form-field-full-width">
              <mat-label>State</mat-label>
              <input matInput placeholder="State" formControlName="state" />
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label>Zipcode</mat-label>
              <input matInput placeholder="Zipcode" formControlName="zip" />
            </mat-form-field>

            <mat-form-field class=" form-field-full-width">
              <mat-label>Country</mat-label>
              <input matInput placeholder="Country" formControlName="country" />
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label>Phone Number</mat-label>
              <input matInput placeholder="Phone Number" formControlName="phoneNumber" />
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label>WhatsApp Number</mat-label>
              <input matInput placeholder="WhatsApp Number" formControlName="whatsappNumber" />
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label>Fax Number</mat-label>
              <input matInput placeholder="Fax Number" formControlName="faxNumber" />
            </mat-form-field>

            <mat-form-field class=" form-field-full-width">
              <mat-label>Facility ID</mat-label>
              <input matInput placeholder="Facility ID" formControlName="facilityId" />
            </mat-form-field>

            <mat-form-field class=" form-field-full-width">
              <mat-label>License Number</mat-label>
              <input matInput placeholder="License Number" formControlName="licenseNumber" />
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label>Accreditation number</mat-label>
              <input matInput placeholder="Accreditation number" formControlName="accreditationNumber" />
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile colspan="1" rowspan="1">
        <mat-card class="innerCardWrap" style="margin-left: 10px">
          <mat-card-header>
            <img mat-card-avatar mat-card-avatar src="./assets/images/icon-admin.png" class="hospital-image" />
            <mat-card-title>Admin Info</mat-card-title>
            <mat-card-subtitle>Admin User Details</mat-card-subtitle>
          </mat-card-header>

          <mat-card-content>
            <mat-form-field class="form-field-full-width">
              <mat-label>First Name</mat-label>
              <input matInput placeholder="First Name" formControlName="firstName" />
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label>Last Name</mat-label>
              <input matInput placeholder="Last Name" formControlName="lastName" />
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label>Aadhar Number</mat-label>
              <input matInput placeholder="Aadhar Number" formControlName="aadharCard" />
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label class="LoginLable">Gender</mat-label>
              <mat-select formControlName="gender">
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
                <mat-option value="other">Other</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [formControl]="birthDate" />
              <mat-label>Date of Birth</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="dateOfBirth" (click)="picker.open()" [max]="maxDate" onchange="shoe()" readonly>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" formControlName="email" />
            </mat-form-field>

            <mat-form-field class="form-field-full-width">
              <mat-label>Mobile No.</mat-label>
              <input matInput placeholder="Mobile No." formControlName="cellPhone" />
            </mat-form-field>

            <mat-form-field fxFlex="" class="form-field-full-width">
              <mat-label>Password</mat-label>
              <input matInput formControlName="password" class="loginEmail" type="password"
                [type]="hide ? 'text' : 'password'" />
              <mat-icon matSuffix (click)="hide=!hide" color="primary">{{hide ? 'visibility' :
                'visibility_off'}}</mat-icon>
            </mat-form-field>
            @if (hospitalInfoFormGroup.get('password')?.hasError('minlength') &&
            hospitalInfoFormGroup.get('password')?.touched) {
            <div class="error" style="margin-top:-20px;margin-bottom: 2px;">
              Password must be at least 6 characters long.
            </div>
            }
            <mat-form-field fxFlex="" class="form-field-full-width">
              <mat-label>Confirm Password</mat-label>
              <input matInput formControlName="confirmPassword" class="loginEmail" type="password"
                [type]="hide1 ? 'text' : 'password' " />
              <mat-icon matSuffix (click)="hide1=!hide1" color="primary">{{hide1 ? 'visibility' :
                'visibility_off'}}</mat-icon>
            </mat-form-field>
            @if (hospitalInfoFormGroup.get('confirmPassword')?.touched &&
            hospitalInfoFormGroup.controls['confirmPassword']['errors']?.['MatchPassword']) {
            <div class="error">
              Password does not match
            </div>
            }
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
</div>