import { CommonModule, DatePipe, NgClass } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Title, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AgendaService, DayService, MonthAgendaService, MonthService, ScheduleModule, WeekService, WorkWeekService } from '@syncfusion/ej2-angular-schedule';
import { getApps, initializeApp } from 'firebase/app';
import { routes } from './app/app-routes';
import { AppComponent } from './app/app.component';
import { CoreModule } from './app/core/core.module';
import { LoaderService } from './app/core/services/loader.service';
import { reducers } from './app/reducer';
import { SharedModule } from './app/shared/shared.module';
import { environment } from './environments/environment';
// import { AgmCoreModule } from '@agm/core';
// import { GoogleMapsModule } from "@angular/google-maps";
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { Loader } from '@googlemaps/js-api-loader';
// import { HeaderInterceptor } from './app/core/interceptor/jsoninterceptor';

const firebaseApp = getApps().length === 0 ? initializeApp(environment.firebase) : getApps()[0];

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            NgClass,
            CommonModule,
            SharedModule, 
            CoreModule, 
            HttpClientModule,
            HttpClientJsonpModule,
            FormsModule, 
            ReactiveFormsModule, 
            FlexLayoutModule, 
            StoreModule.forRoot(reducers), 
            EffectsModule.forRoot(), 
            environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 50 }), 
            ScheduleModule,
            NgxGpAutocompleteModule,
            // AgmCoreModule.forRoot({
            //     apiKey: 'AIzaSyAUpBg5-S_EVVjj8bqX_M6ffFeiZ3h_ht4',
            //     libraries: [ 'places' ]
            //   }),
            //   GoogleMapsModule
      
            // SidebarModule,
            // ToggleSidebarModule,
        ),
        provideRouter(routes),
        Title,
        LoaderService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        DatePipe,
        DayService,
        WeekService,
        WorkWeekService,
        MonthService,
        AgendaService,
        MonthAgendaService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        // {provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true},
        // {provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true},
        {
            provide: Loader,
            useValue: new Loader({
              apiKey: 'AIzaSyAUpBg5-S_EVVjj8bqX_M6ffFeiZ3h_ht4',
              libraries: ['places']
            })
          },
    ]
})
    .catch(err => console.error(err));
