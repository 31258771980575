export const config = {
  partialUrls: {
    doctorLogin: '/api/Login/doctorlogin',
    staffLogin: '/api/login/StaffLogin',
    validateStaff: '/api/login/ValidateUser',
    appointmentListing: '/RequestConsultation/ListOpdConsultation',
    ipdListing: '/RequestConsultation/ListIpdConsultation',
    searchPatient: '/api/Patient/ListPatient',
    createPatient: '/api/Registration/CreatePatient',
    getPatientInfo: '/api/Patient/PatientAllDetails',
    getPatientVisit: '/api/Patient/GetPatientVisit',
    createPatientVital: '/api/Analysis/AddVitals',
    viewPatientVital: '/api/Analysis/ViewVitals',
    addStaffVisitAttendance: '/api/Organization/AddStaffPatientAttendance',
    createOrgCarePlan: '/RequestConsultation/AddOrganizationCarePlan',
    viewOrgCarePlan: '/RequestConsultation/ViewOrganizationCarePlan',
    viewDiagnosisDetails: '/api/Diagnosis/ListDiagnosisOpen',
    viewInstructions: '/api/Diagnosis/ViewInstruction',
    updatePatientVital: '/api/Analysis/UpdateVitals',
    healthDetails: '/api/Patient/AddHealthDetails',
    AddAppointment: '/RequestConsultation/AddConsultation',
    ViewAppointment: '/RequestConsultation/ViewConsultation',
    updateAppointmentStatus: '/RequestConsultation/UpdateConsultation',
    patientregistration: '/api/Registration/CreatePatient',
    DoctorRegistration: '/api/Registration/CreateDoctor',
    OrganizationRegistration: '/api/registration/createorganization',
    patientLogin: '/api/Login/PatientLogin',
    viewAnalysisCount: '/api/Diagnosis/ViewAnalysisCount',
    viewAnalysisCountOpen: '/api/Diagnosis/ViewAnalysisCountOpen',
    ListPrakrutiAnalysis: '/api/Analysis/ListPrakrutiAnalysis',
    ListPrakrutiAnalysisOpen: '/api/Analysis/ListPrakrutiAnalysisOpen',
    ListGutAnalysis: '/api/Analysis/ListGutAnalysis',
    ListGutAnalysisOpen: '/api/Analysis/ListGutAnalysisOpen',
    updateGutAnalysis: '/api/Analysis/UpdateGutAnalysis',
    updatePrakrutiAnalysis: '/api/Analysis/UpdatePrakrutiAnalysis',
    bookAppointment: '/api/Patient/AppointmentBooking',
    scheduleAppointment: '/api/Patient/ListofDoctorAppointment',
    confirmationAppointment: '/api/Patient/AppointmentBookingRequest',
    followUpAppointment: '/api/Patient/FollowUpAppointments',
    SelfRegistrationMessage: '/api/Patient/SelfRegistrationMessage',
    AnalysisFormMessage: '/api/Patient/AnalysisFormMessage',
    SendConsentMessage: '/api/Patient/SendConsentMessage',
    GetOrganizationInfo: '/api/Organization/getOrganizationInfo',
    GetDepartments: '/api/Organization/getHospitalDepartments',
    AddDepartmentsAndRooms: '/api/Organization/addDepartmentAndRooms',
    GetMasterReportData: '/api/UniversityDashboard/getmasterreport',
    GetPatientOverview: '/api/UniversityDashboard/getpatientoverview',
    GetHospitalList: '/api/UniversityDashboard/getorganizationbranches',
    GetAlertList: '/api/UniversityDashboard/getalertsdata',
    GetUserRoles: '/api/Organization/getRoles',
    GetRolePrivileges: '/api/Organization/getRolesPrivileges',
    AddStaff: '/api/Organization/AddStaff',
    SendEmailOtp: '/api/Communication/SendEmail',
    GetOrganizationBranches: '/api/Organization/getOrganizationBranches',
    AddOrganizationBranch: '/api/registration/AddOrganizationBranch',
    GetOrganizationBranchDepartments:
      '/api/Organization/getOrganizationBranchDepartment',
    admitPatient: '/api/Patient/AddPatientVisitCheckIn',
    addVitals: '/api/Analysis/AddVitals',
    getDiagnosisDetails: '/api/Diagnosis/GetDiagnosisData',
    addDoctorDiagnosis: '/api/Diagnosis/AddDoctorDiagnosis',
    getCarePlanAlerts: '/RequestConsultation/ViewInPatientCarePlanDetails',
    getUserOrganizationBranches:
      '/api/Organization/getUserOrganizationBranchInfo',
    getKriyas: '/api/Diagnosis/ViewProcedure',
    getMedications: '/api/Diagnosis/ViewMedication',

    // auth
    CheckAadhaar: '/api/auth/checkadhaar',
    GetUserCommunicationDetails: '/api/auth/getusercommunicationdetails',
    UpdateStaffVerificationStatus: '/api/auth/updatestaffstatus',
    UpdateUserPassword: '/api/auth/updateuserpassword',

    //Organizations
    GetAllUserOrganizations: '/api/Organization/getallorganizationsofuser',
    CompleteBranchOnboarding: '/api/Organization/completebranchonboarding',
    GetAllStaff: '/api/Organization/getallstaffs',
    GetAllStaffNames: '/api/Organization/getallstaffsname',
    GetOrgBranchSchedule: '/api/Organization/getorgbranchschedule',
    GetDrugs: '/api/Prescription/ViewDrug',
    GetUserBranchRolesAndRights: '/api/Organization/getrolesandrights',

    DischargePatient: '/api/Patient/DischargePatient',
    GetPatientPermissionForOrg: '/api/Patient/GetPatientPermissionForOrg',
  },
};
