import { UtilityService } from 'src/app/core/services/utility.service';
import { Component, OnInit } from '@angular/core';
import {
  sidebarAnimation,
  iconAnimation,
  labelAnimation,
} from '../../animations';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterModule,
} from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { FeathericonsModule } from '../../icons/feathericons/feathericons.module';
import { ToggleService } from '../header/toggle.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AuthService } from '../../services/auth.service';
import { UserOrganizationBranchDto } from 'src/app/models/user-organization-branch-dto';
import { SharedModule } from '../../shared.module';
import { SessionUserType } from 'src/app/core/models/session-type';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    sidebarAnimation(),
    labelAnimation(),
    iconAnimation(),
    labelAnimation(),
  ],
  standalone: true,
  imports: [
    NgScrollbarModule,
    MatExpansionModule,
    RouterLinkActive,
    RouterModule,
    RouterLink,
    NgClass,
    FeathericonsModule,

    RouterLink,
    RouterLinkActive,
    MatFormFieldModule,
    MatSelectModule,
    CommonModule,
    SharedModule,
  ],
})
export class SidebarComponent implements OnInit {
  isExpanded = true;
  showMenu: string = '';
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  sidebarState: any;
  panelOpenState = false;
  userInfo: any;
  userMenu: any;

  sessionUserType: SessionUserType = SessionUserType.None;
  menu: any;

  get orgBranch(): boolean {
    return localStorage.getItem('organizationBranchId') ? true : false;
  }

  constructor(
    private toggleService: ToggleService,
    private router: Router,
    private authService: AuthService
  ) {
    this.toggleService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });

    this.sessionUserType = UtilityService.sessionUserType;

    // this.menu = [
    //   // {
    //   //   icon: '/assets/images/sidebar/dashboard.svg',
    //   //   name: 'Appointment',
    //   //   path: `dashboard/doctor`,
    //   // },
    //   // {
    //   //   icon: '/assets/images/sidebar/consulting.svg',
    //   //   name: `Consulting`,
    //   //   // path: 'patient/analysis',
    //   // },
    //   // {
    //   //   icon: '/assets/images/sidebar/schedule.svg',
    //   //   name: `Schedule`,
    //   //   // path: '',
    //   // },
    //   // {
    //   //   icon: '/assets/images/sidebar/history.svg',
    //   //   name: `History`,
    //   //   // path: '',
    //   // },
    // ];

    switch (this.sessionUserType) {
      case SessionUserType.Patient:
        this.menu = [
          {
            icon: '/assets/images/sidebar/dashboard.svg',
            name: 'Home',
            path: `dashboard/patient`,
          },
        ];
        break;

      case SessionUserType.Staff:
        this.menu = [
          {
            icon: '/assets/images/sidebar/dashboard.svg',
            name: 'Appointment',
            path: `dashboard/doctor`,
          },
        ];
        break;

      default:
        this.menu = [];
        break;
    }
  }

  ngOnInit() {
    this.userInfo = JSON.parse(UtilityService.getLocalStorage('user_info'));
    let menu = JSON.parse(UtilityService.getLocalStorage('user_menu'));
    this.userMenu = [];
  }

  // Toggle Service
  isToggled = false;
  toggle() {
    this.toggleService.toggle();
  }

  // Mat Expansion
  // panelOpenState = false;

  logout(e: any) {
    e.preventDefault();
    localStorage.clear();
    this.router.navigate(['/auth/doctor/login']);
  }

  patientLogout(e: any) {
    e.preventDefault();
    localStorage.clear();
    this.router.navigate(['/auth/patient/login']);
  }
}
