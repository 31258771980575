@if (loading) {
  <div class="web-container" fxLayout="row" fxLayoutAlign="center center">
    <app-loader></app-loader>
  </div>
}
@if (webpagePropties || !loading) {
  <section style="background: var(--bg-color);">
    <div class="container">
      <div class="info-detail">
        <div class="card-title">
          <p>{{webpagePropties?.doctorName}}</p>
        </div>
        <div class="divider"></div>
        <div class="logo-wrapper">
          <h2>Clinic Info</h2>
        </div>
        <div class="contact-info">
          <div class="email-info-title">
            <i aria-hidden="true" class="elementkit-infobox-icon fas fa-envelope-open icons"></i>
            <a (click)="emailOpen(webpagePropties?.email)" style="cursor: pointer;">{{webpagePropties?.email}}</a>
          </div>
          <div class="email-info-title">
            <i aria-hidden="true" class="elementkit-infobox-icon fas fa-solid fa-phone icons"></i>
            <a (click)="callPhoneNumber(webpagePropties?.phoneNumber)" style="cursor: pointer;"> {{webpagePropties?.phoneNumber}}</a>
          </div>
          <div class="email-info-title">
            <i aria-hidden="true" class="elementkit-infobox-icon fas fa-hospital-alt icons"></i>
            <span>{{webpagePropties?.clinicName}}</span>
          </div>
          <div class="email-info-title">
            <i aria-hidden="true" class="elementkit-infobox-icon fas fa-map-marker-alt icons"></i>
            <a [href]="webpagePropties?.googleMapLink" target="_blank">
              {{webpagePropties?.clinicAddress1!= ('' || undefined)
              ? webpagePropties?.clinicAddress1 + ',': ''}}
              {{webpagePropties?.clinicAddress2 != ('' || undefined)
              ? webpagePropties?.clinicAddress2 + ',': '' }}
              {{webpagePropties?.city!= ('' || undefined) ? webpagePropties?.city + ',': ''}}
              {{webpagePropties?.state!= ('' || undefined) ? webpagePropties?.state + ',': ''}}
              {{webpagePropties?.country!= ('' || undefined) ? webpagePropties?.country + ',': ''}}
              {{webpagePropties?.zipcode!= ('' || undefined) ? webpagePropties?.zipcode + '':
            ''}}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
}

@if (webpagePropties) {
  <section class="doctor-section">
    <div class="container" fxLayout="row" fxLayout.lt-sm="column" fxFlex>
      @if (webpagePropties?.profileImage) {
        <div class="doctor-img file-upload" fxFlex="25"
          fxFlex.lt-md="30" fxFlex.lt-sm="100">
          <img [src]="webpagePropties?.profileImage" alt="Doctor Image">
        </div>
      }
      <div class="doctor-info" fxFlex="69" fxFlex.lt-md="64" fxFlex.lt-sm="100">
        <!-- <h2 class="doctor-name">{{webpagePropties?.doctorName}}</h2> -->
        <p class="doctor-description">{{webpagePropties?.description }}</p>
      </div>
    </div>
  </section>
}

@if (webpagePropties) {
  <section class="details-section">
    <div class="container">
      <div style="width: 100%">
        @if (webpagePropties?.degree != '') {
          <div class="element-card">
            <div class="heading">Credentials</div>
            <div class="contents">{{webpagePropties?.degree}}
            </div>
          </div>
        }
        @if (webpagePropties?.institute != '') {
          <div class="element-card">
            <div class="heading">Education and institution </div>
            <div class="contents">{{webpagePropties?.institute}}
            </div>
          </div>
        }
        @if (webpagePropties?.yearsInPractice != '') {
          <div class="element-card">
            <div class="heading">Years in practice</div>
            <div class="contents">{{webpagePropties?.yearsInPractice}}</div>
          </div>
        }
        @if (webpagePropties?.achievements != '') {
          <div class="element-card">
            <div class="heading">Achievements</div>
            <div class="contents">{{webpagePropties?.achievements}}</div>
          </div>
        }
        @if (webpagePropties?.expertise != '') {
          <div class="element-card">
            <div class="heading">Specific area of expertise</div>
            <div class="contents">
              {{webpagePropties?.expertise}}
            </div>
          </div>
        }
      </div>
    </div>
  </section>
}
@if (webpagePropties && services.length != 0) {
  <section class="speciality-section" [style.background]="'url('+ webpagePropties?.backgroundImage +')'"
    >
    <p class="text-title">Services</p>
    <div class="container">
      <div class="box-container" fxLayout="row wrap" fxLayoutAlign="center" fxFlex="100">
        @for (service of services; track service; let i = $index) {
          <div class="box"
            [ngClass]="{'display-none':service.service == ''}" fxFlex="31" fxFlex.lt-md="48" fxFlex.lt-sm="100">
            <div>
              <h2>0{{i + 1}}</h2>
              <h5 class="white-break">{{service.service}}</h5>
            </div>
          </div>
        }
      </div>
    </div>
  </section>
}

@if (webpagePropties) {
  <section class="speciality-section">
    <p class="text-title">Schedule Your Appointment</p>
    <div class="element-card container" fxLayout="column" style="margin: 0 auto !important;">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" align="end">
        <div class="color-blue"></div>
        <p>Available Slot</p>
      </div>
      <ejs-schedule #scheduleObj width='100%' height='80vh' [eventSettings]="eventSettings"
        [selectedDate]="selectedDate" (popupOpen)="onPopupOpen($event)" (actionComplete)='onActionComplete($event)'
        cssClass='custom-class' [timeScale]="timeScale" style="overflow:visible; " [workHours]="scheduleHours"
        (eventRendered)="applyCategoryColor($event)">
        <e-views>
          <!-- <e-view option="Day" [interval]="1"></e-view> -->
          <e-view option="Week" [format]='formatString' [interval]="1"></e-view>
          <e-view option="Month" [interval]="1"></e-view>
        </e-views>
      </ejs-schedule>
    </div>
  </section>
}

@if (webpagePropties && testimonials.length != 0) {
  <section class="testimonials-section" [style.background]="'url('+ webpagePropties?.backgroundImage2 +')'"
    >
    <p class="text-title">Testimonials </p>
    <div class="container">
      <div class="box-container" fxLayout="row wrap" fxLayoutAlign="center" fxFlex>
        @for (testimonial of testimonials; track testimonial; let i = $index) {
          <div class="testimonial" fxFlex="31" fxFlex.lt-md="48" fxFlex.lt-sm="100"
            [ngClass]="{'display-none': testimonial.testimonial == '' || testimonial.clientName == ''}">
            <div class="test-info">
              <div class="test-name">
                <span>&#64;{{testimonial.clientName}}</span>
                <!-- @{{testimonial.clientName.replaceAll(' ', '')}} -->
              </div>
            </div>
            <p class="white-break">
              {{testimonial.testimonial}}
            </p>
          </div>
        }
      </div>
    </div>
  </section>
}
@if (webpagePropties) {
  <section class="speciality-section">
    <div class="element-card container" style="margin: 0 auto !important;">
      <iframe width="1200" height="450" frameborder="0" style="border:0" referrerpolicy="no-referrer-when-downgrade"
        [src]="this.safeMapUrl" loading="lazy" allowfullscreen>
      </iframe>
    </div>
  </section>
}

@if (webpagePropties) {
  <footer>
    <p>&copy; 2024 neemandtulsi.com. All rights reserved.</p>
  </footer>
}