import { Constants } from 'src/app/constant/constant';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UtilityService } from '../services/utility.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  count: any = 0;
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const subdomain = window.location.hostname.split('.')[0];

    if (
      subdomain === 'stage-portal' ||
      subdomain === 'portal' ||
      subdomain === 'dev-portal' ||
      subdomain === 'localhost' ||
      subdomain === 'stagenat' ||
      subdomain === 'prodnat' 
    ) {
      if (
        (state.url.includes('/self-registration/analysis') ||
          state.url.includes('/patient/consent')) &&
        localStorage.getItem('redirectUrl') == undefined &&
        window.location.search != ''
      ) {
        if (localStorage.getItem('userType') == 'Doctor') {
          localStorage.clear();
          // this.router.navigate(['/auth/patient/login'])
          console.log(window.location);
          localStorage.setItem(
            'redirectUrl',
            window.location.href.split(`${window.location.origin}`)[1]
          );
          this.router.navigate(['/auth/patient/login'], {
            queryParams: { returnUrl: state.url },
          });
        } else if (localStorage.getItem('userType') == 'Patient') {
          console.log(window.location);
          this.router.navigate(['/auth/patient/login'], {
            queryParams: { returnUrl: state.url },
          });
          localStorage.setItem(
            'redirectUrl',
            window.location.href.split(`${window.location.origin}`)[1]
          );
        } else {
          this.router.navigate(['/auth/patient/login'], {
            queryParams: { returnUrl: state.url },
          });
          localStorage.setItem(
            'redirectUrl',
            window.location.href.split(`${window.location.origin}`)[1]
          );
        }
        return false;
      } else if (
        (state.url.includes('/self-registration/analysis') ||
          state.url.includes('/patient/consent')) &&
        localStorage.getItem('redirectUrl') != undefined &&
        window.location.search != '' &&
        UtilityService.getLocalStorage('accessToken') == undefined
      ) {
        if (localStorage.getItem('userType') == 'Doctor') {
          localStorage.clear();
          // this.router.navigate(['/auth/patient/login'])
          console.log(window.location);
          localStorage.setItem(
            'redirectUrl',
            window.location.href.split(`${window.location.origin}`)[1]
          );
          this.router.navigate(['/auth/patient/login'], {
            queryParams: { returnUrl: state.url },
          });
        } else if (localStorage.getItem('userType') == 'Patient') {
          console.log(window.location);
          this.router.navigate(['/auth/patient/login'], {
            queryParams: { returnUrl: state.url },
          });
          localStorage.setItem(
            'redirectUrl',
            window.location.href.split(`${window.location.origin}`)[1]
          );
        } else {
          this.router.navigate(['/auth/patient/login'], {
            queryParams: { returnUrl: state.url },
          });
          localStorage.setItem(
            'redirectUrl',
            window.location.href.split(`${window.location.origin}`)[1]
          );
        }
        return false;
      } else if (state.url.includes('/patient/book-appointment')) {
        if (this.count == 0) {
          UtilityService.setLocalStorage(
            'accessToken',
            window.location.search
              .split('?accessToken=')[1]
              .split('&websiteBookappointment=')[0]
          );
          var websiteBookappointment = decodeURIComponent(
            window.location.search
              .split('&websiteBookappointment=')[1]
              .split('&userDetail=')[0]
          );
          UtilityService.setLocalStorage(
            'websiteBookappointment',
            websiteBookappointment
          );
          UtilityService.setLocalStorage(
            'userDetail',
            decodeURIComponent(window.location.search.split('&userDetail=')[1])
          );
          UtilityService.setLocalStorage(
            'user_info',
            decodeURIComponent(window.location.search.split('&userDetail=')[1])
          );
          UtilityService.setLocalStorage('userType', 'Patient');
          console.log(window.location.search);
          this.router.navigate(['/patient/book-appointment']);
          console.log(window.location.search);
          this.count = 1;
          return false;
        } else if (this.count == 1) {
          // this.router.navigate(['/patient/book-appointment']);
          return true;
        } else {
          return true;
        }
      } else if (UtilityService.getLocalStorage('accessToken')) {
        return true;
      } else {
        this.router.navigate(['/auth/doctor/login'], {
          queryParams: { returnUrl: state.url },
        });
        console.log('here: ',state)
        return false;
      }
    } else if(subdomain === 'dev-oversight' || subdomain === 'oversight'){
      if(UtilityService.getLocalStorage('accessToken')) return true;
      else{
        this.router.navigate(['/unidashboard/login']);
        console.log('here: ',state)
        return false;
      }
    } else {
      // this.router.navigate([`${subdomain}`]);
      return false;
    }
  }
}
