import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  private baseUrl = environment.baseURL;
  private scheduleApiUrl = '/api/Doctor';

  constructor(private http: HttpClient) { }

  addSchedule(data: any): Observable<any> {
    const url = `${this.baseUrl}${this.scheduleApiUrl}/AddSchedule`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.post(url, data, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error adding Clinic Time:', error);
        return throwError(error);
      })
    );
  }

  updateSchedule(data: any): Observable<any> {
    const url = `${this.baseUrl}${this.scheduleApiUrl}/EditSchedule`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.post(url, data, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error update Clinic Time:', error);
        return throwError(error);
      })
    );
  }

  deleteSchedule(data: any): Observable<any> {
    const url = `${this.baseUrl}${this.scheduleApiUrl}/DeleteSchedule`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.post(url, data, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error update Clinic Time:', error);
        return throwError(error);
      })
    );
  }

  viewSchedule(day: any, userId: any, patientId: any,organizationBranchId ?: string): Observable<any> {
    // console.log(doctorId, patientId)
    var patientQuery = patientId != '' ? `&patientId=${patientId}` : ''

    if(organizationBranchId) patientQuery += `&orgBranchId=${organizationBranchId}`

    // + patientQuery
    const url = `${this.baseUrl}${this.scheduleApiUrl}/ViewSchedule?workDay=${day}&userId=${userId}`+patientQuery;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error fetching Prakruti questions:', error);
        return throwError(error);
      })
    );
  }

  viewScheduleOpen(day: any, doctorId: any, patientId: any): Observable<any> {
    // console.log(doctorId, patientId)
    var patientQuery = patientId != '' ? `&patientId=${patientId}` : ''
    // + patientQuery
    const url = `${this.baseUrl}${this.scheduleApiUrl}/viewScheduleOpen?workDay=${day}&doctorId=${doctorId}`+patientQuery;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error fetching Prakruti questions:', error);
        return throwError(error);
      })
    );
  }

  fetchDoctors(patientId: any) {
    const url = `${this.baseUrl}/api/Patient/Dashboard?patientId=${patientId}`;
    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error fetching doctors:', error);
        return throwError(error);
      })
    );
  }
  private createAuthorizationHeader(): HttpHeaders {
    const accessToken = localStorage.getItem('accessToken');

    return new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    });
  }
}
