// import { MapsAPILoader } from '@agm/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule, DatePipe } from '@angular/common';
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  NO_ERRORS_SCHEMA,
  NgZone,
  ViewChild,
} from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatOption,
} from '@angular/material/autocomplete';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  ActionEventArgs,
  AgendaService,
  DayService,
  DragAndDropService,
  EventRenderedArgs,
  EventSettingsModel,
  MonthService,
  PopupOpenEventArgs,
  ResizeService,
  ScheduleComponent,
  ScheduleModule,
  TimeScaleModel,
  WeekService,
  WorkHoursModel,
  WorkWeekService,
} from '@syncfusion/ej2-angular-schedule';
import { extend } from '@syncfusion/ej2-base';
import moment from 'moment';
import { Observable, map, startWith } from 'rxjs';
import { Constants } from 'src/app/constant/constant';
import { LoaderService } from 'src/app/core/services/loader.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { AppointmentService } from 'src/app/shared/services/appointment.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ScheduleService } from 'src/app/shared/services/doctor-service/schedule.service';
import { AppointmentDialogComponent } from '../book-appointment/appointment-dialog/appointment-dialog.component';
import {
  NgxGpAutocompleteDirective,
  NgxGpAutocompleteModule,
  NgxGpAutocompleteOptions,
} from '@angular-magic/ngx-gp-autocomplete';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MatProgressSpinner,
  MatProgressSpinnerModule,
} from '@angular/material/progress-spinner';
import { Role } from 'src/app/shared/model/roles-and-right/roles';
export type UserSchedulerView = 'Month' | 'TimelineWeek';
// import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-doctor-profile-setting',
  providers: [
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    ResizeService,
    DragAndDropService,
  ],
  templateUrl: './doctor-profile-setting.component.html',
  styleUrls: ['./doctor-profile-setting.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatCardModule,
    MatIconButton,
    MatProgressSpinnerModule,

    MatExpansionModule,
    MatIcon,
    ExtendedModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    MatOption,
    MatButton,
    MatCard,
    ScheduleModule,
    NgxGpAutocompleteModule,
    LoaderComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    CommonModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exportAs: 'ngx-places',
})
export class DoctorProfileSettingComponent implements AfterViewInit {
  panelOpenState = false;

  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['IN'] },
    types: ['geocode'],
  };
  @ViewChild('degreeInput') degreeInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  degrees: any[] = Constants.degrees;
  allDegrees: any[] = Constants.allDegrees;
  durations: any[] = Constants.durations;
  ratios: any[] = Constants.ratios;
  times: any[] = Constants.times;
  days: any[] = Constants.days;

  degreeCtrl = new UntypedFormControl();
  filteredDegree: Observable<any>;

  officeaddressFormGroup!: UntypedFormGroup;
  userInfo: any;
  personaldataFormGroup!: UntypedFormGroup;
  professionaldataFormGroup!: UntypedFormGroup;
  myForm!: UntypedFormGroup;
  clinicTimeFormGroup!: UntypedFormGroup;
  selectable3 = true;
  removable3 = true;
  // public views: Array<string> = ['Month', "Week"];
  public views: Array<string> = ['Day', 'Week', 'WorkWeek', 'Month'];
  public showQuickInfo: Boolean = false;
  public data: object[] = [
    {
      Subject: 'Morning Before OPd',
    },
  ];
  public eventSettings: EventSettingsModel = {
    allowEditing: false,
    allowDeleting: true,
    allowAdding: false,
    dataSource: [],
    fields: {
      id: 'Id',
      startTime: { name: 'StartTime', validation: { required: true } },
      endTime: { name: 'EndTime', validation: { required: true } },
    },
  };
  public formatString: string = 'HH:mm';
  public scheduleHours: WorkHoursModel = {
    highlight: true,
    start: '08:00',
    end: '23:00',
  };
  public timeScale: TimeScaleModel = {
    enable: true,
    interval: 30,
    slotCount: 2,
  };

  public timeScale1: TimeScaleModel = {
    enable: true,
    interval: 60,
    slotCount: 4,
  };
  @ViewChild('scheduleObj')
  public scheduleObj: ScheduleComponent;

  readonly separatorKeysCodes2: number[] = [ENTER, COMMA];
  public selectedDate: Date = new Date();
  events: any = [];
  selectedFile: any;
  imageSrc: any;
  duration: any;
  ratio: any;
  startDate = new Date();
  startTime: any;
  endTime: any;
  day: any;
  breakendTime: any;
  breakstartTime: any;
  timeIntervals: {
    Time1: any;
    Time2: any;
    RecurrenceRule: any;
    IsBlock: any;
    scheduleId: any;
    IsReadonly: any;
  }[] = [];
  dayName: any = [];
  doctorProfileData: any;
  timeSlotsBreak: any = [];
  datesofarray: any = [];
  currentDate: Date | null;
  datesArraywithday: any = [];
  @ViewChild('search') public searchElementRef: ElementRef;
  // public selectedAddress: PlaceResult;
  public street_number: any;
  public route2: any;
  public locality: any;
  public sublocality: any;
  public country: any;
  public state: any;
  public city: any;
  public postalCode: any;
  public address1: any;
  loading: boolean = false;
  userImage: any;
  loadingButton: boolean = false;
  loadingButton1: boolean = false;
  organizationBranchId = localStorage.getItem('organizationBranchId') || '';

  isAdmin: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private scheduleService: ScheduleService,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private loaderService: LoaderService,
    private appointmentService: AppointmentService,
    private router: Router,
    // private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private utilityService: UtilityService
  ) {
    this.filteredDegree = this.degreeCtrl.valueChanges.pipe(
      startWith(null),
      map((degree: string | null) =>
        degree ? this._filter1(degree) : this.allDegrees.slice()
      )
    );
  }

  ngOnInit(): void {
    localStorage.removeItem('isCheckedNameReschedule');
    localStorage.removeItem('isCheckedName');
    this.getProfileData();
    this.userInfo = JSON.parse(localStorage.getItem('user_info') || '');
    this.personaldataFormGroup = this.formBuilder.group({
      speciality: new UntypedFormControl('', Validators.required),
      degree: new UntypedFormControl('', Validators.required),
      institute: new UntypedFormControl('', Validators.required),
      // surgery: new UntypedFormControl('', Validators.required),
    });
    this.professionaldataFormGroup = this.formBuilder.group({
      licence: new UntypedFormControl('', Validators.required),
      registration: new UntypedFormControl('', Validators.required),
      boardmembership: new UntypedFormControl('', Validators.required),
      // surgery: new UntypedFormControl('', Validators.required),
    });

    this.officeaddressFormGroup = this.formBuilder.group({
      Street1: new UntypedFormControl('', Validators.required),
      Street2: new UntypedFormControl(''),
      City: new UntypedFormControl('', Validators.required),
      Zip: new UntypedFormControl('', Validators.required),
      State: new UntypedFormControl('', Validators.required),
      Country: new UntypedFormControl('', Validators.required),
      clinicName: new UntypedFormControl('', Validators.required),
      image: new UntypedFormControl(''),
    });
    this.clinicTimeFormGroup = this.formBuilder.group({
      day: new UntypedFormControl('', Validators.required),
      duration: new UntypedFormControl('', Validators.required),
      ratio: new UntypedFormControl('', Validators.required),
      startTime: new UntypedFormControl('', Validators.required),
      endTime: new UntypedFormControl('', Validators.required),
      breakStartTime: new UntypedFormControl('', Validators.required),
      breakEndTime: new UntypedFormControl('', Validators.required),
    });
    this.myForm = this.formBuilder.group({
      shiftOPD: new FormControl(''),
      // city: new FormControl('')
    });
    // this.getSchedule()

    this.utilityService.currentRolesAndRights.subscribe({
      next: (roles) => {
        this.isAdmin = roles.some((role) => role.roleName === Role.Admin);
        this.updateFormsByRole();
      },
    });
  }

  private updateFormsByRole() {
    if (this.isAdmin) {
      this.personaldataFormGroup.enable();
      this.professionaldataFormGroup.enable();
      this.officeaddressFormGroup.enable();
      this.clinicTimeFormGroup.enable();
    } else {
      this.personaldataFormGroup.disable();
      this.professionaldataFormGroup.disable();
      this.officeaddressFormGroup.disable();
      this.clinicTimeFormGroup.disable();
    }
  }

  isFormValid() {
    return (
      this.personaldataFormGroup.valid &&
      this.professionaldataFormGroup.valid &&
      this.officeaddressFormGroup.valid
    );
  }

  activeSchedules: any[] = [];

  isScheduleValid() {
    if (this.scheduleObj === undefined) return false;
    const events = this.scheduleObj.getCurrentViewEvents();
    return events.length > 0 ? true : false;
  }

  canProceed() {
    return this.isFormValid() && this.isScheduleValid();
  }

  selectAddress(place: google.maps.places.PlaceResult | any): void {
    // Place object from API
    console.log(place);
    this.getAddressComponentByPlace(place);
    // Text from input
    console.log(this.placesRef.el.nativeElement.value);
  }

  getAddressComponentByPlace(place: any) {
    let components = place.address_components;
    let city = null;
    let postalCode = null;
    let route2 = null;
    let route3 = null;
    let locality = null;
    let locality1 = null;
    let locality2 = null;
    let locality3 = null;
    var locality_1 = '';
    var locality_2 = '';
    var locality_3 = '';
    var street_number_1 = '';
    var router2_1 = '';
    var router3_1 = '';
    let street_number = null;
    let address1 = null;
    let state = null;
    let country = null;

    for (var i = 0, component; (component = components[i]); i++) {
      if (
        component.types[0] == 'premise' ||
        component.types[0] == 'street_number'
      ) {
        street_number = component['long_name'];
        if (street_number != null || street_number != undefined) {
          street_number_1 = street_number + ',';
        }
        // var street_number_1 = street_number != null ? street_number : ''
      }
      if (
        component.types[0] == 'route' ||
        component.types[0] == 'neighborhood'
      ) {
        route2 = component['long_name'];
        if (route2 != null || route2 != undefined) {
          router2_1 = route2 + '';
        }
      }
      if (component.types[0] == 'landmark') {
        route3 = component['long_name'];
        if (route3 != null || route3 != undefined) {
          router3_1 = route3 + '';
        }
      }
      address1 = street_number_1 + ' ' + router2_1 + ' ' + router3_1;
      // console.log( component.types[ 0 ] == "sublocality_level_1" , component.types[ 0 ] == "sublocality_level_2", component.types[ 0 ] == "sublocality_level_3"  )
      if (component.types[0] == 'sublocality_level_1') {
        locality1 = component['long_name'];
        if (locality1 != null || locality1 != undefined) {
          locality_1 = locality1 + ', ';
        } else {
          locality_1 = '';
        }
      }
      if (component.types[0] == 'sublocality_level_2') {
        locality2 = component['long_name'];
        if (locality2 != null || locality2 != undefined) {
          locality_2 = locality2 + ', ';
        } else {
          locality_2 = '';
        }
        //  locality_2 = locality2 != null ? locality2+',' : '';
      }
      if (component.types[0] == 'sublocality_level_3') {
        locality3 = component['long_name'];
        if (locality3 != null || locality3 != undefined) {
          locality_3 = locality3 + '';
        } else {
          locality_3 = '';
        }
        //  locality_3 = locality3 != null ? locality3+',' : '';
      }
      locality = locality_1 + locality_2 + locality_3;
      if (
        component.types[0] == 'locality' ||
        component.types[0] == 'administrative_area_level_3'
      ) {
        city = component['long_name'];
        if (city != null || city != undefined) {
          city = city;
        } else {
          city = '';
        }
      }
      if (component.types[0] == 'administrative_area_level_1') {
        state = component['long_name'];
      }
      if (component.types[0] == 'country') {
        country = component['long_name'];
      }
      if (component.types[0] == 'postal_code') {
        postalCode = component['long_name'];
      }
    }

    this.route2 = route2;
    this.street_number = street_number;
    this.locality = locality;
    this.city = city != null ? city : '';
    this.state = state != null ? state : '';
    this.country = country != null ? country : '';
    this.postalCode = postalCode != null ? postalCode : '';
    this.address1 = address1;
    const payload = {
      Street1: this.address1,
      Street2: this.locality,
      City: this.city,
      State: this.state,
      Country: this.country,
      Zip: this.postalCode,
    };
    this.officeaddressFormGroup.patchValue(payload);
  }

  get pfd(): { [key: string]: AbstractControl } {
    return this.personaldataFormGroup.controls;
  }

  get prfd(): { [key: string]: AbstractControl } {
    return this.professionaldataFormGroup.controls;
  }

  get ofg(): { [key: string]: AbstractControl } {
    return this.officeaddressFormGroup.controls;
  }

  getProfileData() {
    this.loading = true;
    const userInfo = UtilityService.getUser();
    this.authService
      .doctorProfileData(userInfo.id)
      .subscribe((response: any) => {
        setTimeout(() => {
          // this.mapsAPILoader.load().then(() => {
          //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          //     strictBounds: false,
          //   });
          //   autocomplete.addListener("place_changed", () => {
          //     this.ngZone.run(() => {
          //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //       console.log(place)
          //       this.getAddressComponentByPlace(place);
          //     });
          //   });
          // });
        });
        this.loading = false;
        console.log(response);
        this.doctorProfileData = response.data;
        this.locality = this.doctorProfileData.clinicAddress2;
        this.city = this.doctorProfileData.city;
        this.state = this.doctorProfileData.state;
        this.country = this.doctorProfileData.country;
        this.postalCode = this.doctorProfileData.zipCode;
        this.address1 = this.doctorProfileData.clinicAddress1;
        this.imageSrc = this.doctorProfileData.logo;
        this.userImage = this.doctorProfileData.userIMagePath;
        this.doctorProfileData.webpageURL = `${location.origin}/${this.userInfo.firstName}${this.userInfo.lastName}?id=${this.doctorProfileData.doctorId}`;
        console.log(this.imageSrc);
      });
  }

  updateProfile() {
    this.loadingButton = true;
    const userInfo = UtilityService.getUser();
    var data = {
      DoctorId: userInfo.id,
      Degree: this.pfd['degree'].value,
      Specialities: this.pfd['speciality'].value,
      InstituationName: this.pfd['institute'].value,
      LicenceNo: this.prfd['licence'].value,
      RegistrationNo: this.prfd['registration'].value,
      BoardMembership: this.prfd['boardmembership'].value,
      ClinicName: this.ofg['clinicName'].value,
      StreetAddress: this.ofg['Street1'].value,
      APT: this.ofg['Street2'].value,
      City: this.ofg['City'].value,
      State: this.ofg['State'].value,
      Country: this.ofg['Country'].value,
      ZipCode: this.ofg['Zip'].value,
      Logo: this.imageSrc,
      UserImage: this.userImage,
    };
    console.log(data);
    this.authService.updateDoctorProfile(data).subscribe(
      (response: any) => {
        this.loadingButton = false;
        // Handle successful response
        console.log('Profile Updated successfully:', response);
        this.getProfileData();
        this._snackBar.open(response.data?.message, 'Dismiss', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['snackbar-class'],
        });
        this.router.navigateByUrl('/website/webpage', {
          state: { profileData: data },
        });
        // this.router.navigateByUrl('/dashboard/doctor')
      },
      (error) => {
        this.loadingButton = false;
        // Handle error
        console.error('Error Profile Updated:', error);
      }
    );
  }

  /*
Degree auto suggestion
*/
  adddegree(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.degrees.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
    this.degreeCtrl.setValue(null);
  }

  //remove degree
  removedegree(degrees: string): void {
    const index = this.degrees.indexOf(degrees);

    if (index >= 0) {
      this.degrees.splice(index, 1);
    }
  }

  //selected degree
  selecteddegree(event: MatAutocompleteSelectedEvent): void {
    let index = this.degrees.indexOf(event.option.viewValue);

    if (index == -1) {
      this.degrees.push(event.option.viewValue);
    }
    this.degreeInput.nativeElement.value = '';
    this.degreeCtrl.setValue(null);
    // this.degrees.push(event.option.viewValue);
    // this.degreeInput.nativeElement.value = '';
    // this.degreeCtrl.setValue(null);
  }
  private _filter1(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allDegrees.filter(
      (degree: any) => degree.toLowerCase().indexOf(filterValue) === 0
    );
  }

  public onPopupOpen(args: PopupOpenEventArgs): void {
    console.log(
      'args',
      args,
      this.datepipe.transform(args.data?.['startTime'], 'yyyy-MM-dd')
    );
    // if(args.data.Id != undefined){
    //     args.cancel = true;
    // }else
    if (args.data?.['Id'] != undefined) {
      args.cancel = true;
    } else if (args.type === 'QuickInfo') {
      args.cancel = true;
      this.scheduleObj.closeQuickInfoPopup();
      // this.appointmentDialog(this.datepipe.transform(args.data?.['startTime'], 'yyyy-MM-dd'), this.datepipe.transform(args.data?.['endTime'], 'yyyy-MM-dd'))
    }
    // else
    if (args.type === 'Editor') {
      // console.log(args.data)
      // args.cancel = true
      // this.scheduleObj.closeEditor();
      // this.appointmentDialog(this.datepipe.transform(args.data?.['startTime'], 'yyyy-MM-dd'), this.datepipe.transform(args.data?.['endTime'], 'yyyy-MM-dd'))
      // args.cancel = true;
      const elm = document.querySelector<HTMLElement>('.e-title-location-row')!;
      elm.style.display = 'none';
      const elm1 = document.querySelector<HTMLElement>(
        '.e-description-container'
      )!;
      elm1.style.display = 'none';
      const elm2 = document.querySelector<HTMLElement>('.e-dlg-header')!;
      elm2.style.display = 'none';
      const elm3 = document.querySelector<HTMLElement>(
        '.e-time-zone-container'
      )!;
      elm3.style.display = 'none';
    }
    if (args.type === 'RecurrenceAlert') {
      args.cancel = true;
      this.scheduleObj.closeEditor();
    }
    //   args.cancel = true
    //   this.scheduleObj.closeEditor();
    //   // this.appointmentDialog(this.datepipe.transform(args.data.StartTime, 'yyyy-MM-dd'))
    // }
    // else{
    // if (args.data.startTime.getTime() < new Date().getTime()) {
    //   // console.log('true')
    //   this.scheduleObj.closeQuickInfoPopup();
    // }
    // this.selectionTarget = args.data.startTime
    // this.date = this.selectionTarget;
    // this.selectionTarget = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    // console.log(this.selectionTarget);
    // this.change.emit(this.selectionTarget);
    // }
  }

  onCreated() {}
  onActionComplete(args: ActionEventArgs): void {
    console.log(args.data);
    if (args.requestType === 'eventCreated') {
      args.data?.map((x: any) => {
        console.log(x);
        this.events.push({
          Id: x.Id,
          Subject:
            this.datepipe.transform(x.StartTime, 'HH:mm') +
            ' - ' +
            this.datepipe.transform(x.EndTime, 'HH:mm'),
          StartTime: new Date(x.StartTime),
          EndTime: new Date(x.EndTime),
          RecurrenceRule: x.RecurrenceRule,
        });
      });
      // console.log('events', this.events)
      this.eventSettings = {
        dataSource: <Object[]>extend([], this.events, true),
      };
      console.log(
        'It will triggered when new event is rendered on the scheduler user interface.'
      );
    } else if (args.requestType === 'eventRemoved') {
      var scheduleId: any;
      if (args.data) {
        scheduleId = args.data;
      }
      const ScheduleData = {
        scheduleId: scheduleId[0].ScheduleId,
      };

      console.log('day Check add', ScheduleData);
      this.scheduleService.deleteSchedule(ScheduleData).subscribe(
        (response: any) => {
          console.log('Schedule deleted successfully', response);
          // this.eventSettings = {
          //   dataSource: <Object[]>extend([], [], true)
          // };
          // this.ngAfterViewInit()
        },
        (error: any) => {
          console.error('Error deleted Schedule', error);
        }
      );
    }
  }
  appointmentDialog(startDate: any, endDate: any) {
    console.log(startDate, endDate);
    var date = {
      startDate: startDate,
      endDate: endDate,
    };
    const dialogRef = this.dialog.open(AppointmentDialogComponent, {
      width: 'auto',
      data: { date: date, type: 'monthly' },
      panelClass: ['dialog-design'],
      // position:{bottom: '0px'},
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  onFileSelected(e: any): void {
    console.log(4);
    this.selectedFile = event;
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    var pattern = /image-*/;
    var reader = new FileReader();

    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  onFileSelected1(e: any): void {
    console.log(4);
    this.selectedFile = event;
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    var pattern = /image-*/;
    var reader = new FileReader();

    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }

    reader.onload = this._handleReaderLoaded1.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    console.log('_handleReaderLoaded', e);
    var reader = e.target;
    this.imageSrc = reader.result;
    console.log(this.imageSrc);
  }

  _handleReaderLoaded1(e: any) {
    console.log('_handleReaderLoaded', e);
    var reader = e.target;
    this.userImage = reader.result;
    console.log(this.imageSrc);
  }

  durationChange(event: any) {
    console.log(event.value);
    this.duration = event.value;
    // this.addSchedule()
  }
  dayChange(event: any) {
    console.log(event.value);
    this.day = event.value;
    this.loaderService.hide();
  }

  checkSchedule() {
    const userInfo = UtilityService.getUser();
    const param = {
      doctorId: userInfo.id,
      date: this.day.date,
    };
    this.appointmentService.scheduleAppointment(param).subscribe((response) => {
      this.loaderService.hide();
      console.log(response.body.data);
    });
  }

  ratioChange(event: any) {
    console.log(event.value);
    this.ratio = event.value;
    // this.addSchedule()
  }

  timeSlotsBreakEnd: any;
  timeChange(event: any, type: any) {
    console.log(event.value, type);
    if (type === 'start') {
      this.startTime = event.value;
      this.endTime = null;
      this.breakstartTime = null;
      this.breakendTime = null;
    } else if (type === 'end') {
      this.endTime = event.value;
    } else if (type === 'breakstart') {
      this.breakstartTime = event.value;
    } else if (type === 'breakend') {
      this.breakendTime = event.value;
    }
    this.timeSlotsBreak = this.filterTimeSlots(
      this.times,
      moment(this.startTime, ['h:mm A']).format('HH:mm'),
      moment(this.endTime, ['h:mm A']).format('HH:mm')
    );
    this.timeSlotsBreakEnd = this.filterTimeSlots(
      this.times,
      moment(this.breakstartTime, ['h:mm A']).format('HH:mm'),
      moment(this.endTime, ['h:mm A']).format('HH:mm')
    );
    console.log(
      this.startTime,
      this.endTime,
      this.breakstartTime,
      this.breakendTime,
      this.timeSlotsBreak,
      this.timeSlotsBreakEnd,
      '--------------------------------------'
    );
  }

  addClinicTime() {
    this.loadingButton1 = true;
    this.timeIntervals = [];
    this.events = [];

    const userInfo = UtilityService.getUser();
    // console.log('day Check', this.day.viewValue, this.dayName, this.dayName.find((x: any) => x.dayName == this.day.viewValue))
    // const alreadydayCheck = this.dayName.find((x: any) => x.dayName == this.day.viewValue)
    const alreadydayCheck = this.day.disable;
    console.log('day Check', alreadydayCheck);
    if (alreadydayCheck == undefined) {
      const ScheduleData = [
        {
          organizationBranchId: this.organizationBranchId,
          doctorId: userInfo.id,
          workDay: this.day.viewValue,
          startTime: this.startTime,
          endTime: this.endTime,
          breakTimeStart: this.breakstartTime,
          breakTimeEnd: this.breakendTime,
          appointmentCount: this.ratio,
          averageTime: this.duration,
        },
      ];

      console.log('day Check add', ScheduleData);
      this.scheduleService.addSchedule(ScheduleData).subscribe(
        (response) => {
          this.loadingButton1 = false;
          console.log('Schedule added successfully', response);
          // this.getSchedule()
          // this.getAnalysisCount();
          this.index = 0;
          this.ngAfterViewInit();
          this.clinicTimeFormGroup.reset();
          userInfo.isScheduleAdded = true;
          localStorage.setItem('user_info', JSON.stringify(userInfo));
        },
        (error) => {
          this.loadingButton1 = false;
          console.error('Error adding Schedule', error);
          // Handle error as needed
        }
      );
    } else {
      const ScheduleData = [
        {
          // scheduleId: alreadydayCheck.scheduleId,
          workDay: this.day.viewValue,
          startTime: this.startTime,
          endTime: this.endTime,
          breakTimeStart: this.breakstartTime,
          breakTimeEnd: this.breakendTime,
          appointmentCount: this.ratio,
          averageTime: this.duration,
          date: this.day.date,
        },
      ];
      localStorage.setItem('updateSchedule', JSON.stringify(ScheduleData));
      console.log('day Check update', ScheduleData);
      localStorage.removeItem('isCheckedNameReschedule');
      this.router.navigate(['/patient/update-schedule']);
      this.loadingButton1 = false;
      // const dialogRef = this.dialog.open(CancelAppointmentComponent, {
      //   width: '100vw',
      //   height: '80%',
      //   data: { date: ScheduleData },
      //   panelClass: ["dialog-design"],
      //   // position:{bottom: '0px'},
      // });
      // dialogRef.afterClosed().subscribe(result => {
      //   console.log(this.events)
      // })
      // this.scheduleService.updateSchedule(ScheduleData).subscribe(
      //   (response) => {
      //     console.log('Clinic Time updated successfully', response);
      //     // this.getSchedule()
      //     // this.getAnalysisCount();
      //     // this.ngAfterViewInit()
      //   },
      //   (error) => {
      //     console.error('Error update Clinic Time', error);
      //     // Handle error as needed
      //   }
      // );
    }
  }

  ngAfterViewInit(): void {
    this.timeSlotsBreak = [];
    this.timeSlotsBreakEnd = [];

    this.generateDatesArray(new Date());

    if (this.index >= 1) {
      this.loaderService.hide();
    }
    this.secondBatchCalled = false;
    this.selectedDate = new Date();
    this.callApi(this.datesofarray[this.index]);
  }

  index = 0;
  secondBatchCalled = false;
  generateDatesArray(startDate: Date) {
    let currentDate = moment(startDate);
    let weekEnd = currentDate.clone().endOf('week').subtract(0, 'day');

    // Ensure that the weekEnd does not exceed the next Saturday

    this.datesofarray = [];
    this.datesArraywithday = [];

    let date = currentDate.clone();
    while (date <= weekEnd) {
      this.datesofarray.push(date.format('YYYY-MM-DD'));
      this.datesArraywithday.push({
        date: date.format('YYYY-MM-DD'),
        day: date.format('dddd'),
      });
      date = date.add(1, 'days');
    }
  }

  async callApi(value: string) {
    if (!value) return;
    const userInfo = UtilityService.getUser();
    try {
      const response = await this.getSchedule(value);
      console.log('Response:', this.index, this.datesofarray.length - 1);

      if (this.index === this.datesofarray.length - 1) {
        if (this.secondBatchCalled && this.events.length !== 0) {
          this.selectedDate = new Date(
            this.selectedDate.setDate(this.selectedDate.getDate() + 7)
          );
        }
        if (this.events.length === 0 && !this.secondBatchCalled) {
          // If no events, generate dates for the next 7 days and call the API again
          let lastDate = new Date(
            this.datesofarray[this.datesofarray.length - 1]
          );
          lastDate.setDate(lastDate.getDate() + 1);
          this.generateDatesArray(lastDate);
          this.index = 0; // Reset index for the new set of dates
          this.secondBatchCalled = true;
          this.callApi(this.datesofarray[this.index]);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error appropriately
    }
  }

  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    console.log('Teste', event.value);
    this.currentDate = event.value;
  }

  getSchedule(date: any) {
    const userInfo = UtilityService.getUser();
    this.scheduleService
      .viewSchedule(date, userInfo.id, '', this.organizationBranchId)
      .subscribe((result: any) => {
        if (result.data.length > 0) {
          const scheduleId = result.data[0].scheduleId;
          const excludedStartTime = moment(result.data[0].breakTimeStart, [
            'h:mm A',
          ]).format('HH:mm');
          const excludedEndTime = moment(result.data[0].breakTimeEnd, [
            'h:mm A',
          ]).format('HH:mm');
          var tom1startBreak, tom1endBreak;
          let tom1Break = new Date(date);
          var currentTimeSplitBreak = excludedStartTime.split(':');
          var nextimeSplit = excludedEndTime.split(':');
          tom1Break.setDate(tom1Break.getDate());
          tom1startBreak = tom1Break.setHours(
            parseInt(currentTimeSplitBreak[0])
          );
          tom1startBreak = tom1Break.setMinutes(
            parseInt(currentTimeSplitBreak[1])
          );
          tom1endBreak = tom1Break.setHours(parseInt(nextimeSplit[0]));
          tom1endBreak = tom1Break.setMinutes(parseInt(nextimeSplit[1]));
          this.timeIntervals.push({
            Time1: new Date(tom1startBreak),
            Time2: new Date(tom1endBreak),
            RecurrenceRule: ``,
            IsBlock: true,
            scheduleId: scheduleId,
            IsReadonly: false,
          });
          // console.log(result.data)
          for (let index = 0; index < result.data.length; index++) {
            var timebreakadded = 0;
            const element = result.data[index];
            const startTime = moment(element.startTime, ['h:mm A']).format(
              'HH:mm'
            );
            const endTime = moment(element.endTime, ['h:mm A']).format('HH:mm');
            const interval = element.averageTime;
            const day = this.days.find(
              (id: any) => id.viewValue == element.workDay
            ).value;
            const excludedStartTime = moment(element.breakTimeStart, [
              'h:mm A',
            ]).format('HH:mm');
            const excludedEndTime = moment(element.breakTimeEnd, [
              'h:mm A',
            ]).format('HH:mm');
            const scheduleId = element.scheduleId;
            this.timeScale = { enable: true, interval: 30, slotCount: 2 };
            this.timeScale1 = { enable: true, interval: 60, slotCount: 4 };
            let currentTime = startTime;
            // console.log(element, 'elementDay')
            if (
              element.workDay ===
              this.days.find((id: any) => id.viewValue == element.workDay)
                .viewValue
            ) {
              this.dayName.push({
                dayName: this.days.find(
                  (id: any) => id.viewValue == element.workDay
                ).viewValue,
              });

              this.dayName = [...this.dayName].filter((obj, pos, arr) => {
                return (
                  arr
                    .map((mapObj) => mapObj['dayName'])
                    .indexOf(obj['dayName']) === pos
                );
              });
              this.days.forEach((value1: any) => {
                // Check if the value exists in the second array
                this.dayName.find((value2: any) => {
                  // console.log('dayName',value2.dayName, value1.viewValue,value2.dayName == value1.viewValue)
                  if (value2.dayName == value1.viewValue) {
                    value1.disable = true;
                  }
                });
                this.datesArraywithday.find((value2: any) => {
                  // console.log('dayName',value2.dayName, value1.viewValue,value2.dayName == value1.viewValue)
                  if (value2.day == value1.viewValue) {
                    value1.date = value2.date;
                  }
                });
                // console.log('dayName', this.days)
              });
            }

            // if (element.workDay === this.days.find((id: any) => id.viewValue == element.workDay).viewValue) {
            //   this.dayName.push({ dayName: this.days.find((id: any) => id.viewValue == element.workDay).viewValue, scheduleId: element.scheduleId })
            //   console.log(result.data[0].workDay, this.days.find((id: any) => id.viewValue == element.workDay).viewValue, this.dayName, 'dayName')
            // }
            // const nextTime = this.addMinutes(currentTime, interval);
            while (currentTime < endTime) {
              const nextTime = this.addMinutes(currentTime, interval);

              if (nextTime <= endTime) {
                // console.log('element.endTime',moment(element.endTime, ["h:mm A"]).format("HH:mm"), element.endTime, excludedStartTime, excludedEndTime)

                // console.log('element.endTime',startTime,endTime,excludedStartTime,excludedEndTime)
                if (
                  (currentTime >= excludedStartTime &&
                    currentTime < excludedEndTime) ||
                  (nextTime > excludedStartTime &&
                    nextTime <= excludedEndTime) ||
                  (currentTime < excludedStartTime &&
                    nextTime > excludedEndTime)
                ) {
                  // console.log(nextTime)
                  var tom1startBreak, tom1endBreak;
                  let tom1Break = new Date(date);
                  var currentTimeSplitBreak = currentTime.split(':');
                  var nextimeSplit = nextTime.split(':');
                  tom1Break.setDate(tom1Break.getDate());
                  tom1startBreak = tom1Break.setHours(
                    parseInt(currentTimeSplitBreak[0])
                  );
                  tom1startBreak = tom1Break.setMinutes(
                    parseInt(currentTimeSplitBreak[1])
                  );
                  tom1endBreak = tom1Break.setHours(parseInt(nextimeSplit[0]));
                  tom1endBreak = tom1Break.setMinutes(
                    parseInt(nextimeSplit[1])
                  );
                  this.timeIntervals.push({
                    Time1: new Date(tom1startBreak),
                    Time2: new Date(tom1endBreak),
                    RecurrenceRule: ``,
                    IsBlock: true,
                    scheduleId: scheduleId,
                    IsReadonly: false,
                  });
                  // currentTime = nextTime;

                  // console.log('element.endTime',moment(element.endTime, ["h:mm A"]).format("HH:mm"), element.endTime, { Time1: new Date(tom1startBreak), Time2: new Date(tom1endBreak), RecurrenceRule: ``, IsBlock: true,  scheduleId: scheduleId, IsReadonly: false})
                } else {
                  // console.log('element.endTime',moment(element.endTime, ["h:mm A"]).format("HH:mm"), element.endTime)
                  var tom1start, tom1end;
                  let tom1 = new Date(date);
                  var currentTimeSplit = currentTime.split(':');
                  var nextimeSplit = nextTime.split(':');
                  tom1.setDate(tom1.getDate());
                  tom1start = tom1.setHours(parseInt(currentTimeSplit[0]));
                  tom1start = tom1.setMinutes(parseInt(currentTimeSplit[1]));
                  tom1end = tom1.setHours(parseInt(nextimeSplit[0]));
                  tom1end = tom1.setMinutes(parseInt(nextimeSplit[1]));

                  if (element.isBooked) {
                    this.timeIntervals.push({
                      Time1: new Date(tom1start),
                      Time2: new Date(tom1end),
                      RecurrenceRule: ``,
                      IsBlock: false,
                      scheduleId: scheduleId,
                      IsReadonly: true,
                    });
                  } else {
                    this.timeIntervals.push({
                      Time1: new Date(tom1start),
                      Time2: new Date(tom1end),
                      RecurrenceRule: ``,
                      IsBlock: false,
                      scheduleId: scheduleId,
                      IsReadonly: false,
                    });
                  }
                  // this.timeIntervals.push({ Time1: new Date(tom1start), Time2: new Date(tom1end), RecurrenceRule: `FREQ=WEEKLY;BYDAY=${day};INTERVAL=1;COUNT=1;`, IsBlock: false,  scheduleId: scheduleId });
                  // currentTime = nextTime;
                }
                currentTime = nextTime;
              } else {
                console.log('break');
                break;
              }
            }
            // var tom1start, tom1end, tom1startbreak,tom1endbreak
            // let tom1 = new Date(this.startDate)
            // let tom1Break = new Date(this.startDate)
            // var currentTimeSplit = currentTime.split(':')
            // var nextimeSplit = endTime.split(':')
            // var currentBreakTimeSplit = excludedStartTime.split(':')
            // var nextBreakimeSplit = excludedEndTime.split(':')
            // tom1.setDate(tom1.getDate())
            // tom1Break.setDate(tom1Break.getDate())
            // tom1start = tom1.setHours(parseInt(currentTimeSplit[0]))
            // tom1start = tom1.setMinutes(parseInt(currentTimeSplit[1]))
            // tom1startbreak = tom1Break.setHours(parseInt(currentBreakTimeSplit[0]))
            // tom1startbreak = tom1Break.setMinutes(parseInt(currentBreakTimeSplit[1]))
            // tom1end = tom1.setHours(parseInt(nextimeSplit[0]))
            // tom1end = tom1.setMinutes(parseInt(nextimeSplit[1]))
            // tom1endbreak = tom1Break.setHours(parseInt(nextBreakimeSplit[0]))
            // tom1endbreak = tom1Break.setMinutes(parseInt(nextBreakimeSplit[1]))
            // this.timeIntervals.push({ Time1: new Date(tom1start), Time2: new Date(tom1end), RecurrenceRule: `FREQ=WEEKLY;BYDAY=${day};INTERVAL=1;COUNT=1;`, IsBlock: false });
            // this.timeIntervals.push({ Time1: new Date(tom1startbreak), Time2: new Date(tom1endbreak), RecurrenceRule: `FREQ=WEEKLY;BYDAY=${day};INTERVAL=1;COUNT=1;`, IsBlock: true });
          }

          // console.log('IsBlock',[...this.timeIntervals].filter((obj, pos, arr) => {
          //   return arr.map(mapObj => mapObj["Time1"]).indexOf(obj["Time1"]) === pos;
          // }))

          // // let yeasterday2 = yesterday2.toString().split(" ")
          this.timeIntervals?.map((x: any, index: any) => {
            // console.log(x)
            if (x.IsBlock == true) {
              var subject = 'Break-Time';
            } else {
              var subject = `${
                moment(x.Time1).format('h:mm A') +
                ' - ' +
                moment(x.Time2).format('h:mm A')
              }`;
            }
            if (x.IsReadonly) {
              var CategoryColor1 = '808080';
            } else {
              var CategoryColor1 = '';
            }
            this.events.push({
              Id: index + 1,
              // Subject: x.subject,
              Subject: subject,
              StartTime: new Date(x.Time1),
              EndTime: new Date(x.Time2),
              RecurrenceRule: x.RecurrenceRule,
              IsBlock: x.IsBlock,
              ScheduleId: x.scheduleId,
              IsReadonly: x.IsReadonly,
              CategoryColor: CategoryColor1,
            });
          });

          this.events = [...this.events].filter((obj, pos, arr) => {
            return arr.map((mapObj) => mapObj['Id']).indexOf(obj['Id']) === pos;
          });
          // console.log('events', this.events)
          this.eventSettings = {
            dataSource: <Object[]>extend([], this.events, true),
          };

          this.index++;
          if (this.index < this.datesofarray.length) {
            // console.log('Response:', this.index);
            this.callApi(this.datesofarray[this.index]);
            timebreakadded = 0;
            if (this.index >= 1) {
              this.loaderService.hide();
            }
          } else {
            this.index = 0;
          }
          return this.events;
        } else {
          this.index++;
          if (this.index < this.datesofarray.length) {
            // console.log('Response:', this.index);
            this.callApi(this.datesofarray[this.index]);
            if (this.index >= 1) {
              this.loaderService.hide();
            }
          }
        }
      }),
      (error: any) => {
        console.error('Error adding Schedule', error);
        // Handle error as needed

        this.index++;
        if (this.index < this.datesofarray.length) {
          // console.log('Response:', this.index);
          this.callApi(this.datesofarray[this.index]);
          if (this.index >= 1) {
            this.loaderService.hide();
          }
        } else {
          this.index = 0;
        }
      };
  }

  applyCategoryColor(args: EventRenderedArgs): void {
    let categoryColor: string = args.data['CategoryColor'] as string;
    if (!args.element || !categoryColor) {
      return;
    }
    // console.log("categoryColor",categoryColor)
    // console.log(this.scheduleObj.currentView)
    if (this.scheduleObj?.currentView === 'Agenda') {
      (args.element.firstChild as HTMLElement).style.borderLeftColor =
        categoryColor;
    } else {
      // args.element.style.backgroundColor = categoryColor;
      args.element.style.color = 'white';

      // Change background color
      args.element.style.backgroundColor = '#808080';

      // Apply strikethrough effect
      args.element.style.textDecoration = 'line-through';
    }
  }

  addSchedule() {
    this.eventSettings = {
      dataSource: <Object[]>extend([], [], true),
    };
    this.addClinicTime();
    // const startTime = this.startTime;
    // const endTime = this.endTime;
    // const interval = this.duration;
    // console.log(startTime, endTime, interval)

    // this.timeScale = { enable: true, interval: this.duration, slotCount: 1 };
    // let currentTime = startTime;
    // const excludedStartTime = this.breakstartTime;
    // const excludedEndTime = this.breakendTime;
    // console.log(excludedStartTime, excludedEndTime)
    // while (currentTime < endTime) {
    //   const nextTime = this.addMinutes(currentTime, interval);

    //   if (nextTime <= endTime) {
    //     if (currentTime >= excludedStartTime && currentTime < excludedEndTime ||
    //       nextTime > excludedStartTime && nextTime <= excludedEndTime ||
    //       currentTime < excludedStartTime && nextTime > excludedEndTime) {
    //       console.log(nextTime)
    //       var tom1start, tom1end
    //       let tom1 = new Date(this.startDate)
    //       var currentTimeSplit = currentTime.split(':')
    //       var nextimeSplit = nextTime.split(':')
    //       tom1.setDate(tom1.getDate())
    //       tom1start = tom1.setHours(parseInt(currentTimeSplit[0]))
    //       tom1start = tom1.setMinutes(parseInt(currentTimeSplit[1]))
    //       tom1end = tom1.setHours(parseInt(nextimeSplit[0]))
    //       tom1end = tom1.setMinutes(parseInt(nextimeSplit[1]))
    //       this.timeIntervals.push({ Time1: new Date(tom1start), Time2: new Date(tom1end), RecurrenceRule: `FREQ=WEEKLY;BYDAY=${this.day.value};INTERVAL=1;COUNT=1;`, IsBlock: true });
    //       // currentTime = nextTime;

    //       console.log(nextTime)
    //     } else {
    //       var tom1start, tom1end
    //       let tom1 = new Date(this.startDate)
    //       var currentTimeSplit = currentTime.split(':')
    //       var nextimeSplit = nextTime.split(':')
    //       tom1.setDate(tom1.getDate())
    //       tom1start = tom1.setHours(parseInt(currentTimeSplit[0]))
    //       tom1start = tom1.setMinutes(parseInt(currentTimeSplit[1]))
    //       tom1end = tom1.setHours(parseInt(nextimeSplit[0]))
    //       tom1end = tom1.setMinutes(parseInt(nextimeSplit[1]))
    //       this.timeIntervals.push({ Time1: new Date(tom1start), Time2: new Date(tom1end), RecurrenceRule: `FREQ=WEEKLY;BYDAY=${this.day.value};INTERVAL=1;COUNT=1;`, IsBlock: false });
    //       // currentTime = nextTime;
    //     }
    //     currentTime = nextTime;
    //   } else {
    //     console.log('break')
    //     break;
    //   }
    // }

    // // let yeasterday2 = yesterday2.toString().split(" ")
    // this.timeIntervals?.map((x: any, index: any) => {
    //   console.log(x)
    //   if (x.IsBlock == true) {
    //     var subject = 'Break-Time'
    //   } else {
    //     var subject = `${moment(x.Time1).format('h:mm A') + ' - ' + moment(x.Time2).format('h:mm A')}`
    //   }
    //   this.events.push({
    //     Id: index + 1,
    //     // Subject: x.subject,
    //     Subject: subject,
    //     StartTime: new Date(x.Time1),
    //     EndTime: new Date(x.Time2),
    //     RecurrenceRule: x.RecurrenceRule,
    //     IsBlock: x.IsBlock
    //   })
    // });
    // this.events = [...this.events].filter((obj, pos, arr) => {
    //   return arr.map(mapObj => mapObj["Id"]).indexOf(obj["Id"]) === pos;
    // })
    // console.log('events', this.events)
    // this.eventSettings = {
    //   dataSource: <Object[]>extend([], this.events, true)
    // };
    // setTimeout(() => {
    //   this.timeIntervals = []
    //   this.events = []
    //   console.log(this.events)
    // }, 3000);
  }

  addMinutes(time: string, minutes: number): string {
    const [hours, mins] = time.split(':').map(Number);
    const totalMinutes = hours * 60 + mins + minutes;
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;
    return `${this.padZero(newHours)}:${this.padZero(newMinutes)}`;
  }

  padZero(value: any) {
    return value < 10 ? `0${value}` : `${value}`;
  }

  filterTimeSlots(timeSlots: any, startTime: any, endTime: any) {
    console.log('filteredTimeSlots', startTime, endTime);
    const filteredTimeSlots = timeSlots.filter((timeSlot: any) => {
      const slotStartTime = timeSlots.find(
        (e: any) => moment(e.value, ['h:mm A']).format('HH:mm') === startTime
      );
      const slotEndTime = timeSlots.find(
        (e: any) => moment(e.value, ['h:mm A']).format('HH:mm') === endTime
      );

      const slotStartTim1 = moment(slotStartTime.value, ['h:mm A']).format(
        'HH:mm'
      );
      const slotEndTim2 = moment(slotEndTime.value, ['h:mm A']).format('HH:mm');
      console.log('filteredTimeSlots', slotStartTim1, slotEndTim2);
      // slotEndTime = moment(slotEndTime, ["h:mm A"]).format("HH:mm")
      console.log(
        'filteredTimeSlots',
        timeSlot.value,
        moment(timeSlot.value, ['h:mm A']).format('HH:mm') >= startTime &&
          moment(timeSlot.value, ['h:mm A']).format('HH:mm') <= endTime
      );
      // Check if the current time slot falls outside the selected range
      // console.log('filteredTimeSlots', timeSlots, slotEndTime <= startTime || slotStartTime >= endTime)
      const isBetween =
        moment(timeSlot.value, ['h:mm A']).format('HH:mm') >= startTime &&
        moment(timeSlot.value, ['h:mm A']).format('HH:mm') <= endTime;

      if (isBetween) {
        timeSlot.disabled = false; // Prevent selection
      } else {
        timeSlot.disabled = true;
      }
      return (
        moment(slotEndTim2, ['HH:mm']).format('h:mm A') <=
          moment(startTime, ['HH:mm']).format('h:mm A') ||
        moment(slotStartTim1, ['HH:mm']).format('h:mm A') >=
          moment(endTime, ['HH:mm']).format('h:mm A')
      );
    });
    console.log('filteredTimeSlots', timeSlots);
    return timeSlots;
  }
}
