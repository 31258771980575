<div class="margin-div">
  <h4 id="underline-gap" class="m-0 pb-2 font-size-19 capitalize text-middle">Select Departments</h4>
</div>
<div class="class-padding" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  <div fxLayout="column">
      <div fxLayoutAlign="center" fxLayout="row" fxLayoutGap="10px">
        <span>
          <mat-checkbox class="example-margin" [checked]="task().completed" [indeterminate]="partiallyComplete()"
            (change)="update($event.checked)">
            {{task().name}}
          </mat-checkbox>
        </span>
        <span>
          <ul>
            @for (subtask of task().subtasks; track subtask; let i = $index) {
            <li>
              <mat-checkbox [checked]="subtask.completed" (change)="update($event.checked, i)">
                {{subtask.name}}
              </mat-checkbox>
            </li>
            }
          </ul>
        </span>
        <div style="margin-bottom: 5px; display: flex; justify-content: right;">
          <button mat-raised-button color="primary" class="button-size">Done</button>
        </div>
      </div>
      
      
  </div>
</div>