import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  FormControl,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { OrganizationService } from 'src/app/shared/services/org-onboarding-service/organization.service';
import { OrganizationBranchModel } from 'src/app/models/organization-branch-model';
import { MatSelectModule } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-hospital-info',
  templateUrl: './hospital-info.component.html',
  styleUrl: './hospital-info.component.scss',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    MatCardModule,
    MatInputModule,
    MatGridListModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIcon,
  ],
  providers: [
    provideNativeDateAdapter(),
    // {
    //   provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    //   useValue: { subscriptSizing: 'dynamic' }
    // }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HospitalInfoComponent implements OnInit {
  hospitalInfoFormGroup: FormGroup;
  public hide: boolean = false;
  public hide1: boolean = false;
  maxDate: Date = new Date();
  picker: any;
  public responseDateOfBirth: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService
  ) {}

  ngOnInit(): void {
    this.hospitalInfoFormGroup = this.fb.group({
      organizationBranchName: ['', [Validators.required]],
      address1: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      whatsappNumber: [''],
      faxNumber: ['', [Validators.required]],
      facilityId: ['', [Validators.required]],
      licenseNumber: ['', [Validators.required]],
      accreditationNumber: ['', [Validators.required]],
      isRegistrationCompleted: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      aadharCard: ['', [Validators.required]],
      birthDate: [''],
      gender: ['', [Validators.required]],
      email: ['', [Validators.required]],
      cellPhone: [''],
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        this.whitespaceValidator(),
      ]),
      confirmPassword: ['', [Validators.required]],
    });
  }

  onKeyPress(e: any) {
    const allowedKeys = [8, 46];

    if (
      e.which !== 0 &&
      (e.which < 48 || e.which > 57) &&
      !allowedKeys.includes(e.which)
    ) {
      e.preventDefault();
    }
  }

  whitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.trim() === '') {
        return { whitespace: true };
      }
      return null;
    };
  }

  get onSave() {
    console.log('Onsave');
    const user_info = JSON.parse(localStorage.getItem('user_info') || '{}');
    const userId = user_info ? user_info.id : '';
    let organizationBranchModel = new OrganizationBranchModel();
    organizationBranchModel.dateOfBirth =
      this.hospitalInfoFormGroup.value.birthDate;
    organizationBranchModel.gender = this.hospitalInfoFormGroup.value.gender;
    organizationBranchModel.phoneNumber =
      this.hospitalInfoFormGroup.value.phoneNumber;
    organizationBranchModel.whatsappNumber =
      this.hospitalInfoFormGroup.value.whatsappNumber;
    organizationBranchModel.faxNumber =
      this.hospitalInfoFormGroup.value.faxNumber;
    organizationBranchModel.facilityId =
      this.hospitalInfoFormGroup.value.facilityId;
    organizationBranchModel.licenseNumber =
      this.hospitalInfoFormGroup.value.licenseNumber;
    organizationBranchModel.accreditationNumber =
      this.hospitalInfoFormGroup.value.accreditationNumber;
    organizationBranchModel.firstName =
      this.hospitalInfoFormGroup.value.firstName;
    organizationBranchModel.lastName =
      this.hospitalInfoFormGroup.value.lastName;
    organizationBranchModel.aadharCard =
      this.hospitalInfoFormGroup.value.aadharCard;
    organizationBranchModel.dateOfBirth =
      this.hospitalInfoFormGroup.value.birthDate;
    organizationBranchModel.email = this.hospitalInfoFormGroup.value.email;
    organizationBranchModel.cellPhone =
      this.hospitalInfoFormGroup.value.cellPhone;
    organizationBranchModel.password =
      this.hospitalInfoFormGroup.value.password;
    organizationBranchModel.userId = userId;
    organizationBranchModel.organizationBranchName =
      this.hospitalInfoFormGroup.value.organizationBranchName;
    organizationBranchModel.address1 =
      this.hospitalInfoFormGroup.value.address1;
    organizationBranchModel.address2 =
      this.hospitalInfoFormGroup.value.address2;
    organizationBranchModel.city = this.hospitalInfoFormGroup.value.city;
    organizationBranchModel.state = this.hospitalInfoFormGroup.value.state;
    organizationBranchModel.country = this.hospitalInfoFormGroup.value.country;
    organizationBranchModel.zip = this.hospitalInfoFormGroup.value.zip;
    console.log(organizationBranchModel);
    return organizationBranchModel;
  }

  birthDate: FormControl = new FormControl({
    value: new Date(this.responseDateOfBirth),
    disabled: false,
  });
}
