<div class="row">
    <div class="col-lg-6 col-md-12">
        <mat-card
    class="trinta-card issues-summary-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                Unversity Data
            </h5>
        </mat-card-title>
       
    </mat-card-header>
    <mat-card-content>
        <ul class="list p-0 m-0 list-unstyled d-sm-flex justify-content-end">
            <li class="position-relative">
                <div class="dot"></div>
                <span class="d-block text-body">
                    Patients
                </span>
            </li>
            <li class="position-relative">
                <div class="dot closed"></div>
                <span class="d-block text-body">
                    Students
                </span>
            </li>
        </ul>
        <div class="chart">
            <apx-chart
                [series]="chartOptions.series!"
                [chart]="chartOptions.chart!"
                [colors]="chartOptions.colors!"
                [plotOptions]="chartOptions.plotOptions!"
                [grid]="chartOptions.grid!"
                [dataLabels]="chartOptions.dataLabels!"
                [xaxis]="chartOptions.xaxis!"
                [yaxis]="chartOptions.yaxis!"
                [legend]="chartOptions.legend!"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>
    </div>
    <div class="col-lg-6 col-md-12">
        <mat-card class="trinta-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        Patient Ratio
                    </h5>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <apx-chart
                    [series]="donutChartOptions.series!"
                    [chart]="donutChartOptions.chart!"
                    [legend]="donutChartOptions.legend!"
                    [labels]="donutChartOptions.labels!"
                    [tooltip]="donutChartOptions.tooltip!"
                    [dataLabels]="donutChartOptions.dataLabels!"
                    [stroke]="donutChartOptions.stroke!"
                    [colors]="donutChartOptions.colors!"
                    [responsive]="donutChartOptions.responsive!"
                ></apx-chart>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-12 col-md-12">
        <mat-card
    class="trinta-card to-do-list-card mb-25 bg-white border-none d-block"
>
    <mat-card-header>
        <mat-card-title>
            <h5 class="mt-0 mb-0">
                University Table
            </h5>
        </mat-card-title>
        
    </mat-card-header>
    <mat-card-content>
        <form class="search-box position-relative">
            <input type="text" class="d-block w-100 border-none" placeholder="Search here.." (keyup)="applyFilter($event)" #input>
            <button type="button" class="p-0 bg-transparent cursor-pointer border-none">
                <i class="ri-search-line"></i>
            </button>
        </form>
        <div class="to-do-list-table">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource">
                    <!-- Task Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef class="pl-0">
                            Hospital Name
                        </th>
                        <td mat-cell *matCellDef="let element" class="pl-0">
                            <div class="task-name position-relative">
                                <h6 class="mt-0 mb-0">
                                    {{element.name}}
                                </h6>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            Patient Ratio
                        </th>
                        <td mat-cell *matCellDef="let element">
                            @if((element.totalStudents/element.totalPatients)>3){
                                <span class="trinta-badge onHand fw-medium d-inline-block" >
                                    {{formatRatio(element.totalStudents, element.totalPatients) }}
                                </span>
                            }
                            <!-- @if(element.status.onHand){
                                <span class="trinta-badge onHand fw-medium d-inline-block" >
                                    {{element.status.onHand}}
                                </span>
                            } -->
                            @if((element.totalStudents/element.totalPatients)<=3){
                                <span class="trinta-badge urgent fw-medium d-inline-block" >
                                    {{formatRatio(element.totalStudents, element.totalPatients) }}
                                </span>
                            }
                        </td>
                    </ng-container>

                    <!-- Assigned To Column -->
                    <ng-container matColumnDef="totalStudents">
                        <th mat-header-cell *matHeaderCellDef>
                           Total Students
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.totalStudents}}
                        </td>
                    </ng-container>

                    <!-- Deadline Column -->
                    <ng-container matColumnDef="totalPatients">
                        <th mat-header-cell *matHeaderCellDef>
                            Total Patients
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.totalPatients}}
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <!-- <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="text-end pr-0"></th>
                        <td mat-cell *matCellDef="let element" class="text-end pr-0">
                            <div class="action">
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.edit}}"></i>
                                </button>
                                <button type="button" mat-button class="p-0 min-w-auto lh-1 h-auto">
                                    <i class="{{element.action.delete}}"></i>
                                </button>
                            </div>
                        </td>
                    </ng-container> -->

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
    </div>
</div>