import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, Inject, NO_ERRORS_SCHEMA, NgZone, ViewChild } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Constants } from 'src/app/constant/constant';
import { UtilityService } from 'src/app/core/services/utility.service';
import { PasswordValidation } from 'src/app/shared/password-validator';
import { NoWhitespaceDirective } from 'src/app/shared/pipes/no-whitespace.directive';
import { AppointmentService } from 'src/app/shared/services/appointment.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DiagnosisService } from 'src/app/shared/services/doctor-service/diagnosis.service';
import { WebpageService } from 'src/app/shared/services/webpage.service';
import { DialogData } from '../../patient-prescriptions/life-style-dialog/life-style-dialog.component';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteModule, NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-appointment-dialog',
    templateUrl: './appointment-dialog.component.html',
    styleUrls: ['./appointment-dialog.component.scss'],
    standalone: true,
    exportAs: 'ngx-places',
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA,
      NO_ERRORS_SCHEMA
    ],
    imports: [FlexModule,MatDatepickerModule,MatNativeDateModule, NgxGpAutocompleteModule, ExtendedModule, MatRadioGroup, MatRadioButton, MatIcon, MatButton, FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatSuffix, MatError, MatHint, MatSelect, MatOption, MatDatepickerInput, MatDatepickerToggle, MatDatepicker, NoWhitespaceDirective, MatChipListbox, MatChipOption, CdkTextareaAutosize, MatProgressSpinner]

})
export class AppointmentDialogComponent {
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;    
  options: NgxGpAutocompleteOptions = {
    componentRestrictions: { country: ['IN'] },
    types: ['geocode']
  };
  @ViewChild(MatPaginator) paginator: MatPaginator;
  AppointmentDate: string;
  local_data: any;
  AppointmentTime: any;
  patientId: any;
  AppointmentStartTime: string;
  AppointmentEndTime: string;
  AppointmentDay: string;
  dataSource: MatTableDataSource<any>;

  types: any = [
    { value: 'Follow Up', viewValue: 'Follow Up' },
    { value: 'New', viewValue: 'New' },
  ]
  typeselected: any
  AppointmentScheduleID: any;
  flag: boolean;
  loadingData: boolean;
  selectedOptionRescheule: any;
  button: boolean = true;
  AppointmentDateShow: string;
  bookAppointment: boolean = true;
  showLoginandRegister: boolean = false;
  registrationPage: boolean = false;
  forgotPasswordPage: boolean = false;
  OTPverifyPage: boolean = false;
  changePasswordPage: boolean = false;
  public returnUrl: string = '';
  titleFontSize: string;
  public hide: boolean = false;
  public hide1: boolean = false;
  UserForm: FormGroup;
  maxDate = new Date();
  whitespaceErrorMessage = 'Cannot be empty or contain only spaces.';
  errorMessage = '';
  errorMessageforalready: any;
  patientRegistration!: UntypedFormGroup;

  public gengerList: any = Constants.genderList
  public haveYouEverHead: any = Constants.haveYouEverHead;
  selectedsymptoms: any[] = [];
  HealthHistory: any[] = [];
  FatherSideHealth: any[] = [];
  MotherSideHealth: any[] = [];
  phoneNumber: any;
  public loginType: any = '';
  loginForm: FormGroup = new FormGroup({
    emailOrMobile: new FormControl('', [Validators.required, this.validateEmailOrMobile]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });
  devicetoken: any;
  user_email: any;
  forgotForm: FormGroup;
  errorMsg: any;
  verifyForm: FormGroup;
  errorMsgVerify: any;
  resendTimer: number = 30; // Initial timer value in seconds
  showResendButton: boolean = false;

  hidecon1 = true;
  hidecon = true;
  changeForm: FormGroup;
  password: any;
  c_password: any;
  param1: any;
  @ViewChild("search1") public searchElementRef1: ElementRef;
  public street_number: any;
  public route2: any;
  public locality: any;
  public sublocality: any;
  public country: any;
  public state: any;
  public city: any;
  public postalCode: any;
  public address1: any;
  apicall: boolean = false;
  loading: boolean = false;
  loading1: boolean = false;
  loading2: boolean = false;
  loading3: boolean = false;
  loading4: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AppointmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private appointmentService: AppointmentService,
    private _snackBar: MatSnackBar,
    private diagnosisService: DiagnosisService,
    private router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private webpageService: WebpageService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private ngZone: NgZone
  ) {
    this.local_data = { ...data }
    const emailregex: RegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.UserForm = this.fb.group({
      firstname: ['', [Validators.required, this.whitespaceValidator()]],
      middlename: ['', [Validators.required, this.whitespaceValidator()]],
      lastname: ['', [Validators.required, this.whitespaceValidator()]],
      gender: ['', [Validators.required]],
      birthdate: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(emailregex)]],
      phoneNumber: ['', [Validators.required, Validators.pattern(/^(?:(?!\b(\d)\1+\b)[6789]\d{9})$/)]],
      password: new FormControl('', [Validators.required, Validators.minLength(6), this.whitespaceValidator()]),
      confirmPassword: ['', [Validators.required]],
    },
      {
        validator: PasswordValidation?.MatchPassword,
      });


    this.patientRegistration = this.fb.group({

      height: ['', [Validators.required, Validators.min(0), Validators.max(300), Validators.pattern(/^(0|[1-9]\d*)(\.\d+)?$/)]],

      weight: ['', [Validators.required, Validators.min(0), Validators.max(500), Validators.pattern(/^(0|[1-9]\d*)(\.\d+)?$/)]],
      aadhar: ['', [Validators.required, Validators.pattern(/^\d{12}$/)]],
      address: ['', Validators.required],
      address2: ['', Validators.required],
      city: ['', Validators.required],
      zipcode: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      OtherIllness: [''],
      OtherIllnessMother: [''],
      OtherIllnessFather: ['']
    })
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>;
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    console.log(this.local_data)

    const userInfo = UtilityService.getUser();
    this.patientId = userInfo.id
    // this.fetchDiagnosisDetails(this.patientId);
    this.AppointmentDate = moment(this.local_data.date.StartTime).format('YYYY-MM-DD')
    this.AppointmentTime = this.local_data.date.Subject
    this.AppointmentScheduleID = this.local_data.date.ScheduleId
    this.AppointmentStartTime = moment(this.local_data.date.StartTime).format('hh:mm A')
    this.AppointmentEndTime = moment(this.local_data.date.EndTime).format('hh:mm A')
    this.AppointmentDay = moment(this.local_data.date.StartTime).format('dddd')
    this.AppointmentDateShow = moment(this.local_data.date.StartTime).format('ddd, DD MMM YYYY')
    console.log(this.AppointmentTime, this.AppointmentDate, this.AppointmentDay, this.AppointmentEndTime, this.AppointmentStartTime)
    console.log(this.AppointmentStartTime, this.AppointmentEndTime, 'AppointmentEndTime')
  }

  selectAddress(place: google.maps.places.PlaceResult | any): void {
    // Place object from API
    console.log(place);
    this.getAddressComponentByPlace(place)
    // Text from input
    console.log(this.placesRef.el.nativeElement.value);
  }

  getAddressComponentByPlace(place: any) {
    let components = place.address_components;
    let city = null;
    let postalCode = null;
    let route2 = null;
    let route3 = null;
    let locality = null;
    let locality1 = null;
    let locality2 = null;
    let locality3 = null;
    var locality_1 = '';
    var locality_2 = '';
    var locality_3 = '';
    var street_number_1 = '';
    var router2_1 = '';
    var router3_1 = '';
    let street_number = null;
    let address1 = null;
    let state = null
    let country = null

    for (var i = 0, component; component = components[i]; i++) {

      if (component.types[0] == 'premise' || component.types[0] == "street_number") {
        street_number = component['long_name'];
        if (street_number != null || street_number != undefined) {
          street_number_1 = street_number + ',';
        }
        // var street_number_1 = street_number != null ? street_number : '' 
      }
      if (component.types[0] == 'route' || component.types[0] == "neighborhood") {
        route2 = component['long_name'];
        if (route2 != null || route2 != undefined) {
          router2_1 = route2 + '';
        }
      }
      if (component.types[0] == "landmark") {
        route3 = component['long_name'];
        if (route3 != null || route3 != undefined) {
          router3_1 = route3 + '';
        }
      }
      address1 = street_number_1 + ' ' + router2_1 + ' ' + router3_1;
      // console.log( component.types[ 0 ] == "sublocality_level_1" , component.types[ 0 ] == "sublocality_level_2", component.types[ 0 ] == "sublocality_level_3"  )
      if (component.types[0] == "sublocality_level_1") {
        locality1 = component['long_name'];
        if (locality1 != null || locality1 != undefined) {
          locality_1 = locality1 + ', ';
        } else {
          locality_1 = '';
        }
      }
      if (component.types[0] == "sublocality_level_2") {
        locality2 = component['long_name'];
        if (locality2 != null || locality2 != undefined) {
          locality_2 = locality2 + ', ';
        } else {
          locality_2 = '';
        }
        //  locality_2 = locality2 != null ? locality2+',' : '';
      }
      if (component.types[0] == "sublocality_level_3") {
        locality3 = component['long_name'];
        if (locality3 != null || locality3 != undefined) {
          locality_3 = locality3 + '';
        } else {
          locality_3 = '';
        }
        //  locality_3 = locality3 != null ? locality3+',' : '';
      }
      locality = locality_1 + locality_2 + locality_3;
      if (component.types[0] == "locality" || component.types[0] == 'administrative_area_level_3') {
        city = component['long_name'];
        if (city != null || city != undefined) {
          city = city;
        } else {
          city = '';
        }
      }
      if (component.types[0] == 'administrative_area_level_1') {
        state = component['long_name'];
      }
      if (component.types[0] == "country") {
        country = component['long_name'];
      }
      if (component.types[0] == 'postal_code') {
        postalCode = component['long_name'];
      }
    }

    this.route2 = route2;
    this.street_number = street_number;
    this.locality = locality;
    this.city = city != null ? city : '';
    this.state = state != null ? state : '';
    this.country = country != null ? country : '';
    this.postalCode = postalCode != null ? postalCode : '';
    this.address1 = address1;
    const payload = {
      address: this.address1,
      address2: this.locality,
      city: this.city,
      state: this.state,
      country: this.country,
      zipcode: this.postalCode,
    };
    this.patientRegistration.patchValue(payload);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.UserForm.controls;
  }
  whitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.trim() === '') {
        return { 'whitespace': true };
      }
      return null;
    };
  }
  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }
  typeChange(event: any) {
    this.typeselected = event.value
    // console.log(this.typeselected)
    if (this.typeselected.value == "Follow Up") {
      this.flag = true
      this.fetchDiagnosisDetails(this.patientId);
    } if (this.typeselected.value == "New") {
      this.flag = false
      this.dataSource = new MatTableDataSource<any>([]);
      this.button = false
    }

    console.log(event)
  }
  onCancelClick(route: any) {
    if(route == 'close'){
      this.dialogRef.close();
    }else if(route == 'schedule'){
      this.dialogRef.close();
    }else if(route == 'login'){
      this.schedule()
    }else if(route == 'forgot'){
      this.forgotPassword()
    }
  }
  schedule() {
    this.showLoginandRegister = true
    this.bookAppointment = false
    this.registrationPage = false
    this.forgotPasswordPage = false
    this.OTPverifyPage = false
    this.changePasswordPage = false
  }

  register() {
    this.registrationPage = true
    this.showLoginandRegister = false
    this.bookAppointment = false
    this.forgotPasswordPage = false
    this.OTPverifyPage = false
    this.changePasswordPage = false
  }
  forgotPassword() {
    this.forgotForm = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$'),
      ]),
    });
    this.forgotPasswordPage = true
    this.registrationPage = false
    this.showLoginandRegister = false
    this.bookAppointment = false
    this.OTPverifyPage = false
    this.changePasswordPage = false
  }
  OTPVerifyPage() {
    this.user_email = localStorage.getItem('email')
    this.verifyForm = this.fb.group({
      otp: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{6}$'),
      ]),
    });
    this.startResendTimer();
    this.forgotPasswordPage = false
    this.registrationPage = false
    this.showLoginandRegister = false
    this.bookAppointment = false
    this.OTPverifyPage = true
    this.changePasswordPage = false
  }
  ChangePasswordPage() {
    this.changeForm = this.fb.group({
      password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[a-zA-Z0-9\d$@$!%*?&].{7,}')

      ]),
      c_password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[a-zA-Z0-9\d$@$!%*?&].{7,}'),
        this.checkPasswords()
      ]),
    });
    this.password = this.changeForm.get('password');
    this.c_password = this.changeForm.get('c_password');
    this.forgotPasswordPage = false
    this.registrationPage = false
    this.showLoginandRegister = false
    this.bookAppointment = false
    this.OTPverifyPage = false
    this.changePasswordPage = true
  }
  // login(){
  //   this.dialogRef.close('Close');
  //   const appointmentObj = {
  //     DoctorId : '2e0b24de-d6a7-44d9-8ec4-f90e667147b1',
  //     AppointmentTime: this.AppointmentTime,
  //     PatientId : this.patientId,
  //     AppointmentDateShow: this.AppointmentDateShow,
  //     AppointmentDate :  this.AppointmentDate,
  //     StartTime : this.AppointmentStartTime,
  //     EndTime : this.AppointmentEndTime,
  //     Day : this.AppointmentDay,
  //     Type: '',
  //     ScheduleId: this.AppointmentScheduleID,
  //     FollowupAppointmentId: this.selectedOption != undefined ? this.selectedOption.consultationId : '',
  //     IsBookBy: 'Patient',
  //     How: this.selectedOptionRescheule,
  //     DoctorName: this.local_data.doctorName
  //   }
  //   console.log(appointmentObj)
  //   this.router.navigate(['/auth/patient/login'], 
  //       { queryParams: { profile: JSON.stringify(appointmentObj) }});
  // }

  confirm() {
    this.dialogRef.close(this.local_data.date.Id);
    const appointmentObj = {
      DoctorId: this.local_data.doctorId,
      PatientId: this.patientId,
      AppointmentDate: this.AppointmentDate,
      StartTime: this.AppointmentStartTime,
      EndTime: this.AppointmentEndTime,
      Day: this.AppointmentDay,
      Type: '',
      ScheduleId: this.AppointmentScheduleID,
      FollowupAppointmentId: this.selectedOption != undefined ? this.selectedOption.consultationId : '',
      IsBookBy: 'Patient',
      How: this.selectedOptionRescheule
    }
    console.log(appointmentObj)
    this.appointmentService.bookAppointment(appointmentObj).subscribe((responses) => {

      console.log(responses)
      this._snackBar.open('Your appointment request has been sent, and you will be notified once the doctor accepts or denies your request.', '', {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['snackbar-class']
      })

    })
  }

  createUser() {
    // this.apicall = true
    if (this.UserForm.invalid) {
      if (
        this.UserForm.controls['firstname'].errors &&
        this.UserForm.controls['middlename'].errors &&
        this.UserForm.controls['lastname'].errors &&
        this.UserForm.controls['gender'].errors &&
        this.UserForm.controls['birthdate'].errors &&
        this.UserForm.controls['phoneNumber'].errors &&
        this.UserForm.controls['password'].errors
      ) {
        this.errorMessage = 'Please fill all details';
      } else if (this.UserForm.controls['firstname'].errors) {
        this.errorMessage = 'firstname is required';
      } else if (this.UserForm.controls['middlename'].errors) {
        this.errorMessage = 'middlename is required';
      } else if (this.UserForm.controls['lastname'].errors) {
        this.errorMessage = 'lastname is required';
      } else if (this.UserForm.controls['gender'].errors) {
        this.errorMessage = 'gender is required';
      } else if (this.UserForm.controls['birthdate'].errors) {
        this.errorMessage = 'birthdate is required';
      } else if (this.UserForm.controls['email'].errors) {
        this.errorMessage = 'email is required';
      } else if (this.UserForm.controls['phoneNumber'].errors) {
        this.errorMessage = 'phoneNumber  is required';
      } else if (this.UserForm.controls['password'].errors) {
        this.errorMessage = 'password  is required';
      }
      return;
    }
    this.loading4 = true
    const userData = this.UserForm.value;
    userData.birthdate = this.datePipe.transform(this.UserForm.get('birthdate')?.value, 'yyyy-MM-dd')
    var webpagePropties = {
      ...userData,
      height: this.patientRegistration.get('height')?.value,
      weight: this.patientRegistration.get('weight')?.value,
      aadharCard: this.patientRegistration.get('aadhar')?.value,
      address: this.patientRegistration.get('address')?.value,
      address2: this.patientRegistration.get('address2')?.value,
      city: this.patientRegistration.get('city')?.value,
      state: this.patientRegistration.get('state')?.value,
      country: this.patientRegistration.get('country')?.value,
      zipcode: this.patientRegistration.get('zipcode')?.value,
      haveYouEverHad: this.selectedsymptoms.toString(),
      healthHistory: this.selectedsymptoms.toString(),
      otherIllness: this.patientRegistration.get('OtherIllness')?.value,
      motherSideHealth: this.MotherSideHealth.toString(),
      fatherSideHealth: this.FatherSideHealth.toString(),
      otherIllnessFatherSide: this.patientRegistration.get('OtherIllnessFather')?.value,
      otherIllnessMotherSide: this.patientRegistration.get('OtherIllnessMother')?.value,
      isCreatedByDoc: false
    }
    console.log(webpagePropties)
    this.webpageService.createUserfromWebPage(webpagePropties).subscribe(
      (response) => {
        this.loading4 = false
        console.log('user created successfully', response.data.id);
        this.patientId = response.data.id
        this.schedule()
        // this.apicall = false
      },
      (error) => {
        this.loading4 = false
        console.error('Error webpage', error);
        // this.apicall = false
        // Handle error as needed
      }
    );
  }

  fetchDiagnosisDetails(patientId: string): void {
    this.loadingData = true;
    const param = {
      doctorId: this.local_data.doctorId,
      patientId: this.patientId,
    }
    this.appointmentService.followUpAppointment(param).subscribe(
      (response) => {

        console.log(response)
        this.dataSource = new MatTableDataSource(response.body.data);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length > 0) {
          this.selectedOption = this.dataSource.data[0]; // Set selectedOption to the first element
        }
        this.loadingData = false;
      },
      (error) => {
        // Handle errors here
        this.loadingData = false;
        console.error('API Error:', error);
        // You can handle errors and provide feedback to the user
      }
    );
  }
  selectedOption: any; // Variable to store the selected radio button value

  // Function to handle radio button change
  handleRadioChange(option: any) {
    console.log(option)
    this.selectedOption = option;
    // Additional logic or actions based on the selected option
    // You can update the table data accordingly or perform any other actions
  }
  handleRadioChangeReschedule(option: any) {
    console.log(option)
    this.selectedOptionRescheule = option.value;
    // Additional logic or actions based on the selected option
    // You can update the table data accordingly or perform any other actions
  }

  // Function to perform an action based on the selected option
  performAction() {
    if (this.selectedOption) {
      // Implement your logic here based on the selectedOption
      console.log("Performing action for", this.selectedOption);
    } else {
      console.log("No option selected");
    }
  }


  changeSelected(event: any, value: any) {
    console.log(event, this.selectedsymptoms.includes(value))
    const index = this.selectedsymptoms.indexOf(value);
    console.log(index)

    if (event.selected == false && this.selectedsymptoms.includes(value) && index >= 0) {
      this.selectedsymptoms.splice(index, 1);
    } else {
      this.selectedsymptoms.push(value);
    }
    console.log(this.selectedsymptoms.toString())
  }

  HealthHistorySelected(symptoms: any, value: any): void {
    let index = this.HealthHistory.indexOf(symptoms);

    if (symptoms.selected == false && this.HealthHistory.includes(value) && index >= 0) {
      this.HealthHistory.splice(index, 1);
    } else {
      this.HealthHistory.push(value);
    }
    console.log(this.HealthHistory.toString())
  }

  MotherSideHealthSelected(symptoms: any, value: any): void {
    let index = this.MotherSideHealth.indexOf(symptoms);

    if (symptoms.selected == false && this.MotherSideHealth.includes(value) && index >= 0) {
      this.MotherSideHealth.splice(index, 1);
    } else {
      this.MotherSideHealth.push(value);
    }
    console.log(this.MotherSideHealth.toString())
  }

  FatherSideHealthSelected(symptoms: any, value: any): void {
    let index = this.FatherSideHealth.indexOf(symptoms);

    if (symptoms.selected == false && this.FatherSideHealth.includes(value) && index >= 0) {
      this.FatherSideHealth.splice(index, 1);
    } else {
      this.FatherSideHealth.push(value);
    }
    console.log(this.FatherSideHealth.toString())
  }

  onKeyPress(e: any) {
    const allowedKeys = [8, 46];

    if (e.which !== 0 && (e.which < 48 || e.which > 57) && !allowedKeys.includes(e.which)) {
      e.preventDefault();
    }
  }
  /**
   * Login with Email/Mobile
   * @returns
   */
  login() {
    // this.apicall = true
    if (this.loginForm.invalid) {
      if (
        this.loginForm.controls['emailOrMobile'].errors &&
        this.loginForm.controls['password'].errors
      ) {
        this.errorMessage = 'Please fill Email/Mobile No And Password';
      } else if (this.loginForm.controls['password'].errors) {
        this.errorMessage = 'Password is required';
      } else if (this.loginForm.controls['emailOrMobile'].errors) {
        this.errorMessage = 'Email/Mobile No is required';
      }
      // this.apicall = false
      return;
    }

    this.loading = true
    const loginValue = this.loginForm.get('emailOrMobile')?.value.toLowerCase();

    if (loginValue && (/\S+@\S+\.\S+/).test(loginValue)) {
      this.loginType = 'Email'
    } else if (loginValue && (/^(?:(?!\b(\d)\1+\b)[6789]\d{9})$/).test(loginValue)) {
      this.loginType = 'Phone'
    }
    const Obj: any = {
      Email: '',
      PhoneNumber: '',
      type: this.loginType, //Email or Phone
      password: this.loginForm.get('password')?.value,
      FcmToken: this.devicetoken ? this.devicetoken : ''
    };

    if (this.loginType == 'Email') {
      Obj.Email = this.loginForm.get('emailOrMobile')?.value.toLowerCase();
    } else {
      Obj.PhoneNumber = this.loginForm.get('emailOrMobile')?.value;
    }
    this.errorMessage = '';
    this.authService.patientlogin(Obj).subscribe(
      (res: any) => {
        console.log('Response => ', res)
        const result = UtilityService.convertObjectToJson(res);
        console.log('Result => ', result);

        if (result.data.id != null) {
          UtilityService.setLocalStorage('userDetail', JSON.stringify(result.data));
          UtilityService.setLocalStorage('accessToken', result.data.token);
          UtilityService.setLocalStorage(
            'user_info',
            JSON.stringify(result.data)
          );
          UtilityService.setLocalStorage('userType', 'Patient');

          this.dialogRef.close('Close');
          const appointmentObj = {
            DoctorId: '2e0b24de-d6a7-44d9-8ec4-f90e667147b1',
            AppointmentTime: this.AppointmentTime,
            PatientId: this.patientId,
            AppointmentDateShow: this.AppointmentDateShow,
            AppointmentDate: this.AppointmentDate,
            StartTime: this.AppointmentStartTime,
            EndTime: this.AppointmentEndTime,
            Day: this.AppointmentDay,
            Type: '',
            ScheduleId: this.AppointmentScheduleID,
            FollowupAppointmentId: this.selectedOption != undefined ? this.selectedOption.consultationId : '',
            IsBookBy: 'Patient',
            How: this.selectedOptionRescheule,
            DoctorName: this.local_data.doctorName
          }

          console.log(environment.baseURL)
          window.location.href= `${environment.domainUrl}/patient/book-appointment?accessToken=${result.data.token}&websiteBookappointment=${JSON.stringify(appointmentObj)}&userDetail=${JSON.stringify(result.data)}`
          window.localStorage.setItem('websiteBookappointment', JSON.stringify(appointmentObj))
          localStorage.setItem('websiteBookappointment', JSON.stringify(appointmentObj))
          // this.router.navigate([`${environment.domainUrl}/patient/book-appointment`]);
          // this.apicall = false
          this.loading = false
        } else {
          if (this.loginType == 'Email' && result.data.message == 'User not found.') {
            this.errorMessage = 'You are not authorized for patient login'
          } else if (this.loginType == 'Email' && result.data.message != 'User not found.') {
            this.errorMessage = 'Invalid Email/Password'
          } else if (this.loginType == 'Phone' && result.data.message == 'You are not authorized for doctor login.') {
            this.errorMessage = 'You are not authorized for patient login'
          } else {
            this.errorMessage = 'Invalid Phone No/Password'
          }
          // this.apicall = false
          this.loading = false

        }
        // this.apicall = false

      },
      (error: any) => {
        // this.errorMessage = error.error.message;
        this.errorMessage = error.error.error.message;
        console.log(error.error.error.message)
        this.loading = false
        // this.apicall = false
      }
    );
  }

  /**
   * Validate Email Or Mobile
   * @param control
   * @returns
   */
  validateEmailOrMobile(control: any) {
    const value = control.value;

    if (value != '') {
      if (value && (/\S+@\S+\.\S+/).test(value)) {
        return null; // Valid email format
      } else if (value && (/^(?:(?!\b(\d)\1+\b)[6789]\d{9})$/).test(value)) {
        return null; // Valid mobile number format (10 digits)
      } else {
        return { pattern: true }; // Invalid format
      }
    }
    return null;

  }

  // Forgot Password
  get forgot() {
    return this.forgotForm.controls;
  }

  sendOtpMethod() {
    this.loading1 = true
    // this.apicall = true
    if (this.forgotForm.valid) {
      // const email = this.forgot['email'].value;
      var logType:string='';
      const loginValue = this.forgot['email']?.value.toLowerCase(); 

    if (loginValue && (/\S+@\S+\.\S+/).test(loginValue)) {
      logType = 'Email'
    } else if (loginValue && (/^(?:(?!\b(\d)\1+\b)[6789]\d{9})$/).test(loginValue)) {
      logType = 'Phone'
    }

      this.authService.sendOtp(loginValue,logType,'password','Patient').subscribe(
        (response: any) => {
          // Handle successful response
          console.log('OTP sent successfully:', response);
          this.loading1 = false
          if (response.data == "OTP Send Successfully") {
            localStorage.setItem('email', loginValue)
            this.OTPVerifyPage()
            this._snackBar.open('An OTP has been sent to your email address. Please check your inbox.', '', {
              duration: 2000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: ['snackbar-class']
            })
          }
          if (response.data == "User not found") {
            this.errorMsg = response.data
          }
          // this.apicall = false

        },
        (error: any) => {
          this.loading1 = false
          // Handle error
          console.error('Error sending OTP:', error);
          // this.apicall = false
        }
      );
    }
  }

  // OTP verification
  get otpControl() {
    return this.verifyForm.get('otp');
  }
  verifyOtpMethod() {
    this.loading2 = true

    // this.apicall = true
    if (this.verifyForm.valid) {
      const otp = this.otpControl?.value;
      this.authService.verifyOtp(otp, this.user_email, '','Patient').subscribe(
        (response) => {
          this.loading2 = false
          // Handle successful response
          console.log('OTP verification successful:', response);
          if (response.data) {
            if (response.data?.message === 'User not found.') {
              this.errorMsgVerify = 'User not found. Please check your email.';

            } else if (response.data?.message === 'Invalid otp.') {
              this.errorMsgVerify = 'OTP is incorrect';
            } else {
              this.ChangePasswordPage()
            }
          }
          // this.apicall = false

        },
        (error: any) => {
          // Handle error
          this.loading2 = false
          console.error('Error verifying OTP:', error);
          // this.apicall = false
        }
      );
    } else {
      this.loading2 = false
      // Display a custom error message for pattern validation
      if (this.otpControl?.hasError('pattern')) {
        this.otpControl.setErrors({ 'invalidPattern': true });
      }
    }
  }
  startResendTimer() {
    this.showResendButton = false;
    this.resendTimer = 30;
    this.updateTimer();
  }

  updateTimer() {
    if (this.resendTimer > 0) {
      setTimeout(() => {
        this.resendTimer--;
        this.updateTimer();
      }, 1000);
    } else {
      this.showResendButton = true;
    }
  }

  resendOtp() {
    // Your logic for resending OTP
    // ...
    this.errorMsgVerify = null
    // Start the resend timer
    this.startResendTimer();

    if (this.user_email) {
      // this.apicall = true
    var logType='';
    if (this.user_email && (/\S+@\S+\.\S+/).test(this.user_email)) {
      logType = 'Email'
    } else if (this.user_email && (/^(?:(?!\b(\d)\1+\b)[6789]\d{9})$/).test(this.user_email)) {
      logType = 'Phone'
    }

      this.authService.sendOtp(this.user_email,logType,'password','Patient').subscribe(
        (response: any) => {
          // Handle successful response
          console.log('OTP sent successfully:', response);
          if (response.data == "OTP Send Successfully") {
            this._snackBar.open('An OTP has been sent to your email address. Please check your inbox.', '', {
              duration: 2000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: ['snackbar-class']
            })
            // this.router.navigate(['/auth/email-verify']);
          }
          if (response.data == "User not found") {
            this.errorMsgVerify = response.data
          }
          // this.apicall = false

        },
        (error: any) => {
          // Handle error
          console.error('Error sending OTP:', error);
          // this.apicall = false
        }
      );
    }

  }

  //Change Password

  checkPasswords(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const group = control.parent as FormGroup;

      if (!group) return null;

      const passwordControl = group.get('password');
      const confirmPasswordControl = group.get('c_password');

      if (!passwordControl || !confirmPasswordControl) return null;

      const password = passwordControl.value;
      const confirmPassword = confirmPasswordControl.value;

      return confirmPassword ? password === confirmPassword ? null : { mismatch: true } : null;
    };
  }
  get passwordControl() {
    return this.changeForm.get('password');
  }
  changePasswordMethod() {
    this.loading3 = true
    if (this.changeForm.valid) {
      // this.apicall = true
      const password = this.passwordControl?.value;
      // Replace with the actual email

      this.authService.changePassword(this.user_email,'', password,'Patient').subscribe(
        (response) => {
          // Handle successful response
          console.log('Password changed successfully:', response);

          this.loading3 = false
          if (response.data) {
            var role = response.data?.type
            if (role == 'Patient') {
              this.schedule()
              this._snackBar.open('Password changed successfully', '', {
                duration: 2000,
                verticalPosition: 'top',
                horizontalPosition: 'right',
                panelClass: ['snackbar-class']
              })
            }
          }
          // this.apicall = false

        },
        (error) => {
          this.loading3 = false
          // Handle error
          console.error('Error changing password:', error);
          // this.apicall = false
        }
      );
    }
  }
}
