<div fxLayout="row" fxLayoutAlign="start start"  fxLayout.lt-sm="center" class="mat-sidenav-content1">
  <img src="./assets/images/logo.png" alt="" height="50px" fxFlex.lt-sm="80%" />
</div>
<div class="tableBorder divBorder mat-sidenav-content1">
  <div class="margin-div" fxLayout="row" fxLayoutAlign="space-between center">
    <h4 id="underline-gap" class="m-0 pb-2 font-size-19 capitalize text-middle">Consent</h4>
    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
      <p>Doctor Name:</p>
      <p><b>{{doctorName}}</b></p>
    </div> -->
  </div>

  <div class="maincontainer" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
      <p style="text-align: center;font-size: 16px;">Dr. {{doctorName}} requests access to your medical records. Approve?</p>
      <!-- <p><b>{{doctorName}}</b></p> -->
    </div>
    <div fxLayout="row"  fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      @if (this.status == 'Pending' || this.status == 'Denied'){
        <button mat-raised-button
          class="view-Button-doctor" color="primary" (click)="accessPermission('Approved')">Approve
          Access</button>
      }
      @if (this.status == 'Pending' || this.status == 'Approved'){
        <button mat-raised-button 
          class="view-Button-doctor" color="warn" (click)="accessPermission('Denied')">{{this.status ==
          'p' ? 'Deny Access' :
          'Revoke Access'}}</button>
      }
    </div>
  </div>
</div>