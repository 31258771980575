export class OrganizationBranchModel {
  phoneNumber: string;
  whatsappNumber: string;
  faxNumber: string;
  facilityId: string;
  licenseNumber: string;
  accreditationNumber: string;
  firstName: string;
  lastName: string;
  aadharCard: string;
  gender: string;
  dateOfBirth: string;
  email: string;
  cellPhone: string;
  password: string;
  userId: string;
  organizationBranchName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}
