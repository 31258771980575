import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { config } from 'src/app/configs/config';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class PatientDashboardService {
  private baseUrl = environment.baseURL;
  private patientApiUrl = '/api/Patient';

  constructor(private http: HttpClient,private service : ApiService) {}

  getDashboardData(patientId: string): Observable<any> {
    const url = `${this.baseUrl}${this.patientApiUrl}/Dashboard?patientId=${patientId}`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error fetching Prakruti questions:', error);
        return throwError(error);
      })
    );
  }
  accessPermissionforData(data: any): Observable<any> {
    const url = `${this.baseUrl}${this.patientApiUrl}/PermissionforPatientOrg`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.post(url, data, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error adding Prakruti analysis:', error);
        return throwError(error);
      })
    );
  }

  permissionforPatientDetailOpen(patientId: any, doctorId: any, requestStatus: any): Observable<any> {
    const url = `${this.baseUrl}${this.patientApiUrl}/PermissionforPatientDetailOpen?patientId=${patientId}&doctorId=${doctorId}&requestStatus=${requestStatus}`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error adding Prakruti analysis:', error);
        return throwError(error);
      })
    );
  }
  permissionforPatientOrg(patientId: any, doctorId: any, requestStatus: any,organizationId:any): Observable<any> {
    const url = `${this.baseUrl}${this.patientApiUrl}/PermissionforPatientOrg`;

    const body = {
      PatientId: patientId,
      DoctorId: doctorId,
      RequestStatus: requestStatus,
      OrganizationId:organizationId
    };
    console.log(body)
    
    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.post(url,body, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error updating consent:', error);
        return throwError(error);
      })
    );
  }
  private createAuthorizationHeader(): HttpHeaders {
    const accessToken = localStorage.getItem('accessToken');

    return new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    });
  }

  GetPatientPermissionForOrg(patientId: string): Observable<any> {
    return this.service.get(config.partialUrls.GetPatientPermissionForOrg, {
      patientId: patientId,
    });
  }

}
