import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebpageService {
  private doctorInfoSubject = new BehaviorSubject<any>(null);
  doctorInfo$ = this.doctorInfoSubject.asObservable();

  private baseUrl = environment.baseURL;
  private webpageApiUrl = '/api/WebPage';

  constructor(private http: HttpClient) { }

  setDoctorInfo(data: any): Observable<any> {
    this.doctorInfoSubject.next(data);
    return this.doctorInfoSubject.asObservable();
  }

  createWebPage(data: any): Observable<any> {
    const url = `${this.baseUrl}${this.webpageApiUrl}/CreateWebPage`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.post(url, data, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error adding webpage:', error);
        return throwError(error);
      })
    );
  }

  editWebPage(data: any): Observable<any> {
    const url = `${this.baseUrl}${this.webpageApiUrl}/EditWebPage`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.post(url, data, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error editing webpage:', error);
        return throwError(error);
      })
    );
  }

  viewWebPage(doctorId: any): Observable<any> {
    // =='b3bcfbe2-4dd2-4f0d-a78c-801300095eca'
    // = '45a76e4c-e6ea-48a8-b65c-3c2df9cedda8'
    const url = `${this.baseUrl}${this.webpageApiUrl}/ViewWebPage?doctorId=${doctorId}`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error fetching webpage:', error);
        return throwError(error);
      })
    );
  }

  ViewWebPageOpen(doctorId: any): Observable<any> {
    // =='b3bcfbe2-4dd2-4f0d-a78c-801300095eca'
    // = '45a76e4c-e6ea-48a8-b65c-3c2df9cedda8'
    const url = `${this.baseUrl}${this.webpageApiUrl}/ViewWebPageOpen?doctorId=${doctorId}`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error fetching webpage:', error);
        return throwError(error);
      })
    );
  }

  viewWebPageForDomain(domain: any): Observable<any> {
    // =='b3bcfbe2-4dd2-4f0d-a78c-801300095eca'
    // = '45a76e4c-e6ea-48a8-b65c-3c2df9cedda8'
    const url = `${this.baseUrl}${this.webpageApiUrl}/ViewWebPageviaDomain?subDomain=${domain}`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error fetching webpage:', error);
        return throwError(error);
      })
    );
  }

  createUserfromWebPage(data: any): Observable<any> {
    const url = `${this.baseUrl}${this.webpageApiUrl}/CreateUserfromWebPage`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.post(url, data, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error adding webpage:', error);
        return throwError(error);
      })
    );
  }

  createSubDomain(domainname: any): Observable<any> {
    const url = `${this.baseUrl}${this.webpageApiUrl}/CreateSubDomain?subDomainName=${domainname}`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.post(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error adding webpage:', error);
        return throwError(error);
      })
    );
  }

  CheckSubDomainAvailability(domain: any): Observable<any> {
    // =='b3bcfbe2-4dd2-4f0d-a78c-801300095eca'
    // = '45a76e4c-e6ea-48a8-b65c-3c2df9cedda8'
    const url = `${this.baseUrl}${this.webpageApiUrl}/CheckSubDomainAvailability?subDomainName=${domain}`;

    // Include authorization header directly
    const headers = this.createAuthorizationHeader();

    return this.http.get(url, { headers }).pipe(
      catchError((error) => {
        // Handle errors here
        console.error('Error fetching webpage:', error);
        return throwError(error);
      })
    );
  }

  private createAuthorizationHeader(): HttpHeaders {
    const accessToken = localStorage.getItem('accessToken');

    return new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    });
  }

}
