import { Injectable } from '@angular/core';
import { config } from 'src/app/configs/config';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class UniDashboardService {
  constructor(private service: ApiService) {}

  FetchMasterReportData(userId: any) {
    return this.service.post(config.partialUrls.GetMasterReportData + '?userId=' + userId, {});
  }

  FetchPatientOverviewData(userId: any,hospitalId:any) {
    return this.service.post(config.partialUrls.GetPatientOverview + '?userId=' + userId + '&OrgBranchId='+hospitalId, {});
  }

  FetchHospitalListData(userId: any) {
    return this.service.post(config.partialUrls.GetHospitalList + '?userId=' + userId, {});
  }
  FetchAlertsData(userId: any) {
    return this.service.post(config.partialUrls.GetAlertList + '?userId=' + userId, {});
  }
}
