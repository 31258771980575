import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { UtilityService } from 'src/app/core/services/utility.service';
import { UniDashboardService } from 'src/app/shared/services/uni-dashboard-service/uni-dashboard.service';

@Component({
  selector: 'app-summarycard',
  standalone: true,
  imports: [MatCardModule,MatButtonModule,MatIcon],
  templateUrl: './summarycard.component.html',
  styleUrl: './summarycard.component.scss'
})
export class SummarycardComponent implements OnInit {

  opdNotComplianceCount:number=0;
  inpNotComplianceCount:number=0;
  constructor(private uniDashboardService:UniDashboardService){
  }
  ngOnInit(): void {
    const user = UtilityService.getUser();
    this.uniDashboardService.FetchAlertsData(user.id).subscribe(
      (response)=>{
        var data = response.body;
        this.opdNotComplianceCount = data.filter((item: { patientOpdRatio: number; })=>item.patientOpdRatio<0.5).length;
        this.inpNotComplianceCount = data.filter((item: { patientInpRatio: number; })=>item.patientInpRatio<0.5).length;
      }
    );
  }
  isExpanded = false;

  toggleAlerts(): void {
    const alertList = document.getElementById('alertList');
    if (alertList) {
      if (this.isExpanded) {
        alertList.style.maxHeight = '90px';
      } else {
        alertList.style.maxHeight = alertList.scrollHeight + 'px';
      }
      this.isExpanded = !this.isExpanded;
    }

  }
}
