<!-- <app-loader></app-loader> -->
@if (this.portal) {
 <div id="capture" >
<router-outlet></router-outlet>
</div>
}
@if (!this.portal) {
    <div id="capture" >
   <app-webpage></app-webpage>
   </div>
   }