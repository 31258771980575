import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexDataLabels,
    ApexGrid,
    ApexLegend,
    ApexYAxis,
    ApexStroke,
    NgApexchartsModule,
    ApexFill,
    ApexPlotOptions,
    ApexTooltip,
    ApexTitleSubtitle,
    ApexNonAxisChartSeries
} from "ng-apexcharts";
import { UniDashboardService } from 'src/app/shared/services/uni-dashboard-service/uni-dashboard.service';
import { L } from '@angular/cdk/keycodes';
import { UtilityService } from 'src/app/core/services/utility.service';
import { CommonModule } from '@angular/common';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    grid: ApexGrid;
    stroke: ApexStroke;
    fill: ApexFill;
    colors: string[];
    yaxis: ApexYAxis;
    legend: ApexLegend;
    dataLabels: ApexDataLabels;
};

export type AgeGroupChartOptions = {
  series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    yaxis: ApexYAxis;
    colors: string[];
    grid: ApexGrid;
    xaxis: ApexXAxis;
    fill: ApexFill;
    title: ApexTitleSubtitle;
};

export type GenderChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: any;
  colors: string[];
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  fill: ApexFill;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
};

export type TreeChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    title: ApexTitleSubtitle;
    plotOptions: ApexPlotOptions;
    legend: ApexLegend;
};

@Component({
  selector: 'app-patient-overview',
  standalone: true,
  imports: [MatProgressBarModule,RouterLink, MatCardModule, MatButtonModule, MatMenuModule, NgApexchartsModule,CommonModule],
  templateUrl: './patient-overview.component.html',
  styleUrl: './patient-overview.component.scss'
})
export class PatientOverviewComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;
    public ageGroupChartOptions: Partial<AgeGroupChartOptions>;
    public genderChartOptions: Partial<GenderChartOptions>;
    public treeChartOptions: Partial<TreeChartOptions>;
    opdPatients:number;
    inPatients:number;
    carePlanPatients:number;
    genderHospitalList:any=[{organizationBranchId:'all',organizationBranchName:'All'}]
    hospitalList:any=[{organizationBranchId:'all',organizationBranchName:'All'}]
    currentGenderHospitalSelection:any='All';
    currentAgeHospitalSelection:any='All';
    currentDepartmentHospitalSelected:any = 'All';


    constructor(private uniDashboardService:UniDashboardService) {
        this.chartOptions = {
            series: [
                {
                    name: "Expense",
                    data: [130, 90, 120, 160, 200, 170, 130, 100, 70, 100, 120, 75]
                },
                {
                    name: "Revenue",
                    data: [20, 80, 50, 40, 120, 200, 180, 130, 230, 170, 90, 120]
                }
            ],
            chart: {
                height: 220,
                width:'100%',
                type: "area",
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: "gradient",
                gradient: {
                    opacityFrom: 0.45,
                    opacityTo: 0.05
                }
            },
            grid: {
                show: true,
                strokeDashArray: 0,
                borderColor: "#edeff5",
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                }
            },
            stroke: {
                width: 4,
                curve: "smooth"
            },
            colors: [
                "#EDEFF5", "#3761EE"
            ],
            xaxis: {
                axisBorder: {
                    show: false,
                    color: '#edeff5'
                },
                axisTicks: {
                    show: false,
                    color: '#edeff5'
                },
                labels: {
                    show: true,
                    style: {
                        colors: "#262626",
                        fontSize: "13px"
                    }
                },
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec"
                ]
            },
            yaxis: {
                labels: {
                    show: true,
                    style: {
                        colors: "#a9a9c8",
                        fontSize: "13px"
                    }
                },
                axisBorder: {
                    show: false,
                    color: '#edeff5'
                }
            },
            legend: {
                show: false,
                position: 'top',
                fontSize: '13px',
                horizontalAlign: 'center',
                labels: {
                    colors: '#77838f',
                },
                itemMargin: {
                    horizontal: 15,
                    vertical: 0
                },
                markers: {
                    offsetY: -1
                }
            }
        };

       

       

        this.treeChartOptions={
            series: [
                {
                    data: [
                        {
                            x: "Kayachikitsa",
                            y: 218
                        },
                        {
                            x: "Panchakarma",
                            y: 149
                        },
                        {
                            x: "Shalya Tantra",
                            y: 184
                        },
                        {
                            x: "Shalakya Tantra",
                            y: 55
                        },
                        {
                            x: "Prasuti Tantra and Stree Roga",
                            y: 84
                        },
                        {
                            x: "Bal Roga/Kaumarbhritya",
                            y: 31
                        },
                        {
                            x: "Swasthavritta",
                            y: 70
                        },
                        {
                            x: "Maulik Siddhant",
                            y: 30
                        },
                        {
                            x: "Rasa Shastra",
                            y: 44
                        },
                        {
                            x: "Dravyaguna",
                            y: 68
                        },
                        {
                            x: "Agad Tantra",
                            y: 28
                        },
                        {
                            x: "Sharir Kriya",
                            y: 19
                        },
                        {
                            x: "Roganidana",
                            y: 29
                        },
                        {
                            x: "Vajikarana",
                            y: 45
                        },
                        {
                            x: "Bhaishajya Kalpana",
                            y: 35
                        },
                        {
                            x: "Sharir Rachana",
                            y: 24
                        }
                    ]
                    
                }
            ],
            chart: {
                height: 350,
                type: "treemap",
                toolbar: {
                    show: true
                }
            },
            title: {
                text: "Total Patients",
                align: "left",
                offsetX: -9,
                style: {
                    fontWeight: '500',
                    fontSize: '14px',
                    color: '#5b5b98'
                }
            }
        };
    }
    ngOnInit(): void {
        const userInfo = UtilityService.getUser();
        this.uniDashboardService.FetchHospitalListData(userInfo.id).subscribe(
            (response)=>{
                console.log(response.body);
                this.hospitalList = [...this.hospitalList,...response.body];
                //this.hospitalList = [...this.hospitalList,...data.map((x: { organizationBranchName: any; })=>x.organizationBranchName)]
            }
        );
        this.uniDashboardService.FetchPatientOverviewData(userInfo.id,'all').subscribe(
            (response)=>{
                console.log(response.body);
                var data = response.body;

                const ageGroupCounts = this.getAgeGroupCounts(data);
                this.ageGroupChartOptions = {
                    series: [
                        {
                            name: "Total Patients",
                            data: ageGroupCounts
                        }
                    ],
                    chart: {
                        height: 265,
                        type: "bar",
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 6,
                            columnWidth: '30px'
                        }
                    },
                    colors: [
                        "#00836C"
                    ],
                    dataLabels: {
                        enabled: true,
                        formatter: function(val) {
                            return val+'';
                        },
                        offsetY: 7,
                        style: {
                            fontSize: "12px",
                            fontWeight: '500',
                            colors: ["#ffffff"]
                        }
                    },
                    grid: {
                        show: true,
                        strokeDashArray: 0,
                        borderColor: "#edeff5",
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                        yaxis: {
                            lines: {
                                show: true
                            }
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    xaxis: {
                        axisBorder: {
                            show: false,
                            color: '#edeff5'
                        },
                        axisTicks: {
                            show: false,
                            color: '#edeff5'
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: "#262626",
                                fontSize: "13px"
                            }
                        },
                        categories: [
                            "0-10",
                            "11-20",
                            "21-30",
                            "31-40",
                            "41-50",
                            "51-60",
                            "60+"
                        ]
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        labels: {
                            show: false,
                            formatter: function(val) {
                                return val + "";
                            }
                        }
                    }
                };

                const genderCounts = this.getGenderCounts(data);
                this.genderChartOptions = {
                    series: genderCounts,
                    chart: {
                        height: 300,
                        type: "donut"
                    },
                    labels: [
                        "Male","Female"
                    ],
                    stroke: {
                        width: 2,
                        show: true,
                        curve: "smooth"
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: [
                        "#2DB6F5", "#6794DC"
                    ],
                    legend: {
                        offsetY: 0,
                        fontSize: "13px",
                        position: "bottom",
                        horizontalAlign: "left",
                        labels: {
                            colors: "#77838F",
                        },
                        itemMargin: {
                            horizontal: 12,
                            vertical: 8
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function(val) {
                                return val + "";
                            }
                        }
                    }
                };

                const departmentCounts = this.getDepartmentCountData(data);
                this.treeChartOptions={
                    series: [
                        {
                            data: departmentCounts
                            
                        }
                    ],
                    chart: {
                        height: 350,
                        type: "treemap",
                        toolbar: {
                            show: true
                        }
                    },
                    title: {
                        text: "Total Patients",
                        align: "left",
                        offsetX: -9,
                        style: {
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#5b5b98'
                        }
                    }
                };

                this.opdPatients = data.filter((x: { consultationType: string; })=>x.consultationType==='New').length;
                this.inPatients = data.filter((x: { consultationType: string; })=>x.consultationType==='Admit').length;
                this.carePlanPatients = data.filter((x: { consultationType: string; })=>x.consultationType==='Care Plan').length;
            }
        );
    }

    getAgeGroupCounts(data:any) {
        // Define the age groups
        const ageGroups = [0, 10, 20, 30, 40, 50, 60, Infinity];
        // Initialize counters for each age group
        const counts = new Array(ageGroups.length - 1).fill(0);
    
        data.forEach((item: { age: any; }) => {
            const age = item.age;
            if (age !== null && age !== undefined) {
                for (let i = 0; i < ageGroups.length - 1; i++) {
                    if (age >= ageGroups[i] && age < ageGroups[i + 1]) {
                        counts[i]++;
                        break;
                    }
                }
            }
        });
        console.log(counts);
        return counts;
    }

    getGenderCounts(data:any) {
        // Initialize counters for each gender
        const genderCounts = { Male: 0, Female: 0 };
    
        data.forEach((item: { gender: string; }) => {
            if (item.gender === 'Male') {
                genderCounts.Male++;
            } else if (item.gender === 'Female') {
                genderCounts.Female++;
            }
        });
    
        return [genderCounts.Male, genderCounts.Female];
    }

    getDepartmentCountData(data:any) {
        // Step 1: Initialize an empty map to count occurrences of each department
        const departmentCountMap: { [key: string]: number } = {};
    
        // Step 2: Iterate through the API response and count departments
        data.forEach((item: { department: string; }) => {
          const department = this.extractDepartmentName(item.department);
          if (departmentCountMap[department]) {
            departmentCountMap[department]++;
          } else {
            departmentCountMap[department] = 1;
          }
        });
    
        // Step 3: Convert the map to the desired format
        const formattedDepartmentData = Object.keys(departmentCountMap).map(key => ({
          x: key,
          y: departmentCountMap[key]
        }));
    
        return formattedDepartmentData;
      }
    
      extractDepartmentName(department: string): string {
        return department.split(' ')[0];
      }

    onGenderHospitalSelect(hospital:any){
        console.log(hospital);
        this.currentGenderHospitalSelection = hospital.organizationBranchName;
        const userInfo = UtilityService.getUser();
        this.uniDashboardService.FetchPatientOverviewData(userInfo.id,hospital.organizationBranchId).subscribe(
            (response)=>{
                var data = response.body;
                const genderCounts = this.getGenderCounts(data);
                this.genderChartOptions = {
                    series: genderCounts,
                    chart: {
                        height: 300,
                        type: "donut"
                    },
                    labels: [
                        "Male","Female"
                    ],
                    stroke: {
                        width: 2,
                        show: true,
                        curve: "smooth"
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: [
                        "#2DB6F5", "#6794DC"
                    ],
                    legend: {
                        offsetY: 0,
                        fontSize: "13px",
                        position: "bottom",
                        horizontalAlign: "left",
                        labels: {
                            colors: "#77838F",
                        },
                        itemMargin: {
                            horizontal: 12,
                            vertical: 8
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function(val) {
                                return val + "";
                            }
                        }
                    }
                };
            }
        );
    }

    onAgeHospitalSelect(hospital:any){
        console.log(hospital);
        this.currentAgeHospitalSelection = hospital.organizationBranchName;
        const userInfo = UtilityService.getUser();
        this.uniDashboardService.FetchPatientOverviewData(userInfo.id,hospital.organizationBranchId).subscribe(
            (response)=>{
                var data = response.body;
                const ageGroupCounts = this.getAgeGroupCounts(data);
                this.ageGroupChartOptions = {
                    series: [
                        {
                            name: "Total Patients",
                            data: ageGroupCounts
                        }
                    ],
                    chart: {
                        height: 265,
                        type: "bar",
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 6,
                            columnWidth: '30px'
                        }
                    },
                    colors: [
                        "#00836C"
                    ],
                    dataLabels: {
                        enabled: true,
                        formatter: function(val) {
                            return val+'';
                        },
                        offsetY: 7,
                        style: {
                            fontSize: "12px",
                            fontWeight: '500',
                            colors: ["#ffffff"]
                        }
                    },
                    grid: {
                        show: true,
                        strokeDashArray: 0,
                        borderColor: "#edeff5",
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                        yaxis: {
                            lines: {
                                show: true
                            }
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    xaxis: {
                        axisBorder: {
                            show: false,
                            color: '#edeff5'
                        },
                        axisTicks: {
                            show: false,
                            color: '#edeff5'
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: "#262626",
                                fontSize: "13px"
                            }
                        },
                        categories: [
                            "0-10",
                            "11-20",
                            "21-30",
                            "31-40",
                            "41-50",
                            "51-60",
                            "60+"
                        ]
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        labels: {
                            show: false,
                            formatter: function(val) {
                                return val + "";
                            }
                        }
                    }
                };
            }
        );
    }

    onDepartmentHospitalSelect(hospital:any){
        console.log(hospital);
        this.currentDepartmentHospitalSelected = hospital.organizationBranchName;
        const userInfo = UtilityService.getUser();
        this.uniDashboardService.FetchPatientOverviewData(userInfo.id,hospital.organizationBranchId).subscribe(
            (response)=>{
                var data = response.body;
                const departmentCounts = this.getDepartmentCountData(data);
                this.treeChartOptions={
                    series: [
                        {
                            data: departmentCounts
                            
                        }
                    ],
                    chart: {
                        height: 350,
                        type: "treemap",
                        toolbar: {
                            show: true
                        }
                    },
                    title: {
                        text: "Total Patients",
                        align: "left",
                        offsetX: -9,
                        style: {
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#5b5b98'
                        }
                    }
                };
            }
        );
    }
}
