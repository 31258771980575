<div class="mainBackg">
  <div>
    <mat-card class="onboardingMainWrapper">
      <div class="profileDivWrapper">
        <span class="onboardTitle">Hospital Onboarding</span>
        <button mat-icon-button class="avatarButton" [matMenuTriggerFor]="appMenu">
          <img src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="Avatar" class="avatar" />
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>
      </div>

      <div>
        <mat-horizontal-stepper>
          <!-- Hospital Basic Info Section -->
          <mat-step label="Hospital Info">
            <app-hospital-info #hospitalInfoComponent></app-hospital-info>

            <div class="btn-nav">
              <button mat-raised-button matStepperNext (click)="onHospitalInfoNext()" type="button">Next</button>
            </div>
          </mat-step>

          <!-- Departments & Room Section -->
          <mat-step label="Departments & Room">
            <app-departments-room #departmentRoomComponent></app-departments-room>

            <div class="btn-nav">
              <button mat-raised-button matStepperPrevious type="button">
                Back
              </button>
              <button mat-raised-button matStepperNext (click)="onNextDepartment()" type="button"
                [disabled]="this.departmentRoomComponent.wardInfoFormGroup.invalid">
                Next
              </button>
            </div>
          </mat-step>

          <!-- Staff Management Section -->
          <mat-step label="Staff Management">
            <app-staff-management #staffManagementComponent></app-staff-management>

            <div class="btn-nav">
              <button mat-raised-button matStepperPrevious type="button">
                Back
              </button>
              <button mat-raised-button matStepperNext (click)="onSaveForm()" type="button">
                Done
              </button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </mat-card>
  </div>
</div>