<div class="row">
    <div class="col-lg-6 col-md-12">
        <mat-card class="trinta-card issues-summary-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        OPD Performance
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                        {{currentOpdSelection}}
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item (click)="onOpdSelect('Top 5')">
                            Top 5
                        </button>
                        <button mat-menu-item (click)="onOpdSelect('Top 10')">
                            Top 10
                        </button>
                        <button mat-menu-item (click)="onOpdSelect('Bottom 5')">
                            Bottom 5
                        </button>
                        <button mat-menu-item (click)="onOpdSelect('Bottom 10')">
                            Bottom 10
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>

                <div class="chart">
                    <apx-chart [series]="opdChartOptions.series!" [chart]="opdChartOptions.chart!"
                        [colors]="opdChartOptions.colors!" [plotOptions]="opdChartOptions.plotOptions!"
                        [grid]="opdChartOptions.grid!" [dataLabels]="opdChartOptions.dataLabels!"
                        [xaxis]="opdChartOptions.xaxis!" [yaxis]="opdChartOptions.yaxis!"
                        [legend]="opdChartOptions.legend!"></apx-chart>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-6 col-md-12">
        <mat-card class="trinta-card issues-summary-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        In-Patient Performance
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu1">
                        {{currentInpSelection}}
                    </button>
                    <mat-menu #cardHeaderMenu1="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item (click)="onInpSelect('Top 5')">
                            Top 5
                        </button>
                        <button mat-menu-item (click)="onInpSelect('Top 10')">
                            Top 10
                        </button>
                        <button mat-menu-item (click)="onInpSelect('Bottom 5')">
                            Bottom 5
                        </button>
                        <button mat-menu-item (click)="onInpSelect('Bottom 10')">
                            Bottom 10
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>

                <div class="chart">
                    <apx-chart [series]="inpChartOptions.series!" [chart]="inpChartOptions.chart!"
                        [colors]="inpChartOptions.colors!" [plotOptions]="inpChartOptions.plotOptions!"
                        [grid]="inpChartOptions.grid!" [dataLabels]="inpChartOptions.dataLabels!"
                        [xaxis]="inpChartOptions.xaxis!" [yaxis]="inpChartOptions.yaxis!"
                        [legend]="inpChartOptions.legend!"></apx-chart>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-12 col-md-12">
        <mat-card class="trinta-card courses-overview-card mb-25 bg-white border-none d-block">
            <mat-card-header>
                <mat-card-title>
                    <h5 class="mt-0 mb-0">
                        This year's data
                    </h5>
                </mat-card-title>
                <mat-card-subtitle>
                    <button type="button" mat-button class="card-header-menu-btn" [matMenuTriggerFor]="cardHeaderMenu">
                        This Year
                    </button>
                    <mat-menu #cardHeaderMenu="matMenu" class="card-header-menu" xPosition="before">
                        <button mat-menu-item>
                            This Day
                        </button>
                        <button mat-menu-item>
                            This Week
                        </button>
                        <button mat-menu-item>
                            This Month
                        </button>
                        <button mat-menu-item>
                            This Year
                        </button>
                    </mat-menu>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="chart">
                    <apx-chart [series]="chartOptions.series!" [chart]="chartOptions.chart!"
                        [xaxis]="chartOptions.xaxis!" [colors]="chartOptions.colors!" [stroke]="chartOptions.stroke!"
                        [tooltip]="chartOptions.tooltip!" [dataLabels]="chartOptions.dataLabels!"
                        [legend]="chartOptions.legend!" [markers]="chartOptions.markers!" [grid]="chartOptions.grid!"
                        [yaxis]="chartOptions.yaxis!" [title]="chartOptions.title!"></apx-chart>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>