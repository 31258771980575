<div
  class="header-area bg-white box-shadow transition"
  [ngClass]="{ active: isToggled }"
>
  <div class="d-md-flex align-items-center justify-content-between">
    <div class="header-left-side d-flex align-items-center">
      <div
        class="burger-menu position-relative transition lh-1"
        [ngClass]="{ active: isToggled }"
        (click)="toggle()"
      >
        <i-feather name="align-left"></i-feather>
      </div>
      <!-- <img src="../../../../assets/images/logo.png" alt="logo" class="logo" /> -->

      <img
        *ngIf="userType === 'Patient'"
        alt="logo"
        class="logo"
        src="./assets/images/ThriveHubLogopng.png" style="width: 10%;"
      />
      <img
        *ngIf="userType !== 'Patient'"
        class="logo"
        src="./assets/images/VitalyticsLogopng.png" style="width: 10%;"
      />
    </div>

    <ul
      class="header-right-side d-flex align-items-center m-0 p-0 list-unstyled"
    >
      <li>
        <button
          mat-button
          (click)="toggleTheme()"
          class="dark-swtich-btn"
          [class.active]="toggleService.isDark()"
        >
          <i-feather name="moon"></i-feather>
        </button>
      </li>

      <li>
        <div class="date-btn position-relative">
          <i-feather name="calendar"></i-feather> {{ formattedDate }}
        </div>
      </li>

      @if (this.profileName != '') {
      <li>
        <button
          mat-button
          [matMenuTriggerFor]="profileMenu"
          class="profile-menu-btn"
        >
          <img
            src="../../../../assets/images/Group 442.png"
            class="rounded-circle"
            alt="admin-image"
          />
        </button>
        <mat-menu
          class="profile-menu-dropdown"
          #profileMenu="matMenu"
          xPosition="before"
        >
          <div class="menu-header d-flex align-items-center">
            <img
              src="../../../../assets/images/Group 442.png"
              class="rounded-circle"
              alt="admin-image"
            />
            <div class="title">
              <a routerLink="/my-profile" class="d-inline-block fw-medium">
                {{ this.profileName }}
              </a>
              <span class="designation d-block text-body"> Admin </span>
            </div>
          </div>
          <ul class="menu-body pl-0 mb-0 mt-0 list-unstyled">
            <!-- <li class="position-relative transition fw-medium">
              <i class="material-symbols-outlined"> person </i>
              My Profile
              <a
                routerLink="/my-profile"
                class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"
              ></a>
            </li>
            <li class="position-relative transition fw-medium">
              <i class="material-symbols-outlined"> settings </i>
              Settings
              <a
                routerLink="/settings"
                class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"
              ></a>
            </li>
            <li class="position-relative transition fw-medium">
              <i class="material-symbols-outlined"> info </i>
              Support
              <a
                routerLink="/settings/connections"
                class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"
              ></a>
            </li> -->
            <li class="position-relative transition fw-medium">
              <i class="material-symbols-outlined"> logout </i>
              Logout
              <span
                (click)="logout()"
                class="link-btn d-block position-absolute left-0 right-0 top-0 bottom-0 z-1"
              ></span>
            </li>
            
          </ul>
        </mat-menu>
      </li>
      }
    </ul>
  </div>
</div>
