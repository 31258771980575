import {ErrorHandler, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiService} from './services/api.service';
import {LoaderService} from './services/loader.service';
import {UtilityService} from './services/utility.service';
import {NotificationService} from './services/notification.service';
import {Interceptor} from './interceptor/interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {GlobalErrorHandlerService} from './services/global-error-handler.service';
import {LoaderInterceptorService} from "./interceptor/loader-interceptor.service";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,

  ],
  providers: [

    ApiService,
    LoaderService,
    UtilityService,
    NotificationService,
    {provide: ErrorHandler, useClass: GlobalErrorHandlerService},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    
        // {provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true},
  ]
})
export class CoreModule {
}
