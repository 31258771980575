<div
    [ngClass]="{'blank-page': router.url === '/authentication' || router.url === '/authentication/sign-up' || router.url === '/authentication/forgot-password' || router.url === '/authentication/reset-password' || router.url === '/authentication/lock-screen' || router.url === '/authentication/logout' || router.url === '/authentication/confirm-email'}"
    [class.dark-theme]="toggleService.isDark()"
>

    <!-- Sidebar -->
    <app-unisidebar></app-unisidebar>

    <!-- Main Content -->
    <div
        class="main-content transition d-flex flex-column"
        [ngClass]="{'active': isToggled}"
    >
        <app-header></app-header>
        <app-summarycard></app-summarycard>
        <router-outlet></router-outlet>
        <div class="flex-grow-1">
            
        </div>
        <app-footer></app-footer>
    </div>

</div>