import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { config } from 'src/app/configs/config';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environments/environment';
import {
  RegisterOrganizationBody,
  SendOTPBody,
  SendOTPResponse,
  VerifyOTPBody,
  VerifyOTPResponse,
} from '../model/auth/register';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/core/services/utility.service';
import { ResponseWrapper } from '../model/common';
import {
  CheckAadhaarBody,
  CheckAadhaarResponse,
} from '../model/auth/check-aadhaar';
import { LoginRequestBody, LoginResponse } from '../model/auth/login';
import {
  GetVerifyCommunicationResponse,
  VerifyCommunicationBody,
} from '../model/auth/verify-communication';
import { UpdateUserPasswordBody } from '../model/auth/forgot-password';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private service: ApiService,
    private http: HttpClient,
    private router: Router
  ) {}

  /**
   * Login
   * @param obj
   * @returns
   */
  login(obj: any) {
    return this.service.post(config.partialUrls.doctorLogin, obj);
  }
  staffLogin(payload: LoginRequestBody): Observable<LoginResponse> {
    const url = `${this.baseUrl}${config.partialUrls.staffLogin}`;
    return this.http.post<LoginResponse>(url, payload).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return throwError(() => error);
      })
    );
  }
  validateStaff(payload: LoginRequestBody): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.validateStaff}`;
    return this.http.post<any>(url, payload).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return throwError(() => error);
      })
    );
  }
  patientlogin(obj: any) {
    return this.service.post(config.partialUrls.patientLogin, obj);
  }
  registrationpatient(obj: any) {
    return this.service.post(config.partialUrls.patientregistration, obj);
  }
  registrationdoctore(obj: any) {
    return this.service.post(config.partialUrls.DoctorRegistration, obj);
  }
  registrationHospital(obj: any) {
    // return this.service.post(config.partialUrls.HospitalRegistration, obj);
  }
  private baseUrl = environment.baseURL;

  verifyOtp(
    otp: string,
    loginValue: any,
    loginType: any,
    userType: string
  ): Observable<any> {
    const url = `${this.baseUrl}/api/Registration/VerifyOtp`;
    var body: any;
    body = {
      Otp: otp,
      LoginValue: loginValue,
      LoginType: loginType,
      UserType: userType,
    };

    return this.http.post(url, body).pipe(
      catchError((error) => {
        console.error('Error verifying OTP:', error);
        return throwError(error);
      })
    );
  }

  sendOtp(
    loginValue: string,
    loginType: string,
    type: string,
    userType: string
  ): Observable<any> {
    const url = `${this.baseUrl}/api/Registration/SendOtp`;
    const body = {
      LoginValue: loginValue,
      Type: type,
      UserType: userType,
      LoginType: loginType,
    };

    return this.http.post(url, body).pipe(
      catchError((error) => {
        console.error('Error sending OTP:', error);
        return throwError(error);
      })
    );
  }

  sendMobileOtp(phonenumber: string, userType: string): Observable<any> {
    const url = `${this.baseUrl}/api/Registration/MobileVerification`;
    const body = { MobileNumber: phonenumber, UserType: userType };

    return this.http.post(url, body).pipe(
      catchError((error) => {
        console.error('Error sending OTP:', error);
        return throwError(error);
      })
    );
  }

  changePassword(
    loginValue: any,
    loginType: string,
    password: string,
    userType: string
  ): Observable<any> {
    const url = `${this.baseUrl}/api/Registration/ForgotPassword`;
    const body = {
      LoginValue: loginValue,
      LoginType: loginType,
      Password: password,
      UserType: userType,
    };

    return this.http.post(url, body).pipe(
      catchError((error) => {
        console.error('Error changing password:', error);
        return throwError(error);
      })
    );
  }

  changePatientPassword(
    verificationId: string,
    password: string,
    confirmPassword: string
  ): Observable<any> {
    const url = `${this.baseUrl}/api/Registration/setpatientpassword`;
    const body = {
      VerificationId: verificationId,
      Password: password,
      ConfirmPassword: confirmPassword,
    };
    console.log(body)
    return this.http.post(url, body).pipe(
      catchError((error) => {
        console.error('Error changing password:', error);
        return throwError(error);
      })
    );
  }

  doctorProfileData(doctorId: any) {
    const url =
      `${this.baseUrl}/api/Doctor/GetDoctorDetails?doctorId=` + doctorId;

    return this.http.get(url).pipe(
      catchError((error) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  updateDoctorProfile(body: any) {
    const url = `${this.baseUrl}/api/Doctor/UpdateDoctorProfile`;

    return this.http.post(url, body).pipe(
      catchError((error) => {
        console.error('Error :', error);
        return throwError(error);
      })
    );
  }

  getOrganizationBranches(params: any) {
    return this.service.get(
      config.partialUrls.getUserOrganizationBranches,
      params
    );
  }
  // New Flow APIs

  sendRegistrationOtp(data: SendOTPBody): Observable<SendOTPResponse> {
    const url = `${this.baseUrl}/api/Auth/sendotp`;

    return this.http
      .post<ResponseWrapper<SendOTPResponse>>(url, data)
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error :', error);
          return throwError(() => error);
        })
      );
  }

  verifyRegistrationOtp(data: VerifyOTPBody): Observable<VerifyOTPResponse> {
    const url = `${this.baseUrl}/api/Auth/verifyotp`;

    return this.http
      .post<ResponseWrapper<VerifyOTPResponse>>(url, data)
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  checkAadhaar(data: CheckAadhaarBody): Observable<boolean> {
    const url = `${this.baseUrl}${config.partialUrls.CheckAadhaar}`;

    return this.http
      .post<ResponseWrapper<CheckAadhaarResponse>>(url, data)
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  registerOrganization(data: RegisterOrganizationBody): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.OrganizationRegistration}`;
    return this.http.post<any>(url, data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getUserCommunicationForVerification(
    communicationId: string
  ): Observable<GetVerifyCommunicationResponse> {
    const url = `${this.baseUrl}${config.partialUrls.GetUserCommunicationDetails}`;

    return this.http
      .post<ResponseWrapper<GetVerifyCommunicationResponse>>(url, null, {
        params: {
          communicationId: communicationId,
        },
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  verifyUserCommunication(payload: VerifyCommunicationBody): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.UpdateStaffVerificationStatus}`;

    return this.http
      .post<ResponseWrapper<any>>(url, payload)
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  setNewPassword(data: UpdateUserPasswordBody): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.UpdateUserPassword}`;

    return this.http
      .post<any>(url, data)
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  logout() {
    UtilityService.clearLocalStorage();
    this.router.navigate(['']);
  }
}
