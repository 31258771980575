import { DatePipe, NgClass } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, NO_ERRORS_SCHEMA, ViewChild } from '@angular/core';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionEventArgs, AgendaService, DayService, DragAndDropService, EventRenderedArgs, EventSettingsModel, MonthService, PopupOpenEventArgs, ResizeService, ScheduleComponent, ScheduleModule, TimeScaleModel, WeekService, WorkHoursModel, WorkWeekService } from '@syncfusion/ej2-angular-schedule';
import { extend } from '@syncfusion/ej2-base';
import moment from 'moment';
import { Constants } from 'src/app/constant/constant';
import { LoaderService } from 'src/app/core/services/loader.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { ScheduleService } from 'src/app/shared/services/doctor-service/schedule.service';
import { WebpageService } from 'src/app/shared/services/webpage.service';
import { AppointmentDialogComponent } from '../patient/components/book-appointment/appointment-dialog/appointment-dialog.component';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-webpage',
    templateUrl: './webpage.component.html',
    styleUrls: ['./webpage.component.scss'],
    standalone: true,  
    imports: [FlexModule, ExtendedModule, NgClass, ScheduleModule, LoaderComponent],
    providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA,
      NO_ERRORS_SCHEMA
    ]
})
export class WebpageComponent {
  webpagePropties: any;
  mapUrl: string = '';
  safeMapUrl: SafeResourceUrl;
  buttonShow: boolean = false;
  public views: Array<string> = ['Day', 'Week', 'WorkWeek', 'Month'];
  public showQuickInfo: Boolean = false;
  public data: object[] = [{
    Subject: 'Morning Before OPd',
  }];
  public eventSettings: EventSettingsModel = {
  allowEditing: false,
  allowDeleting: true,
  allowAdding: false,
  dataSource: [],
  fields: {
    id: 'Id',
    startTime: { name: 'StartTime', validation: { required: true } },
    endTime: { name: 'EndTime', validation: { required: true } }
    }
  };
  public formatString: string = 'HH:mm';
  public scheduleHours: WorkHoursModel = { highlight: true, start: '08:00', end: '23:00' };
  public timeScale: TimeScaleModel = { enable: true, interval: 30, slotCount: 2 };
  days: any[] = (Constants.days);
  events: any = [];

  public timeScale1: TimeScaleModel = { enable: true, interval: 60, slotCount: 4 };
  @ViewChild('scheduleObj')
  public scheduleObj: ScheduleComponent;
  
  startDate = new Date();
  startTime: any;
  endTime: any;
  day: any;
  breakendTime: any;
  breakstartTime: any;
  timeIntervals: { Time1: any; Time2: any, RecurrenceRule: any, IsBlock: any, scheduleId: any, IsReadonly:any, isAllSlotBooked: any }[] = [];
  dayName: any = [];
  doctorProfileData: any;
  timeSlotsBreak: any = [];
  datesofarray: any = [];
  currentDate: Date | null;
  datesArraywithday: any = [];
  public selectedDate: Date = new Date();
  numbers: number[];
  root = document.documentElement;
  doctorId: any;
  loading: boolean = false;
  services: any;
  testimonials: any;

  constructor(
    private router: Router,
    private webpageService: WebpageService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private scheduleService: ScheduleService,
    private loaderService: LoaderService,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
  ) {
    console.log(environment.baseURL)
    // this.route.queryParams.subscribe(
    //   params => {
    //     console.log(params['id'])
    //       if(params['id'] != undefined){
    //         localStorage.setItem('doctorId', params['id'])
    //         this.doctorId = localStorage.getItem('doctorId')
    //       }else{
    //         this.doctorId = localStorage.getItem('doctorId')
    //         // this.doctorId = '2e0b24de-d6a7-44d9-8ec4-f90e667147b1'
    //       }
    //      let data = JSON.parse(params['id']);
    //      console.log('Got param: ', data);
 
    //   }
    // )
    // setTimeout(() => {
    //   this.router.navigate([], {
    //     queryParams: {
    //       'id': null,
    //     },
    //     queryParamsHandling: 'merge'
    //   }) 
    // }, 1000);
    this.viewWebpageDetails()
  }


  loadMap(): void {
 
  }

  viewWebpageDetails(): void {
    this.loading = true
    // var doctordetails = JSON.parse(localStorage.getItem('newRegisterDoctor') || '')
    this.webpageService.viewWebPageForDomain(window.location.hostname).subscribe(
    // this.webpageService.viewWebPage(this.doctorId).subscribe(
      // this.webpageService.ViewWebPageOpen(this.doctorId).subscribe(
      (result) => {
        console.log('res: ',result.data)
        this.loading = false
        this.loaderService.hide();
        this.doctorId =result.data.doctorId
        this.webpagePropties = result.data
        this.root.style.setProperty('--bg-color', this.webpagePropties.backgroundColor);
        this.root.style.setProperty('--font-family', this.webpagePropties.fonts);
        this.services = this.webpagePropties.services.filter((obj:any) => !this.hasEmptyStringValue(obj))
        this.testimonials = this.webpagePropties.testimonials.filter((obj:any) => !this.hasEmptyStringValue(obj))
        // this.loadMap();
        if (this.webpagePropties.clinicAddress1!==null || this.webpagePropties.clinicAddress1!=='') {
          this.mapUrl = this.webpagePropties?.clinicAddress1+this.webpagePropties?.city+this.webpagePropties?.state+this.webpagePropties?.zipcode
          this.safeMapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?key=AIzaSyAUpBg5-S_EVVjj8bqX_M6ffFeiZ3h_ht4&q=${this.mapUrl}`);
          // this.safeMapUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAUpBg5-S_EVVjj8bqX_M6ffFeiZ3h_ht4&q=${this.mapUrl}`;
        }
        this.callApi(this.datesofarray[this.index]);

      })
  }

  hasEmptyStringValue(obj: any): boolean {
    return Object.values(obj).some(value => value === '');
  }


  ngAfterViewInit(): void {
    let date= new Date();

    let tomorrow = new Date(date)
    tomorrow.setDate(tomorrow.getDate() + 1)
    let tommorow = moment(tomorrow).format('YYYY-MM-DD')
    this.numbers = Array(15).fill(0).map((x, i) => i);
    console.log(this.numbers)
    for (let index = 0; index < this.numbers.length; index++) {
      const element = this.numbers[index];
      // console.log(element)
      let tom: any = tommorow[element]
      tom = new Date(date)
      tom.setDate(tom.getDate() + element)
      // console.log(tom)
      let tomo: any = tommorow[element]
      tomo = moment(tom).format('YYYY-MM-DD')
      this.datesofarray.push(tomo)
    }
    if (this.index >= 0) {
      this.loaderService.hide();
    }
  }
  index = 0;
  async callApi(value: string) {
    const userInfo = UtilityService.getUser();
    try {
      await this.getSchedule(value)
      // console.log('Response:', response);

   
    } catch (error) {
      console.error('Error:', error);
      // Handle error appropriately
    }
  }

  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    console.log('Teste', event.value);
    this.currentDate = event.value
  }

  getSchedule(date:any) {
    const userInfo = UtilityService.getUser();
    this.scheduleService.viewScheduleOpen(date, this.doctorId, '').subscribe(
      (result: any) => {
        if (result.data.length > 0) {
        const scheduleId = result.data[0].scheduleId
        const excludedStartTime = moment(result.data[0].breakTimeStart, ["h:mm A"]).format("HH:mm");
        const excludedEndTime = moment(result.data[0].breakTimeEnd, ["h:mm A"]).format("HH:mm");
        var tom1startBreak, tom1endBreak
        let tom1Break = new Date(date)
        var currentTimeSplitBreak = excludedStartTime.split(':')
        var nextimeSplit = excludedEndTime.split(':')
        tom1Break.setDate(tom1Break.getDate())
        tom1startBreak = tom1Break.setHours(parseInt(currentTimeSplitBreak[0]))
        tom1startBreak = tom1Break.setMinutes(parseInt(currentTimeSplitBreak[1]))
        tom1endBreak = tom1Break.setHours(parseInt(nextimeSplit[0]))
        tom1endBreak = tom1Break.setMinutes(parseInt(nextimeSplit[1]))
          this.timeIntervals.push({ Time1: new Date(tom1startBreak), Time2: new Date(tom1endBreak), RecurrenceRule: ``, IsBlock: true,  scheduleId: scheduleId, IsReadonly: false, isAllSlotBooked: false});
        // console.log(result.data)
        for (let index = 0; index < result.data.length; index++) {
          var timebreakadded = 0
          const element = result.data[index];
          const startTime = moment(element.startTime, ["h:mm A"]).format("HH:mm");
          const endTime = moment(element.endTime, ["h:mm A"]).format("HH:mm");
          const interval = element.averageTime;
          const day = this.days.find((id: any) => id.viewValue == element.workDay).value
          const excludedStartTime = moment(element.breakTimeStart, ["h:mm A"]).format("HH:mm");
          const excludedEndTime = moment(element.breakTimeEnd, ["h:mm A"]).format("HH:mm");
          const scheduleId = element.scheduleId
          this.timeScale = { enable: true, interval: 30, slotCount: 2 };
          this.timeScale1 = { enable: true, interval: 60, slotCount: 4 };
          let currentTime = startTime;
          const isAllSlotBooked = element.isAllSlotBooked
          // console.log(element, 'elementDay')
          if (element.workDay === this.days.find((id: any) => id.viewValue == element.workDay).viewValue) {
            this.dayName.push({ dayName: this.days.find((id: any) => id.viewValue == element.workDay).viewValue })

            this.dayName = [...this.dayName].filter((obj, pos, arr) => {
              return arr.map(mapObj => mapObj["dayName"]).indexOf(obj["dayName"]) === pos;
            })
            this.days.forEach((value1:any) => {
              // Check if the value exists in the second array
              this.dayName.find((value2:any) => {
                if(value2.dayName == value1.viewValue){
                  value1.disable = true
                }
              });
              this.datesArraywithday.find((value2:any) => {
                if(value2.day == value1.viewValue){
                  value1.date = value2.date
                }
              });
              // console.log('dayName', this.days)
            });
          }

        while (currentTime < endTime) {
          const nextTime = this.addMinutes(currentTime, interval);

          if (nextTime <= endTime) {
            // console.log('element.endTime',moment(element.endTime, ["h:mm A"]).format("HH:mm"), element.endTime, excludedStartTime, excludedEndTime)
            
              // console.log('element.endTime',startTime,endTime,excludedStartTime,excludedEndTime)
            if (currentTime >= excludedStartTime && currentTime < excludedEndTime ||
              nextTime > excludedStartTime && nextTime <= excludedEndTime ||
              currentTime < excludedStartTime && nextTime > excludedEndTime) {
              // console.log(nextTime)
              var tom1startBreak, tom1endBreak
              let tom1Break = new Date(date)
              var currentTimeSplitBreak = currentTime.split(':')
              var nextimeSplit = nextTime.split(':')
              tom1Break.setDate(tom1Break.getDate())
              tom1startBreak = tom1Break.setHours(parseInt(currentTimeSplitBreak[0]))
              tom1startBreak = tom1Break.setMinutes(parseInt(currentTimeSplitBreak[1]))
              tom1endBreak = tom1Break.setHours(parseInt(nextimeSplit[0]))
              tom1endBreak = tom1Break.setMinutes(parseInt(nextimeSplit[1]))
              this.timeIntervals.push({ Time1: new Date(tom1startBreak), Time2: new Date(tom1endBreak), RecurrenceRule: ``, IsBlock: true,  scheduleId: scheduleId, IsReadonly: false, isAllSlotBooked: isAllSlotBooked});
             } else {
              var tom1start, tom1end
              let tom1 = new Date(date)
              var currentTimeSplit = currentTime.split(':')
              var nextimeSplit = nextTime.split(':')
              tom1.setDate(tom1.getDate())
              tom1start = tom1.setHours(parseInt(currentTimeSplit[0]))
              tom1start = tom1.setMinutes(parseInt(currentTimeSplit[1]))
              tom1end = tom1.setHours(parseInt(nextimeSplit[0]))
              tom1end = tom1.setMinutes(parseInt(nextimeSplit[1]))

              if(element.isBooked){
                this.timeIntervals.push({ Time1: new Date(tom1start), Time2: new Date(tom1end), RecurrenceRule: ``, IsBlock: false, scheduleId: scheduleId, IsReadonly: true, isAllSlotBooked: isAllSlotBooked });
              }else{
                this.timeIntervals.push({ Time1: new Date(tom1start), Time2: new Date(tom1end), RecurrenceRule: ``, IsBlock: false, scheduleId: scheduleId, IsReadonly: false, isAllSlotBooked: isAllSlotBooked });
              }
            }
            currentTime = nextTime;
          } else {
            console.log('break')
            break;
          }
        }

        }
       
        this.timeIntervals?.map((x: any, index: any) => {
          // console.log(x)
          if (x.IsBlock == true) {
            var subject = 'Break-Time'
          } else {
            var subject = `${moment(x.Time1).format('h:mm A') + ' - ' + moment(x.Time2).format('h:mm A')}`
          }
          if(x.IsReadonly|| x.isAllSlotBooked){
            var CategoryColor1= '808080'
          }else{
            var CategoryColor1= ''
          }
          this.events.push({
            Id: index + 1,
            // Subject: x.subject,
            Subject: subject,
            StartTime: new Date(x.Time1),
            EndTime: new Date(x.Time2),
            RecurrenceRule: x.RecurrenceRule,
            IsBlock: x.IsBlock,
            ScheduleId: x.scheduleId,
            IsReadonly: x.IsReadonly,
            CategoryColor: CategoryColor1,
            isAllSlotBooked: x.isAllSlotBooked
          })
        });

        this.events = [...this.events].filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj["Id"]).indexOf(obj["Id"]) === pos;
        })
        // console.log('events', this.events)
        this.eventSettings = {
          dataSource: <Object[]>extend([], this.events, true)
        };

        this.index++;
        if (this.index < this.datesofarray.length) {
          // console.log('Response:', this.index);
          this.callApi(this.datesofarray[this.index]);
          timebreakadded = 0
          if(this.index >= 0){
            this.loaderService.hide();
          }
        }else{
          this.index = 0
        }
        return this.events
      }
      else{
        this.index++;
        if (this.index < this.datesofarray.length) {
          // console.log('Response:', this.index);
          this.callApi(this.datesofarray[this.index]);
          if(this.index >= 0){
            this.loaderService.hide();
          }
        }
      }}),
      (error:any) => {
        console.error('Error adding Schedule', error);
        // Handle error as needed

        this.index++;
        if (this.index < this.datesofarray.length) {
          // console.log('Response:', this.index);
          this.callApi(this.datesofarray[this.index]);
          if(this.index >= 0){
            this.loaderService.hide();
          }
        }else{
          this.index = 0
        }
      }
  }

  applyCategoryColor(args: any | EventRenderedArgs): void {
    let categoryColor: string = args.data['CategoryColor'] as string;
    if (!args.element || !categoryColor) {
        return;
    }
    if (this.scheduleObj?.currentView === 'Agenda') {
        (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
    } else {
        args.element.style.color = 'white';

        // Change background color
        args.element.style.backgroundColor = '#808080';
  
        // Apply strikethrough effect
        args.element.style.textDecoration = 'line-through';
    }
  }

  addMinutes(time: string, minutes: number): string {

    const [hours, mins] = time.split(':').map(Number);
    const totalMinutes = hours * 60 + mins + minutes;
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;
    return `${this.padZero(newHours)}:${this.padZero(newMinutes)}`;
  }

  padZero(value: any) {
    return value < 10 ? `0${value}` : `${value}`;
  }

  public onPopupOpen(args: any | PopupOpenEventArgs): void {
    console.log('args', args, this.datepipe.transform(args.data?.['startTime'], 'yyyy-MM-dd'))
    // if(args.data.Id != undefined){
    //     args.cancel = true; 
    // }else 
    if(args.data?.['Id'] != undefined){
      args.cancel = true; 
      this.appointmentDialog(args.data)
    }else if (args.type === "QuickInfo") {
      args.cancel = true;
      this.scheduleObj.closeQuickInfoPopup();
      this.appointmentDialog(args.data)
     }
    // else
    if (args.type === 'Editor') {
      const elm = document.querySelector<HTMLElement>('.e-title-location-row')!;
      elm.style.display = 'none';
      const elm1 = document.querySelector<HTMLElement>('.e-description-container')!;
      elm1.style.display = 'none';
      const elm2 = document.querySelector<HTMLElement>('.e-dlg-header')!;
      elm2.style.display = 'none';
      const elm3 = document.querySelector<HTMLElement>('.e-time-zone-container')!;
      elm3.style.display = 'none';

    }
    if (args.type === "RecurrenceAlert") {
      args.cancel = true
      this.scheduleObj.closeEditor();
    }
    if (args.type === "EventContainer") {
      // args.cancel = true
      // this.scheduleObj.closeEditor();
    }

  }



  onActionComplete(args: any | ActionEventArgs): void {
    console.log(args.data)
    if (args.requestType === "eventCreated") {

      args.data?.map((x: any) => {
        console.log(x)
        this.events.push({
          Id: x.Id,
          Subject: this.datepipe.transform(x.StartTime, 'HH:mm') + ' - ' + this.datepipe.transform(x.EndTime, 'HH:mm'),
          StartTime: new Date(x.StartTime),
          EndTime: new Date(x.EndTime),
          RecurrenceRule: x.RecurrenceRule
        })
      });
      // console.log('events', this.events)
      this.eventSettings = {
        dataSource: <Object[]>extend([], this.events, true)
      };
      console.log("It will triggered when new event is rendered on the scheduler user interface.");
    }else if(args.requestType === "eventRemoved"){
      var scheduleId:any;
      if(args.data){
        scheduleId = args.data
      }
      const ScheduleData = {
        scheduleId: scheduleId[0].ScheduleId
      };

      console.log('day Check add', ScheduleData)
      this.scheduleService.deleteSchedule(ScheduleData).subscribe(
        (response:any) => {
          console.log('Schedule deleted successfully', response);
        },
        (error:any) => {
          console.error('Error deleted Schedule', error);
        }
      );
    }
  }
  appointmentDialog(data: any) {
    console.log('data dialog', data)
    var data = data
    if (data.IsReadonly == false && data.IsBlock == false && data.isAllSlotBooked == false) {
      console.log('data dialog')

      const userInfo = UtilityService.getUser();
      console.log(userInfo)

        const dialogRef = this.dialog.open(AppointmentDialogComponent, {
          width: 'auto',
          data: { date: data, patientId: '', patientData: '', rescheduleAppointment: '', doctorName: this.webpagePropties.doctorName },
          panelClass: ["dialog-design"],
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
          // if(result.appointmentId.appointmentId != ''){
          //   this.events.map((value:any) =>{ 
          //     if(result == value.Id){
          //       value.CategoryColor= '#00613E'
          //     }
          //   });
          //   this.eventSettings = {
          //     dataSource: <Object[]>extend([], this.events, true)
          //   };
          //   console.log(this.events)

          // }
        })

    }

  }

  callPhoneNumber(phoneNumber: string) {
    window.open(`tel:${phoneNumber}`, '_blank');
  }
  emailOpen(email: string){
    window.open(`mailto:${email}`, '_blank');
  }
}
