import { Routes } from '@angular/router';
import { AuthGuard } from './core/gaurds/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { p1PatientPostRegistrationComponent } from './modules/p1-patient/components/p1patient-post-registration/p1patient-post-registration.component';
import { ThankYouPageComponent } from './modules/p1-patient/components/thank-you-page/thank-you-page.component';
import { DoctorProfileSettingComponent } from './modules/patient/components/doctor-profile-setting/doctor-profile-setting.component';
import { WebpageComponent } from './modules/webpage/webpage.component';
import { OrgOnboardingComponent } from './modules/org-onboarding/org-onboarding.component';
import { HospitalSelectComponent } from './modules/auth/components/hospital-select/hospital-select.component';
import { InstOnboardingComponent } from './modules/inst-onboarding/inst-onboarding.component';
import { UniGuard } from './core/gaurds/university.guard';
import { MasterReportComponent } from './modules/dashboard/components/unidashboard/master-report/master-report.component';
import { PatientOverviewComponent } from './modules/dashboard/components/unidashboard/patient-overview/patient-overview.component';
import { PerformanceReportComponent } from './modules/dashboard/components/unidashboard/performance-report/performance-report.component';
import { UnidashboardComponent } from './modules/dashboard/components/unidashboard/unidashboard.component';
import { StaffGuard } from './core/gaurds/staff/staff.guard';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },

      {
        path: 'doctor/profile-setting',
        component: DoctorProfileSettingComponent,
        data: { title: 'Profile Setting' },
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard-routing').then(
            (dashboard) => dashboard.dashboardRoutes
          ),
      },
      {
        path: 'patient',
        loadChildren: () =>
          import('./modules/patient/patient-routing').then(
            (obj) => obj.patientRoutes
          ),
      },
      {
        path: 'appointment',
        loadChildren: () =>
          import('./modules/appointment/appointment-routing').then(
            (obj) => obj.appointmentRouting
          ),
      },
      {
        path: 'campaign',
        loadChildren: () =>
          import('./modules/campaign/campaign-routing').then(
            (obj) => obj.CampaignRoutingModule
          ),
      },
      {
        path: 'self-registration',
        loadChildren: () =>
          import('./modules/p1-patient/p1patient-routing').then(
            (obj) => obj.p1patientRoutes
          ),
      },
      // { path: 'access-denied', component: AccessDeniedComponent },
    ],
  },

  {
    path: 'onboarding',
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then(
        (m) => m.OnboardingModule
      ),
    canActivate: [AuthGuard, StaffGuard],
  },

  {
    path: '',
    component: UnidashboardComponent,
    canActivate: [UniGuard],
    children: [
      {
        path: 'unidashboard/masterreport',
        component: MasterReportComponent,
        data: { title: 'Master Report' },
      },
      {
        path: 'unidashboard/patientoverview',
        component: PatientOverviewComponent,
        data: { title: 'Patient Overview' },
      },
      {
        path: 'unidashboard/performancereport',
        component: PerformanceReportComponent,
        data: { title: 'Performance Report' },
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  // {
  //   path: 'doctor-search',
  //   loadChildren: () =>
  //     import('./modules/doctor-search/doctor-search-routing').then(
  //       (m) => m.doctorSearchRoutes
  //     ),
  // },
  // {
  //   path: 'website',
  //   loadChildren: () =>
  //     import('./modules/webpage/webpage-routing').then(
  //       (obj) => obj.webpageRoutes
  //     ),
  // },
  // {
  //   path: ':name',
  //   component: WebpageComponent,
  // },
  {
    path: 'patient/consent',
    component: p1PatientPostRegistrationComponent,
    data: { title: 'Consent' },
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'patient/thank-you',
    component: ThankYouPageComponent,
    data: { title: 'Thank you' },
    pathMatch: 'full',
  },
  // {
  //   path: 'hospital/onboarding',
  //   component: OrgOnboardingComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     title: 'Hospital Onboarding',
  //     urls: [{ title: 'Hospital Onboarding' }],
  //   },
  // },
  // {
  //   path: 'institute/onboarding',
  //   component: HospitalSelectComponent,
  //   data: {
  //     title: 'Institute Onboarding',
  //     urls: [{ title: 'Institute Onboarding' }],
  //   },
  // },
  {
    path: 'unidashboard',
    loadChildren: () =>
      import(
        '../app/modules/dashboard/components/unidashboard/unidashboard-routing'
      ).then((m) => m.UnidashboardRoutingModule),
  },
  {
    path: 'institute/hospital-onboarding',
    component: InstOnboardingComponent,
    data: {
      title: 'Institute Hospital Info',
      urls: [{ title: 'Hospital Info' }],
    },
  },
];
