import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UtilityService } from '../../services/utility.service';
import { SessionUserType } from '../../models/session-type';

@Injectable({
  providedIn: 'root',
})
export class StaffGuard {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const sessionUser = UtilityService.sessionUserType;
    const isLoggedIn = UtilityService.isAccessTokenValid;

    if (isLoggedIn && sessionUser === SessionUserType.Staff) {
      return true;
    }

    if (isLoggedIn) {
      if (sessionUser === SessionUserType.Patient) {
        this.router.navigate(['/dashboard/patient']);
      }

      this.router.navigate(['/auth/doctor/login']);
    }

    return false;
  }
}
