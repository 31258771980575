import { Inject, Injectable, NgZone } from '@angular/core';

@Injectable()
export class NotificationService {

  constructor(
    private zone: NgZone) { }

  showSuccess(message: string): void {
    // Had an issue with the snackbar being ran outside of angular's zone.
    this.zone.run(() => {

    });
  }

  showError(message: string): void {
    this.zone.run(() => {

    });
  }
  showWarning(message: string, options?: any): void {
    this.zone.run(() => {

    });
  }
}
