<div style="display: flex; justify-content: end;">
    <button mat-raised-button color="primary" type="button" (click)="departmentPopup()">Manage Departments</button>
</div>
<div class="onboardingInsideWrapper">
    <form [formGroup]="wardInfoFormGroup">
        <table class="wardTable md-table" matTable>
            <thead class="md-table-header">
                <tr class="md-table-headers-row">
                    <td>Department Name</td>
                    <td>Room Type 1<span class="required">*</span></td>
                    <td>No of Rooms 1<span class="required">*</span></td>
                    <td>No. of Beds<span class="required">*</span></td>
                    <td>Price Per Night<span class="required">*</span></td>

                    <td>Room Type 2</td>
                    <td>No. of Rooms 2</td>
                    <td>No. of Beds</td>
                    <td>Price Per Night</td>

                    <td>Room Type 3</td>
                    <td>No. of Rooms 3</td>
                    <td>No. of Beds</td>
                    <td>Price Per Night</td>
                </tr>
            </thead>
            <tbody class="md-table-content" formArrayName="departments">
                <tr class="md-table-content-row" *ngFor="let department of departments.controls; let i = index"
                    [formGroupName]="i">
                    <td class="txtWrap">{{ finalDepartments[i].$fieldName }}</td>
                    <td>
                        <mat-form-field class="form-field-150px-width">
                            <input matInput placeholder="Room Type" formControlName="roomType1" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-80px-width">
                            <input matInput placeholder="0-100" formControlName="totalRooms1"
                                (keypress)="onKeyPress($event)" max="100" min="0" type="number" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-80px-width">
                            <input matInput placeholder="0-100" formControlName="totalBedsPerRoom1"
                                (keypress)="onKeyPress($event)" max="100" min="0" type="number" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-100px-width">
                            <input matInput placeholder="Price" formControlName="pricePerNight1"
                                (keypress)="onKeyPress($event)" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-150px-width">
                            <input matInput placeholder="Room Type" formControlName="roomType2" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-80px-width">
                            <input matInput placeholder="0-100" formControlName="totalRooms2"
                                (keypress)="onKeyPress($event)" max="100" min="0" type="number" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-80px-width">
                            <input matInput placeholder="0-100" formControlName="totalBedsPerRoom2"
                                (keypress)="onKeyPress($event)" max="100" min="0" type="number" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-100px-width">
                            <input matInput placeholder="Price" formControlName="pricePerNight2"
                                (keypress)="onKeyPress($event)" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-150px-width">
                            <input matInput placeholder="Room Type" formControlName="roomType3" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-80px-width">
                            <input matInput placeholder="0-100" formControlName="totalRooms3"
                                (keypress)="onKeyPress($event)" max="100" min="0" type="number" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-80px-width">
                            <input matInput placeholder="0-100" formControlName="totalBedsPerRoom3"
                                (keypress)="onKeyPress($event)" max="100" min="0" type="number" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-100px-width">
                            <input matInput placeholder="Price" formControlName="pricePerNight3"
                                (keypress)="onKeyPress($event)" />
                        </mat-form-field>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>