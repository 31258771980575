import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MatCard,
  MatCardAvatar,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
} from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectionList } from '@angular/material/list';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatOption, MatSelect } from '@angular/material/select';
import {
  MatStep,
  MatStepper,
  MatStepperNext,
  MatStepperPrevious,
} from '@angular/material/stepper';
import {
  MatCell,
  MatHeaderCell,
  MatHeaderRow,
  MatRow,
  MatTable,
  MatTableModule,
} from '@angular/material/table';
import { MatToolbar } from '@angular/material/toolbar';
import { DepartmentModalComponent } from '../department-modal/department-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { StaffManagementComponent } from './components/staff-management/staff-management.component';
import { DepartmentsRoomComponent } from './components/departments-room/departments-room.component';
import { HospitalInfoComponent } from './components/hospital-info/hospital-info.component';
import { DepartmentService } from 'src/app/shared/services/org-onboarding-service/department-service';
import { Router } from '@angular/router';
import { StaffManagementService } from 'src/app/shared/services/org-onboarding-service/staff.service';
import { StaffAddDto } from 'src/app/models/dto/staff-add-dto';
import { OrganizationBranchModel } from 'src/app/models/organization-branch-model';
import { OrganizationService } from 'src/app/shared/services/org-onboarding-service/organization.service';

export interface RoomType {
  value: string;
  displayValue: string;
}

@Component({
  selector: 'org-onboarding',
  templateUrl: './inst-onboarding.component.html',
  styleUrl: './inst-onboarding.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    MatToolbar,
    MatCard,
    MatCardHeader,
    MatCardAvatar,
    MatCardTitle,
    MatCardSubtitle,
    MatCardContent,
    MatStepper,
    MatStep,
    MatStepperPrevious,
    MatStepperNext,
    MatLabel,
    ReactiveFormsModule,
    MatGridList,
    MatGridTile,
    MatIcon,
    MatButton,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    MatTable,
    MatHeaderRow,
    MatCell,
    MatRow,
    MatHeaderCell,
    MatIconButton,
    MatSelect,
    MatOption,
    MatTableModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatChipsModule,
    StaffManagementComponent,
    DepartmentsRoomComponent,
    HospitalInfoComponent,
  ],
})
export class InstOnboardingComponent implements OnInit {
  hospitalInfoFormGroup: FormGroup;
  wardInfoFormGroup: FormGroup;
  staffDetailsFormGroup: FormGroup;
  staffList: StaffAddDto[];
  @ViewChild('hospitalInfoComponent', { static: false })
  hospitalInfoComponent: HospitalInfoComponent;
  @ViewChild('departmentRoomComponent')
  departmentRoomComponent: DepartmentsRoomComponent;
  @ViewChild('staffManagementComponent')
  staffManagementComponent: StaffManagementComponent;
  departments = [
    'Kayachikitsa (General Medicine)',
    'Panchakarma (Detoxification and Rejuvenation)',
    'Shalya Tantra (Surgery)',
    'Shalakya Tantra (ENT and Ophthalmology)',
    'Prasuti Tantra and Stree Roga (Obstetrics and Gynecology)',
    'Bal Roga/Kaumarbhritya (Pediatrics)',
    'Swasthavritta (Preventive Medicine)',
    'Maulik Siddhant (Basic Principles)',
    'Rasa Shastra (Alchemy and Pharmacetics)',
    'Dravyaguna (Materia Medica)',
    'Agad Tantra (Toxicology)',
    'Sharir Kriya (Physiology)',
  ];

  constructor(
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private departmentService: DepartmentService,
    private router: Router,
    private staffManagementService: StaffManagementService,
    private organizationService: OrganizationService
  ) {}

  departmentPopup() {
    const dialogRef = this.dialog.open(DepartmentModalComponent, {
      width: '400px',
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('Dialog closed', result);
    });
  }

  ngOnInit() {
    this.hospitalInfoFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.wardInfoFormGroup = this._formBuilder.group({
      secondCtrl: this._formBuilder.array([], [Validators.required]),
    });
    this.staffDetailsFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required],
    });
    const tile2 = this.wardInfoFormGroup.get('secondCtrl') as FormArray;
    for (let index = 0; index < this.departments.length; index++) {
      tile2.push(this.defaultDepartments(this.departments[index]));
    }
  }

  defaultDepartments(deptName: string) {
    return this._formBuilder.group({
      deparmentName: [deptName],
      deparmentDetails: this._formBuilder.array([
        this._formBuilder.group({
          roomType: ['', Validators.required],
          beds: ['', Validators.required],
          price: ['', Validators.required],
        }),
      ]),
    });
  }

  onHospitalInfoNext() {
    if (this.hospitalInfoComponent) {
      console.log('onHospitalInfoNext');
      const branchModel = this.hospitalInfoComponent.onSave;
      console.log(branchModel);
      this.organizationService
        .postOrganizationBranch(branchModel)
        .subscribe((response) => {
          console.log(response);
          if (
            response.body.data.message ===
            'Organization branch created successfully.'
          ) {
            localStorage.setItem(
              'organizationBranchId',
              response.body.data.organizationBranchId
            );
          }
        });
    } else {
      console.error('HospitalInfoComponent is not available');
    }
  }

  addRooms(index: number) {
    const parent = this.wardInfoFormGroup.get('secondCtrl') as FormArray;
    const child = parent.at(index).get('deparmentDetails') as FormArray;
    child.push(
      this._formBuilder.group({
        roomType: ['', Validators.required],
        beds: ['', Validators.required],
        price: ['', Validators.required],
      })
    );
  }
  onNextDepartment() {
    this.departmentService
      .postDepartments(this.departmentRoomComponent.departments.value)
      .subscribe((response) => {
        console.log(response);
      });
    console.log(this.departmentRoomComponent.departments.value);
  }

  onSaveForm() {
    this.staffManagementComponent.onSaveForm;
    this.staffList = this.staffManagementComponent.staffList;
    // this.staffManagementService
    //   .addStaff(this.staffList)
    //   .subscribe((response) => {
    //     console.log(response);
    //     if (
    //       response.body.data.message ==
    //       'Organization Staff added created successfully.'
    //     ) {
    //       this.router.navigate(['/institute/onboarding']);
    //     } else {
    //       console.log('Error', response);
    //     }
    //   });
  }

  onKeyPress(e: any) {
    const allowedKeys = [8, 46];

    if (
      e.which !== 0 &&
      (e.which < 48 || e.which > 57) &&
      !allowedKeys.includes(e.which)
    ) {
      e.preventDefault();
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/auth/doctor/login']);
  }
}
