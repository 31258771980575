import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, switchMap, filter, take } from 'rxjs/operators';
import { UtilityService } from '../services/utility.service';
import { Constants } from 'src/app/constant/constant';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
  private totalRequests = 0;
  constructor(
    private router: Router,
    private store: Store,
    private loaderService: LoaderService
  ) {}

  /**
   * Interceptor Service
   * @param req
   * @param next
   * @returns
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = UtilityService.getLocalStorage('accessToken');

    if (token) {
      if (req.headers.get('Anonymous') !== null) {
        const newHeaders = req.headers.delete('Anonymous');
        req = req.clone({ headers: newHeaders });
      } else {
        let bypassURL: any = Constants.BYPASS_URL_AUTH.find((url: any) => req.url.includes(url));
        if (!bypassURL) {
          req = req.clone({
            setHeaders: {
              Authorization: 'Bearer ' + token,
            },
          });
        }
        // req = this.addToken(req, token)
      }
    }
    // check for bypass loader.
    let bypassURL:any = Constants.BYPASS_URL.find((url: any) => req.url.includes(url));
    if(!bypassURL) {
      this.totalRequests++;
      this.showLoader();
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.onEnd(req);
        }
        return event;
      }),

      catchError((err: HttpErrorResponse) => {
        console.log(err);
        this.onEnd(req);
        return throwError(() => err);
        // if (
        //   err.status === 401 &&
        //   err.error &&
        //   err.error.code === 'token_not_valid'
        // ) {
        //   this.router.navigate(['/auth/login']);
        // }
      })
    );
  }

  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }

  private onEnd(req: any): void {
    this.totalRequests--;
    // if (this.totalRequests <= 0) {
      this.hideLoader();
    // }
  }
}
