import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DatePipe, NgClass } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterLink } from '@angular/router';
import { ToggleService } from './toggle.service';
import { FeathericonsModule } from 'src/app/shared/icons/feathericons/feathericons.module';
import { UtilityService } from 'src/app/core/services/utility.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    FeathericonsModule,
    MatButtonModule,
    MatMenuModule,
    RouterLink,
    CommonModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [DatePipe],
})
export class HeaderComponent implements OnInit {
  public userInfo: any;
  firstLetter: any;
  secondLetter: any;
  profileName: any;

  userType: any;

  constructor(
    public toggleService: ToggleService,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.userType = UtilityService.getLocalStorage('userType');

    this.toggleService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
  }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('user_info') || '{}');
    this.firstLetter =
      this.userInfo.firstName != null
        ? this.userInfo.firstName
            .split(' ')
            .map((name: any) => name[0])
            .join('')
            .toUpperCase()
        : '';
    this.secondLetter =
      this.userInfo.lastName != null
        ? this.userInfo.lastName
            .split(' ')
            .map((name: any) => name[0])
            .join('')
            .toUpperCase()
        : '';
    this.profileName = this.firstLetter + this.secondLetter;
  }

  // Toggle Service
  isToggled = false;
  toggle() {
    this.toggleService.toggle();
  }

  // Dark Mode
  toggleTheme() {
    this.toggleService.toggleTheme();
  }

  // Current Date
  currentDate: Date = new Date();
  formattedDate: any = this.datePipe.transform(
    this.currentDate,
    'dd MMMM yyyy'
  );

  logout() {
    UtilityService.clearLocalStorage();
    this.router.navigate(['/unidashboard/login']);
  }
}
