<div class="form-group">
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-select
        [(value)]="currentOrg"
        (selectionChange)="onBranchSwitch($event)"
      >
        @for (branch of userOrganizationBranches; track branch) {<mat-option
          [value]="branch"
          >{{ branch.organizationBranchName }}</mat-option
        >}
      </mat-select>
    </mat-form-field>
</div>
