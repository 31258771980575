import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'src/app/configs/config';
import { environment } from '../../../../environments/environment';
import { catchError, map, Observable, pipe, throwError } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import {
  GetOrganizationParams,
  OrganizationDTO,
} from '../../model/organization';
import { ResponseWrapper } from '../../model/common';
import { Branch } from '../../model/organization/branch';
import { UserBranchRoleAndPrivilege } from '../../model/roles-and-right/branch-role-and-privilage';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private baseUrl = environment.baseURL;

  constructor(private http: HttpClient, private service: ApiService) {}

  getOrganizationInfo(): Observable<any> {
    var userDetails = JSON.parse(localStorage.getItem('userDetail') as string);
    const userId = userDetails.id;
    // const userId = 'aca5c9e7-e160-4110-8eb4-cad9bf78e299';
    const url = `${this.baseUrl}${config.partialUrls.GetOrganizationInfo}?userId=${userId}`;
    return this.http.get(url);
  }

  getOrganizationBranches(params: any): Observable<Branch[]> {
    const url = `${this.baseUrl}${config.partialUrls.GetOrganizationBranches}`;
    return this.http
      .get<ResponseWrapper<Branch[]>>(url, { params })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting branches:', error);
          return throwError(() => error);
        })
      );
  }

  postOrganizationBranch(data: any): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.AddOrganizationBranch}`;
    return this.http.post(url, data);
  }

  postDepartmentAndRooms(data: any): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.AddDepartmentsAndRooms}`;
    return this.http.post(url, data);
  }

  // New Functions

  getUserOwnOrganizations(userId: string): Observable<OrganizationDTO[]> {
    const url = `${this.baseUrl}${config.partialUrls.GetAllUserOrganizations}`;
    return this.http
      .get<ResponseWrapper<OrganizationDTO[]>>(url, {
        params: {
          userId: userId,
        },
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting organization:', error);
          return throwError(() => error);
        })
      );
  }

  getOrganization(orgId: string): Observable<OrganizationDTO> {
    const params: GetOrganizationParams = {
      organizationId: orgId,
    };
    const url = `${this.baseUrl}${config.partialUrls.GetOrganizationInfo}`;
    return this.http
      .get<ResponseWrapper<OrganizationDTO>>(url, {
        params: params,
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting organization:', error);
          return throwError(() => error);
        })
      );
  }

  completeBranchOnboarding(branchId: string): Observable<any> {
    const url = `${this.baseUrl}${config.partialUrls.CompleteBranchOnboarding}`;
    return this.http
      .post<ResponseWrapper<null>>(url, null, {
        params: {
          organizationBranchId: branchId,
        },
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting organization:', error);
          return throwError(() => error);
        })
      );
  }

  getUserBranchRolesAndRights(
    organizationBranchId: string
  ): Observable<UserBranchRoleAndPrivilege[]> {
    const url = `${this.baseUrl}${config.partialUrls.GetUserBranchRolesAndRights}`;
    return this.http
      .get<ResponseWrapper<UserBranchRoleAndPrivilege[]>>(url, {
        params: {
          organizationBranchId: organizationBranchId,
        },
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error getting branch roles and rights:', error);
          return throwError(() => error);
        })
      );
  }
}
