@if (bookAppointment) {
<div class="tableBorder divBorder" fxLayout="column" fxLayoutAlign="start">
  <div class="margin-div">
    <h4 id="underline-gap" class="m-0 pb-2 font-size-19 capitalize text-middle">Schedule Appointment</h4>
  </div>
  <div fxLayout="column" class="class-padding">
    <div fxLayout="column" fxLayoutGap="10px">
      <p><b> Date:</b> {{this.AppointmentDateShow}}, {{this.AppointmentTime}}</p>
    </div>
    @if (this.flag === true) {
    <br>
    }
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <p><b> Mode:</b></p>
      <mat-radio-group (change)="handleRadioChangeReschedule($event)" fxLayout="row" fxLayout.lt-sm="column"
        fxLayoutAlign="start center" fxLayoutAlign.lt-sm="start start" fxLayoutGap="20px" fxLayoutGap.lt-sm="0px">
        <mat-radio-button color="primary" value="InPlaned">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon> directions_walk</mat-icon>
            <p> Walk - In</p>
          </div>
        </mat-radio-button>
        <mat-radio-button color="primary" value="Scheduled">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon>computer</mat-icon>
            <p>Telemedicine</p>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    @if (this.flag === true) {
    <br>
    }
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <button mat-raised-button (click)="onCancelClick('close')" [disabled]="this.apicall"
        class="button-size">Cancel</button>
      <button mat-raised-button color="primary" class="button-size" (click)="schedule()"
        [disabled]="this.selectedOptionRescheule == undefined">Schedule</button>
    </div>
  </div>
</div>
}

<!-- login page -->
@if (showLoginandRegister) {
<div class="tableBorder divBorder" fxLayout="column" fxLayoutAlign="start">
  <div class="margin-div">
    <h4 id="underline-gap" class="m-0 pb-2 font-size-19 capitalize text-middle">Need to First Login or Register.</h4>
  </div>
  <div class="class-padding" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div fxLayout="column">
      <form [formGroup]="loginForm" fxLayout.lg="column" fxLayout="column" name="form">
        <span class="error">{{errorMessage}}</span>
        <mat-form-field fxFlex="" class="formFields Formemail">
          <mat-label class="LoginLable">Email or Mobile Number</mat-label>
          <input type="text" class="loginEmail" matInput 
            formControlName="emailOrMobile">
        </mat-form-field>
        @if (loginForm.controls['emailOrMobile'].errors && loginForm.controls['emailOrMobile'].invalid &&
        (loginForm.controls['emailOrMobile'].dirty || loginForm.controls['emailOrMobile'].touched)) {
        <div class="error" style="margin-top:-20px;margin-bottom: 4px;">
          @if (loginForm.controls['emailOrMobile'].errors['email']) {
          <div>Invalid Email Id</div>
          }
          @if (loginForm.controls['emailOrMobile'].errors['pattern']) {
          <div>Invalid Mobile No
          </div>
          }
        </div>
        }
        <mat-form-field fxFlex="" class="formFields Formpass">
          <mat-label class="LoginLable">Password</mat-label>
          <input matInput formControlName="password" class="loginEmail" type="password"
            [type]="hide ? 'text' : 'password'"  />
          <mat-icon matSuffix (click)="hide=!hide" color="primary">{{hide ? 'visibility' :
            'visibility_off'}}</mat-icon>
        </mat-form-field>
        @if (loginForm.get('password')?.hasError('minlength') && loginForm.get('password')?.touched) {
        <div class="error" style="margin-top:-20px;margin-bottom: 2px;">
          Password must be at least 6 characters long.
        </div>
        }
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <button mat-raised-button (click)="onCancelClick('schedule')" [disabled]="this.apicall || loading"
            class="button-size">Cancel</button>
          <button mat-raised-button color="primary" class="button-size" (click)="login()"
            [disabled]="this.loginForm.invalid || loading"  [class.spinner]="loading">Login</button>
        </div>
        <div fxLayout="column" fxLayoutGap="7px" style="margin-top: 10px;">

          <p fxLayoutAlign="center" (click)="forgotPassword()" class="redirect-text">
            Forgot password?</p>
          <p fxLayoutAlign="center"> Don't have an account? <span class="redirect-text" fxLayoutAlign="center"
              (click)="register()" style="padding-left: 3px;"> Register</span>
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
}

<!-- Forgot Password page -->
@if (forgotPasswordPage) {
<div class="tableBorder divBorder" fxLayout="column" fxLayoutAlign="start">
  <div class="margin-div">
    <h4 id="underline-gap" class="m-0 pb-2 font-size-19 capitalize text-middle">Forgot password?</h4>
  </div>
  <div class="class-padding" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div fxLayout="column">
      <span fxLayoutAlign="center" class="spacediv SignlableForm">Enter your Email below to receive your OTP.</span>
      <mat-error fxLayoutAlign="center">{{this.errorMsg}}</mat-error>
      <form [formGroup]="forgotForm" fxLayout.lg="column" fxLayout="column" name="form">
        <mat-form-field fxFlex="" class="formFields Formemail">
          <mat-label class="LoginLable">Email</mat-label>
          <input type="text" matInput  formControlName="email">
          @if (forgotForm.get('email')?.hasError('required')) {
          <mat-error>Email is required</mat-error>
          }
          @if (forgotForm.get('email')?.hasError('pattern')) {
          <mat-error>Invalid Email format</mat-error>
          }
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 10px;" fxLayoutGap="10px">
          <button mat-raised-button (click)="onCancelClick('login')" [disabled]="this.apicall || loading1"
            class="button-size">Cancel</button>
          <button mat-raised-button color="primary" class="button-size" [disabled]="this.forgotForm.invalid || loading1"
            (click)="sendOtpMethod()"  [class.spinner]="loading1">Send</button>
        </div>
      </form>
    </div>
  </div>
</div>
}

<!-- Email verification page -->
@if (OTPverifyPage) {
<div class="tableBorder divBorder" fxLayout="column" fxLayoutAlign="start">
  <div class="margin-div">
    <h4 id="underline-gap" class="m-0 pb-2 font-size-19 capitalize text-middle">Email Verification</h4>
  </div>
  <div class="class-padding" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div fxLayout="column">
      <span fxLayoutAlign="center" class="spacediv SignlableForm">We have sent an OTP to email &nbsp; <span
          class="redirect-text"> {{this.user_email}}</span></span>
      <mat-error fxLayoutAlign="center">{{this.errorMsgVerify}}</mat-error>
      <form [formGroup]="verifyForm" fxLayout.lg="column" fxLayout="column" name="form">
        <mat-form-field fxFlex="" class="formFields Formemail">
          <mat-label class="LoginLable">Enter 6-digit OTP:</mat-label>
          <input type="text" maxlength="6" formControlName="otp" class="loginEmail" matInput
             (keypress)="onKeyPress($event)">
          @if (verifyForm.get('otp')?.hasError('required')) {
          <mat-error>
            Please enter a 6-digit number.
          </mat-error>
          }
          @if (verifyForm.get('otp')?.hasError('invalidPattern')) {
          <mat-error>
            Invalid OTP pattern. Please enter a 6-digit number.
          </mat-error>
          }
          <mat-hint align="end">{{verifyForm.get('otp')?.value.length}} / 6</mat-hint>
        </mat-form-field>
        <div fxLayoutAlign="center" fxLayout="row" fxLayoutGap="10px">
          <p>Not received your code?</p>
          @if (showResendButton) {
          <p class="redirect-text" fxLayoutAlign="center" (click)="resendOtp()">Resend</p>
          }
          @if (!showResendButton) {
          <span class="redirect-text">{{ resendTimer }}s</span>
          }
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 10px;" fxLayoutGap="10px">
          <button mat-raised-button (click)="onCancelClick('forgot')" [disabled]="this.apicall || loading2"
            class="button-size">Cancel</button>
          <button mat-raised-button color="primary" class="button-size" (click)="verifyOtpMethod()"
            [disabled]="this.verifyForm.invalid || loading2" [class.spinner]="loading2">Verify</button>
        </div>
      </form>
    </div>
  </div>
</div>
}

<!-- Change Password page -->
@if (changePasswordPage) {
<div class="tableBorder divBorder" fxLayout="column" fxLayoutAlign="start">
  <div class="margin-div">
    <h4 id="underline-gap" class="m-0 pb-2 font-size-19 capitalize text-middle">Change Password</h4>
  </div>
  <div class="class-padding" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div fxLayout="column">
      <form [formGroup]="changeForm" fxLayout.lg="column" fxLayout="column" name="form">
        <mat-form-field>
          <mat-label>Create Password</mat-label>
          <input matInput formControlName="password" [type]="hidecon ? 'password' : 'text'" type="password"
            id="password" required>
          <mat-icon class="color-secondary" color="primary" matSuffix (click)="hidecon = !hidecon">
            {{hidecon ? 'visibility_off' : 'visibility'}}
          </mat-icon>
          @if (password.errors?.required) {
          <mat-error> Password is required.
          </mat-error>
          }
          @if (password.errors?.pattern) {
          <mat-error>At least 8 characters in length Lowercase and Uppercase letters
            Numbers Special characters
          </mat-error>
          }
        </mat-form-field>
        <!-- Confirm password field -->
        <mat-form-field style="margin-top: 6px;">
          <mat-label>Confirm Password</mat-label>
          <input matInput formControlName="c_password" [type]="hidecon1 ? 'password' : 'text'" id="c_password" required>
          <mat-icon class="color-secondary" color="primary" matSuffix (click)="hidecon1 = !hidecon1">
            {{hidecon1 ? 'visibility_off' : 'visibility'}}
          </mat-icon>
          @if (c_password.errors?.required) {
          <mat-error> Confirm Password is required.
          </mat-error>
          }
          <!-- <mat-error *ngIf="c_password.errors?.pattern" >At least 8 characters in length Lowercase and Uppercase letters Numbers Special characters
                    </mat-error> -->
          @if (changeForm.get('c_password')?.hasError('mismatch')) {
          <mat-error>
            Password and confirm password are not matching
          </mat-error>
          }
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 10px;" fxLayoutGap="10px">
          <button mat-raised-button (click)="onCancelClick('forgot')" [disabled]="this.apicall || loading3"
            class="button-size">Cancel</button>
          <button mat-raised-button color="primary" class="button-size" [disabled]="this.changeForm.invalid || loading3"
            (click)="changePasswordMethod()" [class.spinner]="loading3">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>
}

<!-- Register page -->
@if (registrationPage) {
<div class="tableBorder divBorder" fxLayout="column" fxLayoutAlign="start">
  <div class="margin-div">
    <h4 id="underline-gap" class="m-0 pb-2 font-size-19 capitalize text-middle">Register</h4>
  </div>
  <div fxLayout="column" class="class-padding">
    <form [formGroup]="UserForm" fxLayout.lg="column" fxLayoutAlign="center" fxLayout="column"
      style="margin-top: 10px;">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="space-between center" fxFlex>
          <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
            <mat-form-field class="register-formFields Formemail">
              <mat-label class="LoginLable">First Name</mat-label>
              <input formControlName="firstname" type="text" matInput >
            </mat-form-field>
            @if (UserForm.get('firstname')?.hasError('whitespace')) {
            <mat-error>{{ whitespaceErrorMessage
              }}</mat-error>
            }
          </div>
          <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
            <mat-form-field class="register-formFields Formemail">
              <mat-label class="LoginLable">Middle Name</mat-label>
              <input formControlName="middlename" type="text" matInput >
            </mat-form-field>
            @if (UserForm.get('middlename')?.hasError('whitespace')) {
            <mat-error>{{ whitespaceErrorMessage
              }}</mat-error>
            }
          </div>
          <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
            <mat-form-field class="register-formFields Formemail">
              <mat-label class="LoginLable">Last Name</mat-label>
              <input type="text" formControlName="lastname" matInput >
            </mat-form-field>
            @if (UserForm.get('lastname')?.hasError('whitespace')) {
            <mat-error>{{ whitespaceErrorMessage }}</mat-error>
            }
          </div>
          <!-- </div>
                  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center" fxFlex> -->
          <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
            <mat-form-field class="register-formFields Formemail">
              <mat-label class="LoginLable">Gender</mat-label>
              <mat-select formControlName="gender">
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
                <mat-option value="other">Other</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
            <mat-form-field class="register-formFields Formemail">
              <mat-label class="LoginLable">Birthdate</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="birthdate"
                (click)="picker.open()" [max]="maxDate" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
            <mat-form-field class="register-formFields Formemail">
              <mat-label class="LoginLable">Email Id</mat-label>
              <input formControlName="email" type="email" matInput >
            </mat-form-field>
            @if (UserForm.get('email')?.invalid && (UserForm.get('email')?.dirty || UserForm.get('email')?.touched)) {
            <div style="color:red">Please enter valid Email Id</div>
            }
          </div>
          <!-- </div>
                    <div fxLayout="row " fxLayout.lt-sm="column" fxLayoutAlign="space-between center" fxFlex> -->
          <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
            <mat-form-field class="register-formFields Formemail">
              <mat-label class="LoginLable">Mobile No</mat-label>
              <input formControlName="phoneNumber" type="text" matInput 
                (keypress)="onKeyPress($event)" maxlength="10">
            </mat-form-field>
            @if (UserForm.get('phoneNumber')?.invalid && (UserForm.get('phoneNumber')?.dirty ||
            UserForm.get('phoneNumber')?.touched)) {
            <div style="color:red">Please enter valid Mobile No.</div>
            }
          </div>
          <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
            <mat-form-field fxFlex="" class="register-formFields Formpass">
              <mat-label class="LoginLable">Password</mat-label>
              <input matInput formControlName="password" class="loginEmail" type="password"
                [type]="hide ? 'text' : 'password'" />
              <mat-icon matSuffix (click)="hide=!hide" color="primary">{{hide ? 'visibility' :
                'visibility_off'}}</mat-icon>
            </mat-form-field>
            @if (UserForm.get('password')?.hasError('whitespace')) {
            <mat-error>{{ whitespaceErrorMessage }}</mat-error>
            }
            @if (UserForm.get('password')?.hasError('minlength') && UserForm.get('password')?.touched) {
            <div class="error">
              Password must be at least 6 characters long.
            </div>
            }
          </div>
          <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
            <mat-form-field fxFlex="" class="register-formFields Formpass">
              <mat-label class="LoginLable">Confirm Password</mat-label>
              <input matInput formControlName="confirmPassword" class="loginEmail" type="password"
                [type]="hide1 ? 'text' : 'password'" />
              <mat-icon matSuffix (click)="hide1=!hide1" color="primary">{{hide1 ? 'visibility' :
                'visibility_off'}}</mat-icon>
            </mat-form-field>
            @if (UserForm.get('confirmPassword')?.touched && f['confirmPassword']['errors']?.['MatchPassword']) {
            <div class="error">
              Password does not match
            </div>
            }
          </div>
        </div>
      </div>
      @if (errorMessageforalready) {
      <div class="error" style="text-align: center;">
        {{errorMessageforalready}}
      </div>
      }
    </form>
    <div>
      <div class="">
        <div fxLayout="row">
          <form [formGroup]="patientRegistration" fxFlex="100">
            <div fxFlex="100" fxLayout="column">
              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="10px" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
                  <mat-form-field class="example-full-width flex-gap register-formFields Formpass" appearance="fill">
                    <mat-label>Height (cm)</mat-label>
                    <input matInput  formControlName="height" required noWhiteSpace
                      (keypress)="onKeyPress($event)" maxlength="3" />
                    @if (patientRegistration.get('height')?.hasError('required')) {
                    <mat-error>Height is
                      required</mat-error>
                    }
                    @if (patientRegistration.get('height')?.hasError('min') ||
                    patientRegistration.get('height')?.hasError('max')) {
                    <mat-error>
                      Height must be between 0 and 300
                    </mat-error>
                    }
                    @if (patientRegistration.get('height')?.hasError('pattern')) {
                    <mat-error></mat-error>
                    }
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
                  <mat-form-field class="example-full-width flex-gap register-formFields Formpass" appearance="fill">
                    <mat-label>Weight (kg)</mat-label>
                    <input matInput  formControlName="weight" required noWhiteSpace
                      (keypress)="onKeyPress($event)" maxlength="3" />
                    @if (patientRegistration.get('weight')?.hasError('required')) {
                    <mat-error>Weight is
                      required</mat-error>
                    }
                    @if (patientRegistration.get('Weight')?.hasError('min') ||
                    patientRegistration.get('weight')?.hasError('max')) {
                    <mat-error>
                      Weight must be between 0 and 500
                    </mat-error>
                    }
                    @if (patientRegistration.get('Weight')?.hasError('pattern')) {
                    <mat-error>
                      Weight must not start with a zero
                    </mat-error>
                    }
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
                  <mat-form-field class="example-full-width flex-gap register-formFields Formpass" appearance="fill">
                    <mat-label>Aadhar Card No</mat-label>
                    <input type="text" matInput (keypress)="onKeyPress($event)" 
                      formControlName="aadhar" maxlength="12" required noWhiteSpace />
                    @if (patientRegistration.get('aadhar')?.hasError('required')) {
                    <mat-error>
                      Aadhar Card Number is required
                    </mat-error>
                    }
                    @if (patientRegistration.get('aadhar')?.hasError('pattern')) {
                    <mat-error>
                      Aadhar Card Number must have exactly 12 digits
                    </mat-error>
                    }
                  </mat-form-field>
                </div>
                <!-- <mat-form-field class="example-full-width flex-gap register-formFields Formpass"
                                         appearance="fill" >
                                        <mat-label>Address</mat-label>
                                        <input type="text"  matInput placeholder="Address" formControlName="address"
                                          required noWhiteSpace />
                                          <mat-error *ngIf="patientRegistration.get('address')?.hasError('required')">Address is
                                          required</mat-error>
                                        </mat-form-field> -->
              </div>
              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="10px" fxLayoutAlign="space-between center">
                <!-- Street field -->
                <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
                  <mat-form-field class="example-full-width flex-gap register-formFields Formpass" appearance="fill">
                    <mat-label> Address line 1 </mat-label>
                    <input formControlName="address" maxlength="256" type="text" matInput 
                      #ngxPlaces="ngx-places" [options]="options" ngx-gp-autocomplete
                      (onAddressChange)="selectAddress($event)" [(ngModel)]="this.address1">
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
                  <!-- Street2 field -->
                  <mat-form-field class="example-full-width flex-gap register-formFields Formpass" appearance="fill">
                    <mat-label>Address line 2</mat-label>
                    <input formControlName="address2" matInput  [(ngModel)]="this.locality">
                  </mat-form-field>
                </div>
                <!-- City field -->
                <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
                  <mat-form-field class="example-full-width flex-gap register-formFields Formpass" appearance="fill">
                    <mat-label> City </mat-label>
                    <input formControlName="city" matInput [(ngModel)]="this.city">
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="10px" fxLayoutAlign="space-between center">
                <!-- State Field -->
                <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
                  <mat-form-field class="example-full-width flex-gap register-formFields Formpass" appearance="fill">
                    <mat-label> State </mat-label>
                    <input formControlName="state" matInput  [(ngModel)]="this.state">
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
                  <!-- country Field -->
                  <mat-form-field class="example-full-width flex-gap register-formFields Formpass" appearance="fill">
                    <mat-label> Country </mat-label>
                    <input formControlName="country" matInput [(ngModel)]="this.country">
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="30" fxFlex.lt-sm="100">
                  <!-- Pincode field -->
                  <mat-form-field class="example-full-width flex-gap register-formFields Formpass" appearance="fill">
                    <mat-label> Zip </mat-label>
                    <input formControlName="zipcode" matInput  [(ngModel)]="this.postalCode">
                  </mat-form-field>
                </div>
              </div>
              <div style="display: flex;margin-top: 10px;">
                <div fxFlex="100">
                  <mat-label>Have you ever had?</mat-label>
                  <mat-chip-listbox aria-label="Fish selection" class="flex-gap" multiple="true">
                    @for (info of haveYouEverHead; track info) {
                    <mat-chip-option (selectionChange)="changeSelected($event, info)">{{info}}</mat-chip-option>
                    }
                  </mat-chip-listbox>
                </div>
              </div>
              <div style="display: flex;">
                <mat-form-field fxFlex="100" class="example-full-width flex-gap" appearance="fill" style="width: 99%;">
                  <mat-label>Other illness</mat-label>
                  <textarea type="text" matInput  formControlName="OtherIllness"
                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="2"
                    noWhiteSpace></textarea>
                </mat-form-field>
              </div>
              <div style="display: flex;">
                <div fxFlex="100">
                  <mat-label>Family history mother side</mat-label>
                  <mat-chip-listbox aria-label="Fish selection" class="flex-gap" multiple="true">
                    @for (info of haveYouEverHead; track info) {
                    <mat-chip-option
                      (selectionChange)="MotherSideHealthSelected($event, info)">{{info}}</mat-chip-option>
                    }
                  </mat-chip-listbox>
                </div>
              </div>
              <div style="display: flex;">
                <mat-form-field fxFlex="100" class="example-full-width flex-gap" appearance="fill" style="width: 99%;">
                  <mat-label>Other illness</mat-label>
                  <textarea type="text" matInput  formControlName="OtherIllnessMother"
                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="2"
                    noWhiteSpace></textarea>
                </mat-form-field>
              </div>
              <div style="display: flex;">
                <div fxFlex="100">
                  <mat-label>Family history father side</mat-label>
                  <mat-chip-listbox aria-label="Fish selection" class="flex-gap" multiple="true">
                    @for (info of haveYouEverHead; track info) {
                    <mat-chip-option
                      (selectionChange)="FatherSideHealthSelected($event, info)">{{info}}</mat-chip-option>
                    }
                  </mat-chip-listbox>
                </div>
              </div>
              <div style="display: flex;">
                <mat-form-field fxFlex="100" class="example-full-width flex-gap" appearance="fill" style="width: 99%;">
                  <mat-label>Other illness</mat-label>
                  <textarea type="text" matInput  formControlName="OtherIllnessFather"
                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="2"
                    noWhiteSpace></textarea>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <button mat-raised-button (click)="onCancelClick('login')" [disabled]="this.apicall || loading4"
        class="button-size">Cancel</button>
      <!-- <button mat-raised-button class="button-size" color="primary" (click)="schedule()">Login</button> -->
      <button mat-raised-button class="button-size" color="primary" (click)="createUser()"
        [disabled]="this.UserForm.invalid || this.patientRegistration.invalid || loading4" [class.spinner]="loading4">Register</button>
    </div>
    <div fxLayout="column" fxLayoutGap="7px" style="margin-top: 10px;">
      <p fxLayoutAlign="center"> Already registered? <span class="redirect-text" fxLayoutAlign="center"
          (click)="schedule()" style="padding-left: 3px;"> Login</span>
      </p>
    </div>
  </div>
</div>
}

<!-- loader -->
<!-- @if (apicall) {
                        <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center" class="loader-container">
                          <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                        </div>
                      } -->